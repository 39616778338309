.LocationModal {
  p {
    margin: 0;
    margin-bottom: 10px;
    &.label {
      font-size: 12px;
      font-weight: 700;
      margin-bottom: 0;
    }
  }
}