@import "./../../styles/theme";

$legend-width: 250px;
.introjs-overlay {
  -webkit-transition: none !important;
}

.overlay-selection {
  width: 300px;
}

.zoom-reset-button {
  position: absolute;
  top: 10px;
  right: 10px;
  color: grey !important;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  background-color: whitesmoke;
  border-radius: 60px;
  cursor: pointer;
  user-select: none;
  .text {
    font-family: "Asap", sans-serif;
    font-size: 11px;
    padding-left: 5px;
  }

  &:hover {
    opacity: 0.7;
  }
}
.additional-device-overlay {
  margin-top: 10px;
}
.fake-menu-body {
  display: flex;
  flex-direction: column;
  padding: 10px;
  padding-right: 15px;
  .Icon {
    margin-left: 5px;
  }
  .MuiIconButton-root {
    padding: 0;
    margin-right: 5px;
  }
  .menu-checkbox {
    .MuiFormControlLabel-root {
      margin: 0 0 5px 0 !important;
    }
  }
}

.zoom-tip-highlight {
  border-radius: 8px !important;
}

.zoom-tip {
  padding: 20px !important;
  border-radius: 8px !important;
  width: 400px;
  background-color: white;
  display: flex;
  justify-content: center;

  .close-button {
    position: absolute;
    right: 5px;
    top: 5px;
    padding: 2px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      opacity: 0.7;
      cursor: pointer;

    }
  }

  &::after {
    position: absolute;
    bottom: -12px;
    content: "";
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 12px solid white;
  }

  .title {
    color: $gd-dark-grey;
    font-family: "Asap", sans-serif;
    font-weight: bold;
    flex-direction: row;
    font-size: 14px;
    margin-right: 10px;
    border-right: 1px solid;

    .text {
      font-size: 12px;
      padding-right: 10px;
      padding-top: 10px;
      font-weight: normal;
      margin-bottom: 10px;
    }
  }


  .demo-image {
    min-width: 200px;
    min-height: 140px;
    border-radius: 10px !important;
    //overflow: hidden !important;
    width: 100%;
    height: 100%;
    background-size: contain !important;
    background-position: center;
    background-repeat: no-repeat !important;
  }

}

.tabbed-page {
  //width: 100%;
  background-color: white;
  flex-direction: row;
  flex: 1;

  .inner-tabbed-page {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
  }

  &.expanded {
    position: absolute;
    background: white;
    height: 100%;
    width: 100%;
  }

  .custom-date {
    display: flex;
    padding: 10px 20px;
    flex: 1 1;
    flex-direction: row-reverse;
    align-items: center;
    height: 72px;
    margin-bottom: -92px;

    .MuiInputBase-root {
      //max-width: 200px;
    }
  }

  .graph-top-wrapper {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid $gd-border-grey;
    justify-content: space-between;
    align-items: center;
    padding: 8px 20px;
    position: relative;
    height: 71px;
    //min-height: 41px;
    //height: 74px;

    &.mobile-h {
      height: auto;
    }

    .loader-wrapper {
      justify-content: start !important;

      .loader-inner {
        width: 50px !important;
        padding: 0 !important;
      }
    }

    &.scrollable-y {
      display: flex;
      overflow: hidden;
      padding: 0;

      .legend-granularity {
        padding: 0 !important;
        border-bottom: none !important;
      }

      .Dropdown {
        margin: 0 5px !important;
      }

      .legend-wrapper {
        max-width: none;
        border-left: none;
        overflow: hidden;
      }
    }

    .MuiFormControl-root {
      min-width: auto;
    }
  }

  .graph-type-picker {
    display: flex;
    //margin-right: 20px;
    //margin-top: 5px;

    .Dropdown {
      //margin: 20px;
      //margin-right: 15px;

      //.MuiInput-input {
      //  padding-left: 20px;
      //  padding-right: 40px;
      //  background: white;
      //  border-radius: 50px !important;
      //  border-color: #e5e5ea;
      //}
    }
  }

  .granularity-picker {
    //margin-right: 15px;
    //max-width: 172px;
  }

  .graph-kpi-wrapper {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .graph-kpi-legend-wrapper {
    position: relative;
    overflow: hidden;
    display: flex;
    flex: 1;

    &.inactive {
      pointer-events: none;
    }
  }

  .graph.inactive {
    opacity: 0;
    position: absolute;
    pointer-events: none;

    .overlay {
      pointer-events: none !important;
    }
  }

  .data-graphs {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1;
    overflow: hidden;
    height: calc(100% - 121px);

    .MuiInput-input {
      padding-left: 20px;
      padding-right: 40px;
      background: white;
      border-radius: 50px !important;
      border-color: #e5e5ea;
      font-size: 11px;
    }

    .no-data {
      font-size: 18px;
      font-weight: 400;
      text-align: center;
      display: flex;
      flex: 1 1;
      justify-content: center;
      align-items: center;
      color: grey;
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 2;
      pointer-events: none;
      flex-direction: column;
      .no-data-title {
        font-size: 17px;
        font-family: Poppins, sans-serif;
        font-weight: 500;
        margin-bottom: 0;
      }
    }

    .loader-wrapper {
      position: absolute;
      width: 100%;
      height: 100%;
      flex: 1;
      bottom: 0;
      justify-content: center;
      align-items: center;
      display: flex;
      overflow: hidden;
      z-index: 999;

      .Loader {
        flex-direction: column;
        align-items: center;
        position: relative;
        width: 80%;
        display: block;
      }
    }
  }

  .graph-holder {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    height: 100%;
    padding: 10px 0;
    position: relative;
    z-index: 1;
    background-color: white;
    &.detached {

      .linegraph-prompt {
        display: none !important;
      }
    }
    &.mobile {
      height: auto;
      flex: 1 1;
    }

    &.loading {
      opacity: 0.5;
    }

  }


}

.graph {
  display: flex;
  flex: 1;
  position: relative;
  width: calc(100% - 250px);

  &.inactive {
    .bargraph-tooltip {
      display: none;
    }
  }

  &.loading {
    opacity: 0.5;
  }

}

.tick {
  font-size: 12px;
  font-family: "Asap", "sans-serif";
  color: grey;
}

.arc-label {
  font-size: 12px;
}

.bargraph-tooltip {
  position: absolute;
  margin-left: -110px !important;
  min-width: 250px;
  pointer-events: none;
  border: 1px solid lightgrey;
  border-radius: 5px;
  background-color: white !important;
}

.linegraph-tooltip {
  position: absolute;
  margin-left: -110px !important;
  min-width: 250px;
  pointer-events: none;
  border: 1px solid lightgrey;
  border-radius: 5px;
  background-color: white !important;
}

.starburst-tooltip {
  margin-left: -110px !important;
  min-width: 250px;
  pointer-events: none;
  border: 1px solid lightgrey;
  border-radius: 5px;
  background-color: white !important;
}
