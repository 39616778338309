.portfolio-list {
  display: none;

  .p-list-item {
    display: flex;
  }
  .MuiListSubheader-gutters {
    padding: 0;
  }
  .p-sites {
    height: 0;
    overflow: hidden;
    &.open {
      height: auto;
    }
  }
  ul {
    padding-inline-start: 0 !important;
  }
  &.loaded {
    display: inherit;
  }

}