.optional-field {
  align-items: end;
  .Icon {
    margin-right: 6px;
  }
  .add-remove-button {
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    cursor: pointer;
    font-size: 13px;
    color: #49b9c4;
    margin-top: 15px;
    &:hover {
      opacity: 0.7;
    }
    &.remove {
      font-size: 13px;
      color: #e43536;
      padding: 0 20px;
      padding-bottom: 10px;
    }
  }
}