.spend-popper {
  pointer-events: visible !important;

  .MuiTooltip-tooltip {
    pointer-events: visible !important;
    font-size: 15px !important;
    background-color: white !important;
    color: grey !important;
    border: 1px solid #eeeeee;
    border-radius: 10px !important;
    padding: 20px 30px;
    box-shadow: 0 5px 4px 3px #0000000a;
  }
}

.spend-arrow {
  color: white !important;

  &::before {
    border: 1px solid #eeeeee !important;
  }
}

.spend-tooltip {
  pointer-events: visible !important;
}