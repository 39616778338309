.button-wrapper.with-text {
  margin: 0 10px;
  display: flex;
  align-items: center;
  label {
    margin-left: 5px;
    margin-bottom: 0;
  }
}

.anomalies-wrapper {
  display: flex;
  justify-content: space-between;
  flex: 1 1;
  overflow: hidden;
  flex-direction: column;
  .no-results {
    position: relative;
    text-align: center;
    top: 50%;
    font-size: 18px;
    margin-top: -10px;
  }
  &.row {
    flex-direction: row;
  }

  .search-input-wrapper {
    padding: 5px 0;
  }

  .clickable {
    cursor: pointer;

    label {
      cursor: pointer;
    }
  }
}

.board-header {
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 10px 20px;
  border-bottom: 1px solid #e8e8e8;

  .input-icon {
    display: flex;
    height: 30px;
    width: 30px;
    position: absolute;
    justify-content: center;
    align-items: center;
    top: 3px;
    left: 5px;

    &.no-pointer, &.no-pointer .Icon {
      pointer-events: none !important;
    }

    &.end {
      top: 4px;
      cursor: pointer;
      left: auto;
      right: 10px;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .GdButton {
    margin: 0;
    margin-left: 10px;
  }

}

.anomaly-loader {
  position: absolute;
  top: 0;
  width: 100%;

  .loader-inner {
    padding: 25px;
  }
}

.graph-wrapper {
  position: relative;
  overflow: hidden;
}

.anomaly-graph {
  transition: opacity 0.3s ease-in;
  opacity: 0;

  &.loaded {
    opacity: 1;
  }
}

.anomaly-graph-wrapper {
  padding: 10px;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  border: 1px solid whitesmoke;
  margin-top: 10px;

  .loader-wrapper {
    position: absolute;
    height: 100%;
    z-index: 0;
  }

  #anomaly-graph {
    svg.gd-graph {
      position: absolute;
      z-index: 1;
    }
  }
}

.save-button-wrapper {
  box-sizing: border-box;
  position: relative;
  overflow: hidden;

  .GdButton {
    width: auto;
    height: 20px;
    line-height: 20px;
    font-size: 11px;

    .button-icon {
      margin-top: 4px;
    }
  }
}

.anomaly-status {
  display: inline-flex;
  padding: 2px 10px;
  border-radius: 4px;
  font-size: 11px;
  color: white;
  background-color: #49c570;

  &.dd {
    cursor: pointer;
    padding: 4px 12px;

    &:hover {
      opacity: 0.8;
    }
  }

  .dd-icon {
    margin-left: 5px;
  }

  &.resolved {
    background-color: #9b9b9b !important;
  }

  &.ongoing {
    background-color: #e86e0c !important;
  }

  &.flagged {
    background-color: #e74e0c !important;
  }
}

.anomaly-details-lower-section {
  textarea {
    width: 100%;
    height: 100px;
    border-radius: 10px;
    padding: 15px 20px;
    box-sizing: border-box;
    outline: none !important;
    border-color: lightgrey;
    resize: none;

    &:focus {
      outline: none !important;
    }
  }
}

.anomaly-panel {
  width: 660px;
  min-width: 660px;
  max-width: 660px;
  border-left: 1px solid lightgrey;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  overflow: hidden;
  position: relative;

  .close-anomaly {
    position: absolute;
    top: 9px;
    right: 13px;
  }

  .anomaly-details {
    padding: 10px 20px;
    overflow: auto;
    font-family: 'Poppins', sans-serif;

    p.anomaly-info-label {
      font-size: 12px;
      margin-bottom: 5px;
      margin-top: 15px;
    }

    p.anomaly-info-text {
      font-size: 14px;
      margin: 0;
      font-weight: 500;
      max-height: 80px;
      overflow: auto;
    }
  }
}

.keep-detecting {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;

  p {
    font-size: 13px;
  }

  .yes-no {
    display: flex;
    align-items: center;

    .choice {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 5px;
      cursor: pointer;

      p {
        margin: 0;
        padding-right: 5px;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.anomalies {
  width: 100%;
  padding: 20px;
  overflow: auto;

  .anomaly {
    border: 1px solid lightgrey;
    border-radius: 10px;
    overflow: hidden;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    height: 100%;

    .anomaly-inner {
      padding: 14px 17px;
      //pointer-events: none;
    }

    &:hover {
      opacity: 0.7;
    }

    img {
      width: 100%;
    }

    .anomaly-title {
      font-weight: 500;
      margin-top: 10px;
      margin-bottom: 5px;
    }

    .anomaly-sub-title {
      margin: 0;
      font-size: 12px;
      white-space: pre-line;
      max-height: 80px;
      overflow: auto;
    }
  }
}