@import url("https://fonts.googleapis.com/css?family=Asap:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i");
@import url("https://fonts.googleapis.com/css?family=Source+Code+Pro:400,700,900");
@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed:400");
.Notifications {
  font-family: "Asap", sans-serif;
  position: absolute;
  color: white;
  top: -6px;
  left: 10px;
  z-index: 2;
  min-width: 20px;
  height: 20px;
  font-size: 9px;
  font-weight: 700;
  line-height: 20px;
  border-radius: 30px;
  text-align: center;
  pointer-events: none;
  user-select: none; }

@keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.Icon.spinning {
  overflow: visible; }
  .Icon.spinning .MuiBadge-root {
    overflow: visible; }
  .Icon.spinning svg {
    overflow: visible;
    animation-name: spin;
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out; }

.Icon.clickable svg {
  cursor: pointer; }
  .Icon.clickable svg:hover {
    opacity: 0.7; }

.Icon .MuiBadge-badge {
  background-color: #e43535 !important;
  color: white;
  font-size: 10px;
  font-weight: 700; }
