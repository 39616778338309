.widget {
  position: relative;
}
.data-upload {
  .MuiFormControlLabel-root.Checkbox {
    margin-left: 0;
  }


  .with-body-item {
    .GdRadio {
      label {
        pointer-events: all !important;
        justify-content: start !important;
        align-items: start !important;

        label {
          pointer-events: none !important;
          padding: 12px 0;
        }
      }
    }
  }
  .dot-at-top {
    .GdRadio {
      .MuiRadio-root {
        padding-top: 0;
      }
    }
  }

  table {
    border: 1px solid #bdbdbd;
    border-collapse: separate;
    border-radius: 10px;
    box-sizing: border-box;
    margin-left: -30px;

    tr {
      font-weight: 500;

      td {
        padding: 5px 10px;
        padding-right: 40px;
        border: solid transparent 1px;
      }
    }

    &.rows {
      tr td {
        border-bottom: 1px solid #bdbdbd;
      }

      tr:last-child td {
        border: none !important;
      }
    }

    &.columns {
      tr td {
        border-right: 1px solid #bdbdbd;

        &:last-child {
          border: none !important;
        }
      }
    }
  }


  .date-across-multiple-columns {
    overflow: hidden;
    display: flex;

    .separate-column-date {
      display: flex;
      flex-direction: column;
      flex-shrink: 1; //Allows the divs to shrink
      flex-grow: 1;
      //min-width: 0; //Prevents text from overflowing
      overflow: hidden; //Hide overflow content
      padding-right: 5px;
      box-sizing: border-box;
      min-width: 95px;
    }
  }
}

//.MuiPopover-paper {
//  max-height: 350px !important;
//}

.date-formats {
  padding: 5px;
  align-items: center;

  &.error {
    label {
      color: red;
    }

    background-color: #ff00001c;
    border: 1px solid red;
    border-radius: 5px;
  }

  .GdButton {
    height: 24px;
    //margin-top: 13px;
    padding: 0 11px;
    font-size: 15px;
    font-weight: 900;
    line-height: 21px;
  }

  .trash-icon {
    border: 1px solid #e8e8e8;
    height: 38px;
    margin-top: 6px;
    width: 28px;
    border-radius: 0 5px 5px 0;
    margin-left: -1px;
    margin-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .GdInput input, .GdInput .MuiSelect-select, .GdInput textarea {
    border-radius: 5px 0 0 5px !important;
    font-size: 12px !important;
  }
}

.processing-data {
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  padding: 20px 0;

  p {
    color: grey;
    font-weight: 500;
    margin: 0;
    margin-top: 10px;
  }
}

.file-upload {
  .GdButton {
    padding: 0;
    max-width: 130px;
    cursor: pointer;

    p {
      position: absolute;
      top: 0;
      margin: 0;
      text-align: center;
      width: 100%;
      font-size: 12px;
      color: white;
      pointer-events: none;
    }

    .GdInput {
      margin: 0;
      opacity: 0;
      width: 100%;
      cursor: pointer !important;

      input, input::after, input::before {
        cursor: pointer !important;
      }

      label {
        display: none;
      }
    }
  }
}

.csv-file-name {
  display: flex;
  flex-direction: column;
  //align-items: center;
  border-bottom: 1px solid #e8e8e8;
  padding: 2.5px;

  &:last-child {
    border-bottom: none !important;
  }

  .MuiCheckbox-root {
    padding: 0;
    margin-right: 5px;
  }

  .Checkbox {
    margin: 0;
    margin-top: 5px;
  }

  p {
    margin: 0;
    color: grey;
  }

  .Icon {
    margin-right: 10px;
  }
}

.csv-file-names {
  padding: 10px 20px;
  max-height: 200px;
  overflow: auto;
}

.modal-info {
  background: whitesmoke;
  color: grey;
  padding: 10px 20px;
  font-size: 12px;
}