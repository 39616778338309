@import "../../styles/theme";

.org-security {
  .GdButton {
    width: 180px;
  }
}


.user-drawer {
  height: 100%;

  .user-list-wrapper {
    display: flex;
    overflow: hidden;
    position: relative;
    flex-direction: column;
    flex: 1 1;
  }

  .name {
    color: $gd-dark-grey;
    font-size: 12px;
    margin: 0;
    font-weight: bold;
  }

  .user-wrapper {
    padding: 30px;

    .profile-image {
      width: 120px;
      height: 120px;
      overflow: hidden;
      border: 2px solid #e8e8e8;
      background-size: cover;
      background-position: center;
      pointer-events: none;
      background-color: #2a363f;
      -webkit-user-select: none;
      user-select: none;

      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-size: 28px;
    }

    a {
      cursor: pointer !important;
      &.no-click {
        color: grey;
        pointer-events: none;
      }
    }

    .user-details {
      //flex: 1;
      //height: 120px;
      padding: 20px 0;
      border-bottom: 1px solid #e8e8e8;
      //margin-bottom: 20px;
      p, div.subHeader {
        margin: 0;

        &.with-icon {
          margin-bottom: 10px;

          &:nth-last-child(1) {
            margin-bottom: 0 !important;
          }
        }
      }

      .Icon {
        margin-right: 10px;
      }

      .header {
        font-weight: 600;
        font-size: 20px;
      }
    }

  }


  .override-row {
    font-size: 12px;
    border-bottom: 1px solid #e8e8e8;
    padding: 10px 25px;
    font-style: normal;
    color: grey;

    label {
      margin-bottom: 0 !important;
    }
  }

  .permission-row {
    max-width: 200px;
    font-size: 12px;
    padding: 10px 20px;
    padding-bottom: 0;
    font-style: normal;
    color: grey;
  }

  .user-description {
    display: inline;
    white-space: pre-wrap;
    overflow-wrap: break-word;

    p {
      color: $gd-grey;
      font-size: 12px;
      margin: 0;
    }

  }

}

.organisation-page {
  .left-panel {
    display: flex;
    width: 250px;
    border-right: 1px solid #e8e8e8;
    flex-direction: column;

    .logo-name {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      align-items: center;
      padding: 30px;
      border-bottom: 1px solid #e8e8e8;

      .GdInput {
        label {
          color: #b9b9b9;
        }

        input {
          text-align: center;
          background: white;
          font-size: 18px;
          border-radius: 0 !important;
          padding: 0;
          height: 30px;
          border: none !important;
          box-shadow: none !important;
        }
      }
    }

    .editable-details {
      display: flex;
      flex: 1;
      flex-direction: column;
      padding: 15px 20px;
      overflow: auto;
    }

    .logo {
      width: 100px;
      height: 100px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      position: relative;

      .hover-upload {
        background-color: rgba(0, 0, 0, 0.7);
        opacity: 0;
        cursor: pointer;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        transition: 0.3s opacity ease-in;
        justify-content: center;
        align-items: center;
        display: flex;
        border-radius: 5px;

        &.hovered {
          opacity: 1;
        }
      }

      &:hover {
        .hover-upload {
          opacity: 1;
        }
      }
    }

    .input-row {
      margin: 7px 0;

      .GdInput {
        margin-top: 0;

        label {
          color: #b9b9b9;
          margin-bottom: 5px;
        }

        input {
          border-radius: 0 !important;
          padding: 0;
          background: white;
          height: 30px;
          border: none !important;
          box-shadow: none !important;
        }
      }

      .smaller-input {
        .GdInput {
          margin-top: 0;
        }

        input {
          height: 21px;
        }
      }
    }
  }
}


