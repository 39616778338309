@import "../../../../styles/theme";
.n-f-c-icon {
  padding-left: 7px;
  display: flex;
  align-items: center;
  padding-top: 5px;
}

.KPI-widget {
  overflow: hidden;
  border-radius: 15px;
  padding: 25px;
  margin: 5px;
  margin-top: 0;
  margin-bottom: 10px;
  font-family: 'Poppins', sans-serif;
  min-height: 90px;
  display: flex;
  flex-direction: column;
  position: relative;
  -webkit-transition: 0.2s opacity ease-in;
  transition: 0.2s opacity ease-in;
  pointer-events: none;
  opacity: 0.7;

  .usage-bd {
    background-color: red;
    display: flex;
    justify-content: space-between;
  }

  p {
    margin: 0;
  }

  .not-enough {
    display: flex;
    flex: 1 1;
    justify-content: start;
    align-items: center;
    padding-left: 15px;
  }

  .no-tariff-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 1;
  }

  .no-tariff {
    //justify-content: center;
    align-items: center;
    padding-left: 30px;

    p {
      margin: 0;
    }

    .link {
      cursor: pointer;
      margin-right: 10px;
    }

    .GdButton {
      margin: 0 10px;
    }
  }

  &.spend {
    pointer-events: auto !important;
    cursor: default !important;

    &.no-tariffs {
      pointer-events: auto !important;
    }
  }

  &.loaded {
    opacity: 1;

    .kpi-widget-total-row {
      position: relative;
      opacity: 1;
    }
  }

  .flexible-total-text {
    font-weight: 500;
    color: $gd-dark-grey;
  }

  .kpi-widget-total-row {
    opacity: 0;
    position: absolute;
    display: flex;
    margin-top: 5px;
    height: 60px;
    -webkit-transition: 0.5s opacity ease-in;
    transition: 0.5s opacity ease-in;

    &.no-cont {
      margin-top: 29px;
    }

    .kpi-widget-total {
      font-size: 48px;
      font-weight: 500;
      display: flex;
    }

    .kpi-widget-spend {
      font-size: 48px;
      font-weight: 500;
    }


    .unit-column {
      display: flex;
      flex-direction: column;

      .unit {
        font-size: 20px;
        color: grey;
        font-weight: 300;
        padding-left: 5px;
      }

      .spend-unit {
        font-size: 20px;
        color: grey;
        font-weight: 300;
        padding-right: 5px;
      }

      .percentage-changed {
        display: flex;
        align-items: center;
        font-size: 15px;
        padding-top: 3px;
        font-weight: 500;
        padding-bottom: 4px;

        span {
          margin-left: 5px;

          &.up {
            color: #49c570;
          }

          &.down {
            color: #e43536;
          }
        }
      }

      span {

      }
    }
  }

  .button-wrapper {
    -webkit-transition: 0.2s opacity ease-in;
    transition: 0.2s opacity ease-in;
    opacity: 0;
  }

  &:hover {
    .button-wrapper {
      opacity: 1;
    }
  }

  .kpi-loader {
    position: absolute;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 20px;
    left: 20px;
  }

  .kpi-widget-header {
    display: flex;
    align-items: center;

    p {
      margin: 0;
      padding-left: 10px;
      font-weight: 500;
      font-size: 16px;
      white-space: nowrap;
    }

    .icon-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 25px;
      height: 25px;
      border-radius: 100%;
    }
  }


}