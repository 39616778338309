@import "../../styles/theme";

.date-cell {
	padding: 5px;
}

.button-cell {
	padding-top: 25px;
	padding-left: 5px
}

.icon-cell {
	display: flex;
	align-items: flex-end;
	padding-bottom: 15px;
}


.rule {
	width: 100%;
	height: 100%;

	.edit-timeswitch {
		width: 100%;
		background-color: white;
		height: 100%;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		.editableListsWrapper {
			height: 500px;	//FIXME: this is just a quick hack to test list stuff...do this properly lol
		}

		.top-bar {
			position: absolute;
			top: 0px;
			width: 100%;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			.GdButton {
				width: 120px;
				margin: 20px;
			}
		}

		.bottom-bar {
			position: absolute;
			bottom: 0;
			width: 100%;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			margin-bottom: 60px;
			.GdButton {
				margin: 20px;
			}
		}

	}

	p {
		color: $gd-dark-grey;
		font-size: 14px;
		margin-top: 5px;
		margin-bottom: 10px;
		.Autocomplete {
			display: inline-block;
			margin-left: 10px;
		}
		&.sub {
			color: $gd-grey;
			margin-top: 0px;
			font-size: 11px;
		}
	}
	.details {
		//width: 500px;
		//height: 500px;
		display: flex;
		flex-direction: column;

		.title-row {
			display: flex;
			flex-direction: row;

			.Icon {
				margin-right: 20px;
			}
		}

		.name {
			margin-top: 20px;
		}

		.description {
			margin-top: 20px;
		}

		.save {
			margin-top: 20px;

			.GdButton {
				margin-right: 20px;
			}
		}
	}
}
