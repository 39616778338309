@import '../styles/theme';

.segment {
  height: 100%;
  width: 100%;
  border-right: 1px solid #dadada;
  background-color: white;
  }

.active {
  background-color: $gd-brand !important;
}

.container {
  margin: 20px 0;
  height: 15px;
  display: flex;
  border: 1px solid lightgrey;
  border-radius: 2px;
  overflow: hidden;
  :nth-last-child(1) {
    border-right: none;
  }
}