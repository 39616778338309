.family-tree {
  display: flex;
  position: relative;
  flex: 1;
  flex-direction: column;

  .save-cancel {
    display: flex;
    padding: 20px;
    flex-direction: row-reverse;
  }

  p.how-to {
    margin: 0;
    font-size: 13px;
    padding: 20px;
    display: flex;
    align-items: center;
    color: grey;

    .menu-icon {
      width: 30px;
      height: 30px;
      position: relative;
      display: inline-block;
      filter: contrast(0.5);
      background-size: 90% !important;
      background: transparent url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MiIgaGVpZ2h0PSI0MiI+PGcgc3Ryb2tlPSIjRkZGIiBzdHJva2Utd2lkdGg9IjIuOSIgPjxwYXRoIGQ9Ik0xNCAxNS43aDE0LjQiLz48cGF0aCBkPSJNMTQgMjEuNGgxNC40Ii8+PHBhdGggZD0iTTE0IDI3LjFoMTQuNCIvPjwvZz4KPC9zdmc+) no-repeat center;
    }
  }

  .dragging {
    display: flex;
    flex: 1;
    overflow: hidden;
    width: 100%;
    height: 100%;

    .rst__tree {
      position: relative;
      display: flex;
      flex: 1;

      .ReactVirtualized__Grid {
        padding: 0 30px;

        .rst__rowContents {
          min-width: 0;
          font-size: 13px;
          box-shadow: none;
          background-color: transparent;
          color: #525252;
          border: none !important;
        }

        .rst__row {
          background-color: rgba(255, 255, 255, 0.7);
          border: none !important;
        }

        .rst__collapseButton, .rst__expandButton {
          background-size: 71%;
          box-shadow: none;
          background-color: white;
          width: 25px;
          height: 25px;
        }

        .rst__moveHandle, .rst__loadingHandle {
          height: 100%;
          width: 44px;
          border: none !important;
          -webkit-box-shadow: none !important;
          cursor: move;
          z-index: 1;
          box-shadow: none;
          background-color: transparent;
          background-size: 80%;
          filter: contrast(0.4);
          margin-right: -15px;
        }

        .rst__lineHalfHorizontalRight::before, .rst__lineFullVertical::after, .rst__lineHalfVerticalTop::after, .rst__lineHalfVerticalBottom::after {
          background-color: #f3f3f3;
        }

        .rst__lineChildren::after {
          background-color: #f3f3f3;
        }
      }

    }
  }
}
