@import "./src/styles/theme";

.MenuWrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
  &.disabled {
    pointer-events: none;
  }
}

#MenuShowContainer {
  position: relative;
  &.disabled {
    pointer-events: none !important;
    .Icon, .state-select-view, .menu-span, div {
      pointer-events: none !important;
    }
    .state-select-view {
      background-color: transparent !important;
    }
  }
}

.Menu {
  transition: 0.2s opacity ease-out;
  z-index: 1;
  position: fixed;
  background-color: white;
  border-radius: 5px;
  font-family: $primary-font;
  min-width: 200px;
  overflow: hidden;
  box-shadow: none;
  border: 1px solid transparent;
  opacity: 0;
  max-height: 0;
  &.showing {
    opacity: 1;
    box-shadow: $drop-shadow;
    border: 1px solid $gd-border-grey;
    max-height: 600px;
  }
  &.disabled {
    pointer-events: none;
  }
  .MenuHeader {
    padding: 15px 30px 15px 20px;
    background-color: $gd-bg-grey;
    p {
      margin: 0;
    }
    .title {
      color: $gd-dark-grey;
      font-weight: 700;
      margin-bottom: 5px !important;
      font-size: 12px;
    }
    .subtitle {
      font-size: 11px;
      color: grey;
    }
  }
  .MenuItem {
    display: flex;
    align-items: center;
    padding: 10px 16px 10px 15px !important;
    cursor: pointer;
    &.selected {
      background-color: $gd-bg-grey;
    }
    &:hover {
      background-color: $gd-bg-grey;
    }
    p {
      font-size: 10px;
      margin: 0 17px;
      color: $gd-dark-grey;
    }
  }
}
