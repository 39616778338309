// src/styles/_theme.scss

.row-override {
  flex-direction: row !important;
}

.input-note {
  font-size: 12px !important;
  margin-top: 5px !important;
  color: grey;
}

.MuiAlert-filledSuccess {
  //display: flex;
  //justify-content: center;
  //align-items: center;
}
.MuiAlert-filledSuccess {
  padding: 20px !important;
  .MuiAlert-message, .MuiAlert-action, .MuiAlert-icon {
    display: flex !important;
    align-items: center !important;
    padding: 0 !important;
    margin: 0 10px !important;
  }
}

.GdButton {
  .Loader .MuiCircularProgress-colorPrimary {
    color: #fff !important;
  }
}

.received-messages-card {
  background-color: #1e1e1e;
  color: #d4d4d4;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  height: 400px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
  font-family: monospace;
  overflow-y: auto;

  code {
    padding: 16px;
    overflow-y: auto;
    white-space: pre-wrap;
    line-height: 1.5;
    display: block;
    color: #c5c8c6;
    .modbus-message.sent {
      color: #4fc3f7; /* A bright, readable light blue */
    }

    .modbus-message.received {
      color: #81c784; /* A bright, readable light green */
    }
  }
}

.list-field {
  .icon-container {
    margin-right: 5px;
  }
}

h1, h2, h3, h4 {
  font-family: 'Poppins', sans-serif;
}

.modal-body-wrapper .modal-body .dd-no-right .GdInput .MuiSelect-select {
  border-right: none !important;
  border-radius: 10px 0 0 10px !important;
}
.modal-body-wrapper .modal-body .dd-no-left .GdInput .MuiSelect-select {
  border-radius: 0 10px 10px 0 !important;
}

.normal-checkbox label.MuiFormControlLabel-root {
  margin: 0 !important;
  margin-bottom: -8px !important;
  margin-top: 7px !important;
  .MuiCheckbox-root {
    padding-left: 0;
  }
}

.colTab .MuiTableRow-root.no-border .MuiTableCell-root {
  border: inherit !important;
}

.user-drawer .user-wrapper .user-details .header {
  padding-left: 0;
}

.axis-right .tick line, .axis-left .tick line {
  display: none !important;
}

.tick line {
  opacity: 0.7;
}

//.MuiAlert-message {
//  padding: 8px 0;
//  font-size: 13px;
//  color: white;
//}
//.MuiAlert-filledError .MuiAlert-icon {
//  color: white !important;
//}

.MuiAlert-filledWarning {
  color: orange !important;
}

.TabRow.with-dd {
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  padding: 0 15px;
  justify-content: space-between;
  display: flex;
}

.form-divider {
  width: 20px;
}

.modal-body-wrapper .modal-body .Card.wide .data-upload .Tabs{
  min-width: 0 !important;
  background-color: transparent !important;

  .TabRow {
    background-color: transparent !important;
  }
}

.invalid-title {
  font-size: 17px;
  font-weight: 600;
  font-family: Poppins, sans-serif;
  color: #e43535;
}

.error {
  color: #e43535;
}

.file-upload {
  padding: 100px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.menu-divider {
  margin: 0;
  padding: 5px 15px;
  font-size: 10px;
  font-weight: 700;
  background-color: whitesmoke;
}

.MuiAutocomplete-popper {
  box-shadow: 0 3px 5px 2px #00000014;
}

.dark {
  .menu-divider {
    background-color: #373f46;
  }
}

.no-pointer {
  opacity: 0.7;
  pointer-events: none;
}

.flex-spacer {
  display: flex;
  flex: 1 1;
}

.logo-label {
  padding-left: 10px;
  line-height: 30px;
}

.label-and-info {
  label {
    margin-bottom: 0;
  }

  p {
    margin-top: 0;
    padding-left: 10px;
    font-style: italic;
    color: #8f8f8f;
  }
}

.MuiMenuItem-root {
  .icon-container {
    .Icon {
      font-size: 9px !important;
    }
  }
}

a:hover, a:focus {
  text-decoration: none !important;
}

.Crumb {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.Breadcrumbs, .CrumbWrapper {
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
}

.Breadcrumbs {
  padding-right: 10px;
}

.menu-header .MuiLinearProgress-barColorPrimary {
  background-color: #49B9C4FF;
}

.editableListsWrapper, .graph-kpi-legend-wrapper {
  min-width: 800px;
}

#mobile_decorated_page {
  .editableListsWrapper, .graph-kpi-legend-wrapper {
    min-width: 100%;
  }
}

.page, .tabbed-page .data-graphs {
  //min-width: 800px;
  overflow: auto;
}

.tabbed-page .inner-tabbed-page {
  //overflow: auto;
}

// Fonts
$primary-font: "Asap", sans-serif;
$secondary-font: "Open Sans", sans-serif;
.page.with-header-button {
  .GdButton.fill {
    height: 27px;
    //width: 40px;
    padding: 0 20px;
    line-height: 27px;
    font-size: 11px;
    letter-spacing: 0;
    margin-right: 17px;
    z-index: 10;
  }

  .widget-board-header .place-in-header {
    margin-bottom: -40px;
    margin-top: 5px;
  }
}

.st1 {
  fill: #04c4cd
}

.MuiFormControlLabel-root.Checkbox {
  .MuiSvgIcon-root {
    font-size: 1.2rem !important;
  }

  .MuiTypography-body1 {
    font-size: 11px;
    color: #575757;
    font-weight: 500 !important;
  }
}

.fake-label {
  margin-top: 20px;
  transform: none !important;
  position: relative;
  font-size: 12px;
  font-weight: 700;
  color: grey;
  font-family: "Asap", sans-serif;
  margin-bottom: 6px;
  line-height: 16px;
}

.MuiListSubheader-root {
  font-family: "Asap", sans-serif !important;
}

.create-invoice .editableListsWrapper {
  border: 1px solid #e8e8e8;
  border-radius: 5px;
}

.edit-icon {
  display: flex;
  justify-content: center;
  align-items: center;

  .Icon {
    justify-content: center !important;
    align-items: center !important;
    display: flex !important;
  }
}

.kpi-title .minimal-dropdown .GdInput {
  padding-right: 20px;
}

//#disconnected-popover {
//max-width: 523px;
//}

.Menu.showing {
  z-index: 9999 !important;
}

.italic {
  font-style: italic;
}

//.list-row:nth-last-child(1) {
//  border-bottom: none !important;
//}

.MuiAlert-icon {
  padding-top: 13px !important;
}

.MuiAlert-action {
  display: flex;
  margin-left: auto;
  align-items: start !important;
  margin-right: -8px;
  padding-left: 16px;
  padding-top: 5px;
}

.Menu .MenuItem {
  display: flex;
  align-items: center;
  padding: 10px 16px 10px 15px !important;
  cursor: pointer;
}


.Menu .MenuItem p {
  font-size: 10px;
  margin: 0 11px;
  color: #595959;
}

.inner-tabbed-page.data-upload {
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  //margin-top: 15px;
  .contents {
    padding: 10px;
  }
}

.GdAutocomplete .MuiInputBase-root, .GdInput input, .GdInput .MuiSelect-select, .GdInput textarea {
  background-color: transparent !important;
}

.MuiFormControl-root label {
  padding-left: 5px;
}

.GdButton.fill.gd-grey.fake-input {
  border-width: thin !important;
}

.separated-card {
  margin-bottom: 10px;
}

// * {
//   transition: color 0.3s, background-color  0.3s, border-color 0.3s, fill 0.3s, stroke 0.3s !important;
// }

.schedule-table .schedule-rule .cell.day {
  background-color: transparent !important;
  //&.active {
  //  background-color: red !important;
  //}
}

.custom-field-vals {
  .list-row.list-heading .list-field {
    border: none !important;
  }
}

.modal-body-wrapper .modal-body .Card .CardHeader {
  justify-content: start;
  flex-direction: column-reverse;
  border-radius: 15px 0 0 0 !important;
  //border: none !important;
  font-family: 'Poppins', sans-serif;
  font-size: 25px;

  .Icon {
    span {
      font-size: 14px;
    }
  }

  span {
    font-size: 13px;
    font-family: "Asap", "sans-serif";
    font-weight: 500;
    margin-left: 5px;
    color: #868686;
    margin-top: 1px;
  }
}

.light .modal-body-wrapper .modal-body .Card .modal-footer {
  border-radius: 0 !important;
}

.modal-body-wrapper .modal-body .Card {
  align-items: end !important;
  margin: 0 !important;
}

.modal-body-wrapper {
  padding: 0 !important;
}

.modal-body-wrapper .modal-body .Card .CardBody {
  height: 100% !important;
}

.CardBody {
  height: 100% !important;
}

.clickable {
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
}

::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}

.schedule-rule .cell.day {
  border: 1px solid;
}

.info-t {
  border-bottom: 1px solid #e8e8e8;
  margin: 0 !important;
  font-size: 12px !important;
  padding: 10px;
  background: whitesmoke;
  font-style: normal;
  color: grey;
}

::-webkit-scrollbar-track {
  box-shadow: none !important;
  border-radius: 0 !important;
  background: rgba(225, 225, 225, 0.3) !important;
}

::-webkit-scrollbar-thumb {
  background: #d9d9d9 !important;
}

.dark {

  ::-webkit-scrollbar-track {
    background: rgba(66, 66, 66, 0.3) !important;
  }

  ::-webkit-scrollbar-thumb {
    background: #424242 !important;
  }

}

.GlobalSearch .backDrop.showing {
  background-color: rgba(28, 35, 42, 0.95);
}

svg#react-confirm-alert-firm-svg {
  position: absolute;
}

.Checkbox .MuiTypography-body1 {
  font-weight: 400 !important;
}

.edit-container {
  max-height: 29px !important;
  border-bottom: 1px solid #f3f3f3;
}

.error-text {
  padding: 0 25px;
  font-size: 12px;
  color: red;
}

.Menu #MenuShowContainer {
  width: 100% !important;
}

.KPI-Breakdown {
  //min-width: 200px;
  p {
    font-size: 12px;
  }

  .kpi-label {
    font-size: 11px;
    color: grey;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    margin-top: 5px;
  }

  .title {
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 5px;
    font-weight: 600;
  }

  .kpi-value {
    font-size: 18px;
    color: #595959 !important;
    margin: 0;

    &.total {
      font-weight: 700;
      margin-top: 8px;
    }
  }

  .dividing-text {
    font-size: 11px;
    color: grey;
    border: 1px dashed #dedede;
    border-radius: 5px;
    padding: 2px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;

    span {
      font-weight: 500;
      margin: 0 3px;
    }
  }
}

.no-items-text {
  font-size: 19px !important;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  color: grey !important;
  text-align: center;
  margin-bottom: 30px !important;

  .GdButton {
    //margin-top: 30px;
  }

  span {
    font-size: 14px;
    font-style: italic;
  }
}

.MuiMenuItem-root {
  font-size: 11px !important;
}

//Primary colours
$gd-brand: #49b9c4;
$gd-brand-hover: #4dc7d2;

$gd-green: #49c570;
$gd-green-hover: #4ad27b;

$gd-red: #e43536;
$gd-red-hover: #f73738;

$gd-blue: #03a9f4;
$gd-blue-background: #cef3ff;
$gd-blue-border: #add8e6;
$gd-blue-hover: #03b4ff;
$gd-blue-hover-mild: #e7f9ff;

$gd-yellow: #fcba03;

$gd-orange: #fc9403;


//Data Types
$gd-electricity: #FFCA00;
$gd-gas: #D768F2;
$gd-water: #00C3EE;
$gd-water-hot: #f52ec0;
$gd-generation: #3ad56e;

//Greys
$gd-grey: #a7a7a7;
$gd-bg-grey: #f1f1f1;
$gd-border-grey: #e8e8e8;
$gd-dark-grey: #595959;
$gd-dark-blue: #151a25;
$gd-off-black: #212121;

//Other
$drop-shadow: -1px 9px 12px 0px rgba(0, 0, 0, 0.31);
// src/styles/_theme.scss

.user-drawer .user-wrapper .user-details .header {
  padding-left: 0;
}

.axis-right .tick line, .axis-left .tick line {
  display: none !important;
}

.tick line {
  opacity: 0.7;
}

//.MuiAlert-message {
//  padding: 8px 0;
//  font-size: 13px;
//  color: white;
//}
//.MuiAlert-filledError .MuiAlert-icon {
//  color: white !important;
//}

.MuiAlert-filledWarning {
  color: orange !important;
}

.TabRow.with-dd {
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  padding: 0 15px;
  justify-content: space-between;
  display: flex;
}

.no-pointer {
  opacity: 0.7;
  pointer-events: none;
}

.flex-spacer {
  display: flex;
  flex: 1 1;
}

.logo-label {
  padding-left: 10px;
  line-height: 30px;
}

.label-and-info {
  label {
    margin-bottom: 0;
  }

  p {
    margin-top: 0;
    padding-left: 10px;
    font-style: italic;
    color: #8f8f8f;
  }
}

.MuiMenuItem-root {
  .icon-container {
    .Icon {
      font-size: 9px !important;
    }
  }
}

a:hover, a:focus {
  text-decoration: none !important;
}

.Crumb {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.Breadcrumbs, .CrumbWrapper {
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
}

.Breadcrumbs {
  padding-right: 10px;
}

.menu-header .MuiLinearProgress-barColorPrimary {
  background-color: #49B9C4FF;
}

.editableListsWrapper, .graph-kpi-legend-wrapper {
  min-width: 800px;
}

#mobile_decorated_page {
  .editableListsWrapper, .graph-kpi-legend-wrapper {
    min-width: 100%;
  }
}

.page, .tabbed-page .data-graphs {
  //min-width: 800px;
  overflow: auto;
}

.tabbed-page .inner-tabbed-page {
  //overflow: auto;
}

// Fonts
$primary-font: "Asap", sans-serif;
$secondary-font: "Open Sans", sans-serif;
.page.with-header-button {
  .GdButton.fill {
    height: 27px;
    //width: 40px;
    padding: 0 20px;
    line-height: 27px;
    font-size: 11px;
    letter-spacing: 0;
    margin-right: 17px;
    z-index: 10;
  }

  .widget-board-header .place-in-header {
    margin-bottom: -40px;
    margin-top: 5px;
  }
}

.st1 {
  fill: #04c4cd
}

.MuiFormControlLabel-root.Checkbox {
  .MuiSvgIcon-root {
    font-size: 1.2rem !important;
  }

  .MuiTypography-body1 {
    font-size: 11px;
    color: #575757;
    font-weight: 500 !important;
  }
}

.fake-label {
  margin-top: 20px;
  transform: none !important;
  position: relative;
  font-size: 12px;
  font-weight: 700;
  color: grey;
  font-family: "Asap", sans-serif;
  margin-bottom: 6px;
  line-height: 16px;
}

.MuiListSubheader-root {
  font-family: "Asap", sans-serif !important;
}

.create-invoice .editableListsWrapper {
  border: 1px solid #e8e8e8;
  border-radius: 5px;
}

.edit-icon {
  display: flex;
  justify-content: center;
  align-items: center;

  .Icon {
    justify-content: center !important;
    align-items: center !important;
    display: flex !important;
  }
}

.kpi-title .minimal-dropdown .GdInput {
  padding-right: 20px;
}

//#disconnected-popover {
//max-width: 523px;
//}

.Menu.showing {
  z-index: 9999 !important;
}

.italic {
  font-style: italic;
}

//.list-row:nth-last-child(1) {
//  border-bottom: none !important;
//}

.MuiAlert-icon {
  padding-top: 13px !important;
}

.MuiAlert-action {
  display: flex;
  margin-left: auto;
  align-items: start !important;
  margin-right: -8px;
  padding-left: 16px;
  padding-top: 5px;
}

.Menu .MenuItem {
  display: flex;
  align-items: center;
  padding: 10px 16px 10px 15px !important;
  cursor: pointer;
}


.Menu .MenuItem p {
  font-size: 10px;
  margin: 0 11px;
  color: #595959;
}

.GdAutocomplete .MuiInputBase-root, .GdInput input, .GdInput .MuiSelect-select, .GdInput textarea {
  background-color: transparent !important;
}

.GdButton.fill.gd-grey.fake-input {
  border-width: thin !important;
}

.separated-card {
  margin-bottom: 10px;
}

// * {
//   transition: color 0.3s, background-color  0.3s, border-color 0.3s, fill 0.3s, stroke 0.3s !important;
// }

.schedule-table .schedule-rule .cell.day {
  background-color: transparent !important;
  //&.active {
  //  background-color: red !important;
  //}
}

.custom-field-vals {
  .list-row.list-heading .list-field {
    border: none !important;
  }
}

.modal-body-wrapper .modal-body .Card .CardHeader {
  justify-content: start;
  flex-direction: column-reverse;
  border-radius: 15px 0 0 0 !important;
  //border: none !important;
  font-family: 'Poppins', sans-serif;
  font-size: 25px;

  .Icon {
    span {
      font-size: 14px;
    }
  }

  span {
    font-size: 13px;
    font-family: "Asap", "sans-serif";
    font-weight: 500;
    margin-left: 5px;
    color: #868686;
    margin-top: 1px;
  }
}

.light .modal-body-wrapper .modal-body .Card .modal-footer {
  border-radius: 0 !important;
}

.modal-body-wrapper .modal-body .Card {
  align-items: end !important;
  margin: 0 !important;
}

.modal-body-wrapper {
  padding: 0 !important;
}

.modal-body-wrapper .modal-body .Card .CardBody {
  height: 100% !important;
}

.CardBody {
  height: 100% !important;
}

.clickable {
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
}

::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}

.schedule-rule .cell.day {
  border: 1px solid;
}

.info-t {
  border-bottom: 1px solid #e8e8e8;
  margin: 0 !important;
  font-size: 12px !important;
  padding: 10px;
  background: whitesmoke;
  font-style: normal;
  color: grey;
}

::-webkit-scrollbar-track {
  box-shadow: none !important;
  border-radius: 0 !important;
  background: rgba(225, 225, 225, 0.3) !important;
}

::-webkit-scrollbar-thumb {
  background: #d9d9d9 !important;
}

.dark {

  ::-webkit-scrollbar-track {
    background: rgba(66, 66, 66, 0.3) !important;
  }

  ::-webkit-scrollbar-thumb {
    background: #424242 !important;
  }

}

.GlobalSearch .backDrop.showing {
  background-color: rgba(28, 35, 42, 0.95);
}

svg#react-confirm-alert-firm-svg {
  position: absolute;
}

.Checkbox .MuiTypography-body1 {
  font-weight: 400 !important;
}

.edit-container {
  max-height: 29px !important;
  border-bottom: 1px solid #f3f3f3;
}

.error-text {
  padding: 0 25px;
  font-size: 12px;
  color: red;
}

.Menu #MenuShowContainer {
  width: 100% !important;
}

.KPI-Breakdown {
  //min-width: 200px;
  p {
    font-size: 12px;
  }

  .kpi-label {
    font-size: 11px;
    color: grey;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    margin-top: 5px;
  }

  .title {
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 5px;
    font-weight: 600;
  }

  .kpi-value {
    font-size: 18px;
    color: #595959 !important;
    margin: 0;

    &.total {
      font-weight: 700;
      margin-top: 8px;
    }
  }

  .dividing-text {
    font-size: 11px;
    color: grey;
    border: 1px dashed #dedede;
    border-radius: 5px;
    padding: 2px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;

    span {
      font-weight: 500;
      margin: 0 3px;
    }
  }
}

.no-items-text {
  font-size: 19px !important;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  color: grey !important;
  text-align: center;
  margin-bottom: 30px !important;

  .GdButton {
    //margin-top: 30px;
  }

  span {
    font-size: 14px;
    font-style: italic;
  }
}

.MuiMenuItem-root {
  font-size: 11px !important;
}

//Primary colours
$gd-brand: #49b9c4;
$gd-brand-hover: #4dc7d2;

$gd-green: #49c570;
$gd-green-hover: #4ad27b;

$gd-red: #e43536;
$gd-red-hover: #f73738;

$gd-blue: #03a9f4;
$gd-blue-background: #cef3ff;
$gd-blue-border: #add8e6;
$gd-blue-hover: #03b4ff;
$gd-blue-hover-mild: #e7f9ff;

$gd-yellow: #fcba03;

$gd-orange: #fc9403;


//Data Types
$gd-electricity: #FFCA00;
$gd-gas: #D768F2;
$gd-water: #00C3EE;
$gd-water-hot: #f52ec0;
$gd-generation: #3ad56e;

//Greys
$gd-grey: #a7a7a7;
$gd-bg-grey: #f1f1f1;
$gd-border-grey: #e8e8e8;
$gd-dark-grey: #595959;
$gd-dark-blue: #151a25;
$gd-off-black: #212121;

//Other
$drop-shadow: -1px 9px 12px 0px rgba(0, 0, 0, 0.31);
