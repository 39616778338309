@import "../../styles/theme";

.site-group-general-settings {
	max-width: 500px;
}
.GdSiteGroup.summary {
	border: none;
	border-bottom: 1px solid #e8e8e8;
	background-color: $gd-bg-grey;
	border-radius: 0px;
}
.mobile-site-group-sites {
	overflow: hidden;
	background-color: white;
	flex: 1 1;
	flex-direction: column;
	display: flex;
}
.site-page {


	.edit-row {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		padding: 10px;
	}
}
