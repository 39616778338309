@import "../../../styles/theme";

.left-panel.audit {
  width: 280px;
}

.audit-title-container, .audit-summary, .audit-issues, .audit-note {
  padding-left: 25px !important;
}

.audit-title-container {
  display: flex;
  flex-direction: column;
  align-content: center;
  padding: 18px 18px ;
  //padding: 20px 15px ;
  border-bottom: 1px solid #e8e8e8;
  p {
    margin-top: 0;
  }
  .title {
    font-size: 20px;
    font-weight: 600;
  }
  .subtitle {
    font-size: 13px;
    margin-top: 5px;
    margin-bottom: 0px;
  }
  .bold {
    font-weight: 600;
    margin-top: 5px;
    margin-bottom: 0px;
  }
}

.audit-summary {
  display: flex;
  flex-direction: column;
  align-content: center;
  padding: 10px 18px ;
  border-bottom: 1px solid #e8e8e8;

  .summary-text {
    font-size: 13px;
  }

}

.audit-issues {
  display: flex;
  flex-direction: column;
  align-content: center;
  padding: 10px 18px ;
  border-bottom: 1px solid #e8e8e8;

    .severe {
      font-size: 13px;
      font-weight: 600;
      color: $gd-red
    }

    .moderate {
      font-size: 13px;
      font-weight: 600;
      color: $gd-orange
    }

    .minor {
      font-size: 13px;
      font-weight: 600;
      color: $gd-yellow
    }

  .issue-text {
    font-size: 13px;
  }


}

.audit-note {
  display: flex;
  flex-direction: column;
  align-content: center;
  padding: 10px 18px;
  border-bottom: 1px solid #e8e8e8;
  .title {
    font-size: 13px;
    font-weight: 600;
  }
  .note {
    font-size: 13px;
  }
}

.audit-header {
  padding: 15px 15px;
  width: 100%;
  background-color: $gd-orange;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.audit-site-link {
  padding: 0px 10px;
  cursor: pointer;

}

.audit-header-text {
  font-size: 12px;
  font-weight: 500;
  color: white;
  margin-bottom: 0px;
  margin-top: 0px;
  &.link {
    font-weight: 700;

  }
}
