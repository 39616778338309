.subscriptions {
  padding: 20px;
}

.subscriptions {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.subscription {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.subscription-id {
  font-size: 1.2em;
  font-weight: bold;
  color: #333;
}

.subscription-status {
  color: #888;
}

.subscription-currency,
.subscription-method,
.subscription-period {
  margin: 5px 0;
}

.subscription-discount-title,
.subscription-items-title,
.subscription-tax-rate-title {
  margin-top: 15px;
  font-weight: bold;
  color: #555;
}

.subscription-discount,
.subscription-no-discount {
  color: #444;
}

.subscription-item {
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fefefe;
}

.item-product,
.item-unit-price,
.item-quantity {
  margin: 3px 0;
}

.subscription-tax-rate,
.subscription-no-tax-rate {
  color: #444;
  margin-top: 5px;
}

.list-align {
  display: flex;
  align-items: center;
  padding-right: 10px;
  bottom: 3px;
  position: relative;
  min-width: 50px;
}

#mobile_decorated_page {
  .row {
    .Checkbox {
      min-width: 110px;
    }

    .GdInput {
      min-width: 200px;
      max-width: 240px;
    }

    .date_time_picker {
      padding-right: 10px;
    }
  }
}