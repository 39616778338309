.issue-icon-wrapper p, .issue-icon-wrapper div.issue-number-text {
  margin-left: 5px !important;
  font-size: 10px !important;
  line-height: 10px !important;
}
.issue-popover {
  .issues-context {
    //border: 1px solid #e8e8e8;
    border-bottom: none;
    margin-bottom: 10px;
    //border-radius: 5px;
    overflow: hidden;
    padding: 0;
  }
  .issue-percentage {
    position: relative;
    width: 100%;
    height: 5px;
    background-color: #49c570;
    overflow: hidden;
    .issue-percentage-bar {
      position: absolute;
      right: 0;
      height: 100%;
      background-color: #e43535;
      overflow: hidden;
    }
  }

  .issue-row {
    align-items: center;
    margin-bottom: 5px;
    &.title-row {
      //padding: 5px 10px 0 10px;
    }
  }

  .issue-popover-icon {
    .Icon {
      display: flex !important;
    }
  }

  .issue-popover-item-text {
    margin: 0;
    margin-left: 5px;
    font-size: 12px !important;
    color: grey;
    span.fat {
      //font-size: 13px !important;
      font-weight: 600;
    }
  }
}

.CrumbWrapper {
  min-width: 72px;

  &.mobile {
    min-width: 50px;
  }

  .issue-icon-wrapper {
    p {
      padding-top: 2px;
    }
  }
}

.issue-icon-wrapper {
  display: inline-flex;
  margin-left: 5px;
  color: white;
  align-items: center;
  pointer-events: all !important;
  user-select: none;
  &.header-icon {
    border: 1px solid #e8e8e8;
    border-radius: 40px;
    height: 29px;
    padding: 0 15px;
    margin-left: 0;
    margin-right: 10px;
    font-size: 10px !important;
    font-weight: 700;
    line-height: 10px;
    p {
      font-size: 10px !important;
    }
  }

  .Icon {
    font-size: 12px;
    //margin-right: 3px;
    //display: flex !important;
    //justify-content: center;
    //align-items: center;
  }

  p, div {
    //line-height: 0.7em;
    margin: 0;
    margin-left: 0 !important;
    font-size: 11px !important;
    line-height: 11px;
    font-weight: 600 !important;
    pointer-events: all !important;
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
  }
}
