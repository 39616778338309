@import "../styles/theme";

.GlobalSearch .GdAutocomplete .MuiInputBase-root input {
  height: 8px !important;
}

.gaia-logo-no-txt {
  height: 30px;
  img {
    width: 20px;
    height: auto;
  }
}

@keyframes glowAndZoom {
  0% {
    box-shadow: 0 0 0px rgba(255, 165, 0, 0);
    transform: scale(1);
  }
  50% {
    box-shadow: 0 0 8px rgba(255, 165, 0, 0.5);
    transform: scale(1.2);
  }
  100% {
    box-shadow: 0 0 0px rgba(255, 165, 0, 0);
    transform: scale(1);
  }
}

//.GlobalSearch .GdAutocomplete .MuiInputBase-root {
//  font-size: 11px !important;
//}

.notification-button-container {
  border-radius: 40px;
  padding: 1px;
  margin-right: 10px;
}

.top-nav-icon {
  position: relative;
  overflow: visible;
  line-height: 0;
  border-color: transparent !important;
  cursor: pointer;
  background: #8080801a;
  display: flex;
  border-radius: 30px;
  padding: 8px 8px 8px 13px;
  justify-content: center;
  align-items: center;
  flex-direction: row-reverse;
  margin-right: 10px !important;
  &.remove-padding {
    margin-right: 0px !important;
  }

  .indicator-container {
    display: flex;
    border-radius: 30px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .row {
      flex-direction: row;
      display: flex;
      align-items: center;
      justify-content: center;

      .dot {
        margin: 2px;
        height: 4px;
        width: 4px;
        border-radius: 4px;
      }
    }


    &.single-type {
      //background-color: blue;
    }

    &.double-type {
      //background-color: green;
    }
  }

  &.no-border {
    border: none;
  }
  &.alerts {
    border: none !important;
  }

  &.activated {
    &.alert {
      border-color: transparent !important;
      background-color: #ffa5001a !important;
      .not-dot {
        color: orange !important;
      }
    }
    &.alerts {
      border: none !important;
      //background-color: rgba(255, 255, 255, 0.8);
      //border-color: #f89605 !important;
      margin-right: 0px !important;

      svg {
        //color: #49b9c4 !important;
      }

      .not-dot {
        //color: #f89605;
      }
    }


    &.disconnected {
      border-color: rgb(228, 53, 54) !important;

      svg {
        //color: #fc1e1e !important;
      }

      .not-dot {
        color: rgb(228, 53, 54);

      }
    }
  }
}

.dark {
  .menu-divider {
    background-color: #373f46;
  }
}

.menu-divider {
  margin: 0;
  padding: 5px 15px;
  font-size: 10px;
  font-weight: 700;
  background-color: whitesmoke;
  font-family: "Asap", sans-serif;
}

.icon-w {
  padding: 7px 8px;
}

.not-dot {
  z-index: 2;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  //border: 1px solid white;
  padding: 0px 10px;
  padding-left: 0;
  margin-top: 2px;
  &.no-padding {
    padding: 0;
  }

}

.Add-in-top-nav {
  width: 15px;
  height: 15px;
  background-color: #49b9c4;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 6px;
}

#notifications-popover {
  .MuiPopover-paper {
    max-width: 500px;
  }
}

#top_nav {
  height: 55px;
  min-height: 55px;
  max-height: 55px;
  position: relative;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid $gd-border-grey;
  background-color: white;
  align-items: center;
  padding: 0 20px;
  padding-right: 10px;

  .top-nav-icon {
    &.animate-glow-zoom {
      animation: glowAndZoom 0.4s ease-in-out forwards;
    }

    position: relative;
    overflow: visible;
    margin-right: 15px;
    line-height: 0;
    cursor: pointer;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    border: 1px solid #e8e8e8;
    padding: 0 10px;
    //&.alerts, &.disconnected {
    //  margin-right: 0 !important;
    //}
    &:hover {
      opacity: 0.7;
    }
  }

  .Loader {
    //margin-top: 2px;
    display: inline-block;

    .MuiCircularProgress-colorPrimary {
      color: white;
    }
  }

  .new-button {
    display: inline-block;
    position: relative;
    overflow: hidden;
    width: 85px;
    margin-right: 11px;
    //margin-top: 3px;
    .GdButton.fill {
      height: 27px;
      width: 40px;
      padding: 0 20px;
      line-height: 27px;
      font-size: 11px;
      letter-spacing: 0;
      margin-right: 17px;
    }
  }

  .Breadcrumbs {
    display: flex;
    flex: auto;
    font-size: 15px !important;
  }

  .profile-image {
    width: 27px !important;
    height: 27px !important;
    overflow: hidden;
    border: none !important;
    background-size: cover;
    background-position: center;
    cursor: pointer;
    background-color: #2a363f;
    -webkit-user-select: none;
    user-select: none;
    margin-right: 10px;
    border-radius: 100%;

    &:hover {
      opacity: 0.7;
    }
  }

}
