.pw-contains {
  user-select: none;
  margin-top: 10px;

  .column {
    align-items: start !important;
  }

  .row {
    align-items: center !important;
    margin-bottom: 5px;
  }

  .Icon {
    width: 20px;
    height: 12px;
  }

  span {
    font-size: 13px;
    color: grey;
    margin-left: 5px;
    top: -2px;
    position: relative;
  }

  .pw-attr {
    input.checkbox {
      pointer-events: none;
    }
  }
}