@import "./../styles/theme";

.cancel-compare {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.checkbox-wrapper-wrapper {
  margin: 10px 0;
}

.checkbox-wrapper {
  overflow: hidden;
  height: 25px;
  display: flex;
  align-items: center;

  .Icon {
    display: flex !important;
    position: relative !important;
    pointer-events: auto !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .MuiFormControlLabel-root {
    margin-right: 8px;
  }

  label {
    margin-bottom: 0 !important;
  }
}

.GdButton.fill.mini {
  height: 27px;
  line-height: 30px;
  font-size: 11px;
  letter-spacing: 0;
  margin-right: 17px;
}

.overlays {
  .header {

  }

  .label {
    font-size: 11px;
    font-weight: bold;
  }

  flex: 0 1;
  padding: 0 15px;
  padding-top: 12px;
  border-bottom: 1px solid #e8e8e8;
}

#legend-menu {

  .MuiMenu-list {
    border: 1px solid lightgrey;
    border-radius: 5px;
    padding: 15px;
    padding-top: 10px;
  }
}

.legend-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding-right: 20px;
}

.legend-column {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.legend-value {
  margin-left: 40px;
  padding-top: 5px;
  min-height: 30px;
  color: #525252;
  font-size: 12px;

  .comparison {
    color: grey
  }
}

.graph-legend-wrapper {
  display: flex;
  flex: 1 1;
  overflow: hidden;

  &.copy-inside {
    padding: 10px;
    padding-bottom: 0;
    font-weight: 700;
    font-size: 12px;
  }
}

.legend-wrapper {
  min-width: min-content;
  width: auto;
  &.detached {
    position: fixed;
    z-index: 99999;
    border: 1px solid #e8e8e8;
    border-radius: 10px;
    right: 10px;
    top: 253px;
    pointer-events: none;
    opacity: 0.95;
    &.animate {
      transition: 0.2s top linear, 0.2s left linear;
    }
  }
  &.hidden {
    display: none;
  }

  .reset-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    //padding-top: 10px;
    //height: 30px;
    //padding-left: 20px;
    //padding-right: 20px;
    -webkit-transition: 0.2s all ease-in;
    transition: 0.2s all ease-in;
    justify-content: space-between;
    .reset-button {
      //position: absolute;
      cursor: pointer;
      right: 0;
      //margin-right: 15px;

      &:hover {
        opacity: 0.7;
      }
    }

    .header-text {
      font-family: "Asap", sans-serif;
      font-weight: bold;
      font-size: 12px;
      color: #595959;
    }

    .text {
      font-family: "Asap", sans-serif;
      font-size: 12px;
    }

    .icon-wrapper {
      padding-left: 6px;
    }

  }

  &.locked {
    border-color: orange;
    background: #fff8eb;

    .MuiFormControl-root, .date_time_picker, .cancel-compare, .GdButton, {
      pointer-events: none !important;
      opacity: 0.7 !important;
    }

    .MuiInput-input, .rs-picker-date.rs-picker-cleanable.rs-picker-has-value .rs-picker-toggle, .rs-picker-daterange.rs-picker-cleanable.rs-picker-has-value .rs-picker-toggle, .rs-picker-default .rs-picker-toggle {
      -webkit-transition: 0.2s all ease-in;
      transition: 0.2s all ease-in;
      border-color: orange !important;
      pointer-events: none !important;
      opacity: 0.8 !important;
    }

    .legend-header, .legend-granularity, .legend-date, .legend-timestamp {
      border-color: orange;
    }
  }

  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  border-left: 1px solid $gd-border-grey;
  max-width: 250px;

  .row-dropdowns-wrapper {
    position: relative;
    overflow: hidden;
    display: flex;

    .row-dropdowns {
      border-bottom: 1px solid $gd-border-grey;
      padding: 10px 5px;
      overflow-x: auto;
    }
  }

  //&.with-custom {
  //  max-width: 310px;
  //}

  &.disabled {
    opacity: 0.7;
    pointer-events: none;
  }

  &.zoomed {

  }

  &.closed {
    max-width: 60px;

    .legend-header .reset-icon {
      width: 30px;
    }

    .graph-legend .legend-item .legend-indicator {
      margin: 0 25px;
    }
  }
}

.legend-granularity {
  .Dropdown {
    margin-top: 10px;
  }

  padding: 15px;
  position: relative;
  border-bottom: 1px solid #e8e8e8;
  flex-direction: row;
  -webkit-transition: 0.2s all ease-in;
  transition: 0.2s all ease-in;

  &.extended {
    //height: 150px;
    //max-height: 150px;
    &.custom {
      //height: 190px;
      //max-height: 190px;
    }
  }

  &.custom {
    //height: 150px;
    //max-height: 150px;
  }

  .GdButton {
    margin-top: 10px;
  }

  .label {
    //margin-top: 10px;
    //margin-left: 20px;
    font-size: 11px;
    font-weight: bold;

    .date_time_picker {
      margin-top: 10px;
    }
  }

}

.date_time_picker .rs-picker, .rs-picker-toggle {
  width: 100%;
}

.legend-date {
  .Dropdown {
    margin-top: 10px;
  }

  display: flex;
  align-items: center;
  //flex: 1 1;
  padding: 15px;
  min-height: 41px;
  position: relative;
  border-bottom: 1px solid #e8e8e8;
  flex-direction: row;
  -webkit-transition: 0.2s all ease-in;
  transition: 0.2s all ease-in;

  span.compare-to-label {
    display: inline-block;
    margin-top: 10px;
  }

  &.summary-mode {
    border: none;
    padding: 0;
    //min-width: 150px;
    //padding-bottom: 10px;
    .label {
      align-items: center;
      display: flex;

      .GdButton {
        margin: 0 !important;
      }
    }

    .Dropdown {
      //display: contents;
      //min-width: 137px;
      margin-top: 0 !important;
      margin-right: 0;
    }

    .date_time_picker {
      margin-top: 0 !important;
      margin-right: 10px;
      input {
        &:hover {
          opacity: 0.7;
        }
        cursor: pointer;
        border-radius: 0 !important;
        width: 127px;
        height: 30px;
        border: none !important;
        box-shadow: none !important;
        outline: none !important;
        padding: 0 !important;
      }
      input, span {
        font-size: 11px !important;
      }
    }

    .MuiSelect-select {
      font-size: 11px !important;
      background-color: white;
      border-radius: 30px !important;
      padding-left: 20px;
    }

    .GdButton {
      padding: 0 25px !important;
    }
  }

  &.extended {
    //height: 150px;
    //max-height: 150px;
    &.custom {
      //height: 190px;
      //max-height: 190px;
    }
  }

  &.custom {
    //height: 150px;
    //max-height: 150px;
  }

  .GdButton {
    margin-top: 10px;
    width: 100%;
    padding: 0;
  }

  .label {
    //margin-top: 10px;
    //margin-left: 20px;
    font-size: 11px;
    font-weight: bold;

    .date_time_picker {
      margin-top: 10px;
    }
  }

}

.legend-timestamp {
  display: inline-flex;
  -webkit-transition: 0.2s all ease-in;
  transition: 0.2s all ease-in;
  //flex: 1 1;
  position: relative;
  border: 1px solid transparent !important;
  flex-direction: column;
  //height: 47px;
  //max-height: 47px;
  //padding: 0 15px;
  overflow: hidden;

  &.extended {
    //height: 60px;
    //max-height: 60px;
  }

  &.hidden {
    display: none;
    //height: 0;
    //max-height: 0;
    //border-color: transparent;
  }

  .timestamp {
    //margin-top: 15px;
    //margin-left: 20px;
    //padding-left: 5px;
    font-size: 12px;
    display: inline-flex;
    align-items: center;
    line-height: 5px;

    span {
      margin-right: 5px;

      &.bold {
        font-weight: bold;
      }
    }

  }
}

.legend-header {
  font-family: "Asap", sans-serif;
  display: flex;
  flex: 1 1;
  position: relative;
  border-bottom: 1px solid #e8e8e8;
  //flex-direction: row-reverse;
  max-height: 41px;
  font-size: 13px;
  -webkit-transition: 0.2s all ease-in;
  transition: 0.2s all ease-in;
  font-weight: 700;
  padding-bottom: 10px;
  color: $gd-dark-grey;
  margin-bottom: 10px;

  .reset-icon {
    -webkit-transition: 0.2s width ease-in;
    transition: 0.2s width ease-in;
    cursor: pointer;
    position: relative;
    width: 41px;
    height: 41px;
    top: 0;
    right: 0;
    text-align: center;
    line-height: 41px;
    //color: #656565;

    &.refresh {
      line-height: 39px;
    }

    &:hover {
      opacity: 0.7;
    }
  }
}

.legend-menu-container {

  .loader-wrapper .loader-inner {
    padding: 13px 50px;
    width: auto;
  }

  .row {
    display: flex;
    margin-top: 10px;
    margin-bottom: 5px;
    padding-right: 25px;
    cursor: pointer;
    align-items: center;

    &:hover {
      opacity: 0.7;
    }

    .legend-title {
      font-weight: bold;
      font-size: 12px;
      color: $gd-dark-grey
    }

    .legend-subtitle {
      font-size: 10px;
      color: $gd-grey
    }

    .legend-menu-icon-container {
      display: flex;
      position: absolute;
      right: 0;
      margin-left: 10px;
      padding-right: 20px;
    }
  }
}


.legend-menu {

  .legend-icon {
    margin-left: 5px;

    &:hover {
      opacity: 0.7;
    }
  }

}

.legend-header-wrapper {
  padding: 15px;
  //border-bottom: 1px solid #e8e8e8;
}

.graph-legend {
  //display: flex;
  margin: 0;
  //flex-direction: column;
  padding-bottom: 15px;
  position: relative;
  overflow: auto;
  width: 100%;

  &.row {
    overflow-x: auto;
    padding: 10px;
    //border: 1px solid $gd-border-grey;
    .legend-item {
      width: auto;
      min-height: 0;
      display: inline-block;
      overflow: visible;

      .legend-label {
        white-space: nowrap;
      }

      .legend-indicator {
        margin: 0 5px !important;
      }

      .legend-value {
        min-height: 0;
        margin-left: 20px;
      }
    }
  }

  &.highlighted {
    .legend-item {
      opacity: 0.5;

      &.hidden {
        opacity: 0.3;

        &.highlighted {
          opacity: 1;
        }
      }

      &.highlighted {
        opacity: 1;
      }
    }
  }

  .legend-item {

    width: 75%;
    min-height: 40px;
    display: flex;
    flex-direction: column;
    //overflow: hidden;
    align-items: center;

    &.hidden {
      opacity: 0.3;
    }

    .heatmap-legend {
      margin-left: 40px;
      margin-bottom: 20px;
      width: 100%;
      height: 20px;
      border-radius: 5px;
      position: relative;

      .heatmap-indicator {
        transition: 0.2s left ease-in-out;
        position: absolute;
        left: 20%;
        margin-left: -7px;
        top: 12px;
        width: 14px;
        height: 14px;
      }
    }

    .legend-indicator {
      min-width: 8px;
      min-height: 8px;
      margin: 0 20px;
      margin-right: 10px;
      border-radius: 100%;
      -webkit-transition: 0.2s margin ease-in;
      transition: 0.2s margin ease-in;
    }

    .legend-label {

      font-weight: bold;
      font-size: 12px;
      //color: #525252;
      color: $gd-dark-grey;
      line-height: 14px;
    }

    .rear-container {
      position: absolute;
      right: 0;
      margin-right: 10px;
      display: flex;
      flex-direction: row-reverse;

      .legend-icon {
        cursor: pointer;
        //margin-left: 10px;
        padding-right: 5px;

        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
}
