@import "./../../styles/theme";

.create-asset-page {
	background-color: white;
	.scanning-notice {
		font-weight: bolder;
	}
	.scanning-instructions {
		background-color: $gd-bg-grey;
		padding: 10px;
	}
	.scanning-results {
		flex: 1;
		flex-direction: column;
		.scanning-result {
			padding: 10px;
			display: flex;
			flex-direction: row;
			.image {
				width: 50px;
				height: 50px;
				background-size: contain;
				background-position: center;
				background-repeat: no-repeat;
				margin-left: 20px;
				margin-right: 20px;
			}
			.description {
				margin-left: 20px;
				margin-right: 20px;
				line-height: 40px;
			}
			.add-button {
				margin-left: 20px;
				margin-right: 20px;
				cursor: pointer;
			}
			.identify-button {
				margin-left: 20px;
				margin-right: 20px;
				cursor: pointer;
			}
		}
	}
}
