ListPopover {

  .MuiFormControlLabel-label {
    padding-left: 5px !important;
  }

  .MuiPaper-root {
    -webkit-box-shadow: 0px 2px 5px 4px rgba(50, 50, 50, 0.09) !important;
    box-shadow: 0px 2px 5px 4px rgba(50, 50, 50, 0.09) !important;
  }

}