@import url('https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i');

#DemoModal.ReactModal__Content.ReactModal__Content--after-open {
  height: 100% !important;
  width: 100% !important;
  top: 0 !important;
  left: 0 !important;
  padding: 0 !important;
  border: none !important;
  display: flex !important;
  flex: 1 1 !important;
  justify-content: center !important;
  align-content: center !important;
}

#DemoModal .modal-content .modal-header{
  margin-top: 50px;
}

#DemoModal .modal-content .button-row{
  padding-bottom: 50px;
}

.tos-wrapper {
  max-height: 250px;
  font-size: 11px;
  overflow: auto;
  margin-bottom: 10px;
}

.outer-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  a {
    margin-right: 38px;
    color: lightgrey;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
      text-decoration: underline;
    }
  }
}
#DemoModal {
  background-color: rgba(0, 0, 0, 0.8) !important;
  justify-content: center;
  .divider {
    background-color: #f1f1f1 !important;
    margin: 20px 0 !important;
  }
  video {
    border-radius: 5px;
    border: 2px solid #3e3e3e;
    overflow: hidden;
  }
  .modal-content {
    display: flex;
    flex-direction: column;
    .modal-header {
      font-family: 'Poppins', sans-serif;
      margin-bottom: 20px;
      p {
        color: white;
        &.welcome {
          color: grey;
          font-size: 18px;
          line-height: 24px;
          font-weight: 300;
         span.to-the {
           font-size: 14px;
          font-weight: 200;
         }
        }
        &.gd-dash {
          font-size: 30px;
          font-weight: 600;
        }
      }
    }
    .button-row {
      display: flex;
      justify-content: center;
    }
    video {
      max-width: 50vw;
    }
    .choose-wrapper {
      background-color: white;
      padding: 20px 30px;
      border-radius: 5px;
      min-width: 600px;
      .ch-intro {
        font-style: italic;
      }
      .choice-row {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 50px 0;
        p {
          margin: 0 20px;
          color: grey;
        }
      }
      .dw-txt {
        color: grey;
        font-size: 10px;
      }
    }
  }
}
.ReactModal__Overlay.ReactModal__Overlay--after-open {
  background-color: rgba(0, 0, 0, 0.5) !important;
}
.demo-bar {
  font-size: 13px;
  position: relative;
  background-color: #de5858;
  text-align: center;
  height: 45px;
  justify-content: center;
  align-items: center;
  display: flex;
  color: white;
  font-weight: 500;
  padding: 0 20px;
  overflow: hidden;
  font-family: 'Poppins', sans-serif;
  .link {
    color: white;
    border-bottom: none;
    display: inline-block;
    cursor: pointer;
    margin: 0 5px;
    line-height: 17px;
  }
  .GdButton {
    font-size: 11px;
    height: 30px;
    line-height: 30px;
    width: 53px;
    margin-left: 5px;
    background-color: white !important;
    color: #de5858 !important;
    border: 2px solid #de5858 !important;
    &:hover {
      opacity: 0.7;
    }
  }
}