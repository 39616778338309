.mobile-site-group-sites {
  background-color: white;
}

.name-status-container {
  display: flex;
  flex-direction: row;

  .icon {
    position: relative;
    bottom: 2px;
  }
  .name {
    padding-left: 5px;
  }
}
