.list-item-modal {
  line-height: 25px;
  font-size: 13px;
  color: #313131;
  margin-bottom: 5px;
}
.divider-text {
  padding: 10px 15px;
  font-size: 12px;
  background: #efefef;
  font-weight: 700;
  border-bottom: 1px solid #e8e8e8;
}
.link {
  color: #49b9c4;
  border-bottom: 1px dashed #49b9c4;
  display: inline-block;
  cursor: pointer;
  &:hover {
    color: #4dc7d2;
    border-bottom: 1px dashed #4dc7d2;
  }
}
.Reports {
  display: flex;
  flex: 1 1;
  overflow: hidden;
  .cancel-search {
    position: absolute;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }
  .copied-tt {
    position: absolute;
    background: #49b9c4;
    text-align: center;
    padding: 6px 10px;
    border-radius: 20px;
    top: 10px;
    z-index: 999;
    border: 1px solid lightgrey;
    font-size: 12px;
    color: white;
    font-weight: 700;
    line-height: 20px;
    right: 100px;
    width: 150px;
    opacity: 0.7;
  }
  .left-panel {
    display: flex;
    width: 400px;
    border-right: 1px solid #e8e8e8;
    flex-direction: column;
    background-color: white;
    overflow: hidden;
    &.show-details {
      .wide-inner-panel {
        margin-left: -400px;
      }
    }
    .wide-inner-panel-inner {
      transition: 0.3s ease-in-out;
      position: relative;
      height: 100%;
      width: 800px;
      display: flex;
      margin-left: 0;
      overflow: hidden;
      flex: 1 1;
      p {
        margin: 0;
      }
      .report-list-item.with-icons {
        padding: 11px 20px;
      }
      .list-row-icons {
        display: flex;
        .left-arrow {
          height: 30px;
          width: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          &:hover {
            .Icon {
              opacity: 0.7;
            }
          }
        }
      }
      &.showing-pdfs {
        margin-left: -400px;
      }
    }
    .wide-inner-panel {
      transition: 0.3s ease-in-out;
      position: relative;
      height: 100%;
      width: 800px;
      display: flex;
      margin-left: 0;
      .report-list, .report-details {
        width: 400px;
        overflow: auto;
        .GdInput {
          margin-top: 0;
          .Icon {
            top: 19px;
            &.right {
              right: 20px !important;
              left: auto !important;
            }
          }
          input {
            border: none;
            padding-left: 35px;
            border-radius: 0 !important;
            height: 50px;
            background-color: white !important;
          }
        }
      }
      .report-details {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        &.report-list {
          .details-section {
            padding: 0;
          }
        }
        .report-header {
          position: relative;
          height: 58px;
          border-bottom: 1px solid #e8e8e8;
          overflow: hidden;
          display: flex;
          align-items: center;
          padding-right: 15px;
          font-weight: 700;
          background-color: white;
          .left-arrow {
            width: 60px;
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            &.thin {
              width: 40px;
            }
            &:hover {
              .Icon {
                opacity: 0.7;
              }
            }
          }
          p {
            font-size: 15px;
            margin: 0;
          }
        }
        .list-section {
          position: relative;
          display: flex;
          flex-direction: column;
          flex: 1 1;
          overflow: auto;
          .details-section {
            padding: 0;
          }
        }
        .details-section {
          padding: 30px;
          overflow: auto;
          display: flex;
          flex-direction: column;
          border-bottom: 1px solid #e8e8e8;
          position: relative;
          &.no-padding {
            padding: 0;
          }
          .details-row {
            margin-bottom: 5px;
            height: 40px;
            display: flex;
            align-items: center;
            p {
              margin: 0;
              font-size: 12px;
              margin-left: 10px;
              line-height: 20px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .details-row-icon {
              position: relative;
              width: 50px;
              min-width: 50px;
              height: 40px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }
    .report-list-item {
      position: relative;
      padding: 18px 20px;
      border-bottom: 1px solid #e8e8e8;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        font-size: 13px;
      }
      &:hover, &.selected {
        .left-arrow {
          opacity: 1;
        }
      }
      .left-arrow {
        transition: 0.2s all ease-in;
        opacity: 0;
      }
      &.selected {
        background-color: #49b9c4;
        p {
          //color: white;
          font-weight: 700;
        }
        &:hover {
          background-color: #59c2cd;
        }
      }
      &.pdf {
        font-size: 13px;
        p {
          margin: 0;
        }
      }
      .name {
        margin-top: 0;
        margin-bottom: 8px;
        font-weight: 700;
      }
      .state {
        font-size: 11px;
        margin-bottom: 0;
        span {
          color: grey
        }
      }
      &:hover {
        outline: 0;
        background-color: #e7f9ff;
      }
    }
  }
  .pdf-view {
    position: relative;
    display: flex;
    flex: 1 1;
    justify-content: center;
    padding-top: 10px;
    overflow: auto;
    &:hover {
      .page-controls {
        opacity: 1;
      }
    }
    .page-controls {
      transition: 0.3s all ease-in;
      position: fixed;
      bottom: 30px;
      right: 50px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      background: rgba(255,255,255,0.95);
      border-radius: 5px;
      overflow: hidden;
      padding: 8px;
      opacity: 0;
      &.close {
        top: 77px;
        height: 30px;
        right: 22px;
      }
      p {
        font-size: 12px;
        color: grey;
        margin: 0;
      }
      .control-icon {
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &:hover {
          opacity: 0.7;
        }
        &.disabled {
          pointer-events: none;
          opacity: 0.3;
        }
      }
    }
    .no-pdf {
      display: flex;
      width: 100%;
      height: 100%;
      flex: 1 1;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      p {
        text-align: center;
      }
      .no-pdf-title {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 30px;
      }
      .no-pdf-sub-title {
        color: grey;
      }
    }
  }
}