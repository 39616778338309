div[role=presentation] {
	display: flex;
	flex: 1 1;
	width: 100%;
	flex-direction: row-reverse;
}

.side-drawer {
	position: relative;
	width: 470px;
	min-width: 250px;
	max-width: 1000px;
	flex-grow: 0;
	flex-shrink: 0;
	overflow: hidden;
	background-color: white;
	z-index: 2;
	box-shadow: 2px 0px 8px 0px rgba(0, 0, 0, 0.26);
	//border-left: 1px solid #e8e8e8;
	display: block;
	//display: flex;
	//flex-direction: column;
	.modal-body-wrapper {
		position: relative;
		top: 0;
		left: 0;
		height: 100%;
		//display: flex;
		//flex-direction: column;
	}
	//.CardBody {
	//	max-width: none !important;
	//	min-width: 100% !important;
	//	width: 100%;
	//}
	.Card, .modal-body, .modal-body-wrapper, .CardBody, .modal-footer, .CardHeader, .modal-body-wrapper .modal-body .Card .Tabs, .editableListsWrapper{
		max-width: none !important;
		min-width: 100% !important;
		width: 100%;
	}
}

.modal-body-wrapper {
	display: flex;
	justify-content: center;
	padding: 100px 0 50px 0;
	background-color: transparent !important;
	outline: none !important;
	flex: 0 !important;
	width: auto;
	&.drawer {
		border-left: 1px solid #e8e8e8;
		border-radius: 0;
		width: auto;
	}
	.close-butt {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		flex-direction: row-reverse;
		.Icon {
			justify-content: center;
			align-items: center;
			display: flex !important;
		}
	}
	.CardHeader, .CardBody, .modal-footer, .Tabs {
		box-sizing: border-box;
	}
	.modal-body {
		justify-content: center;
		display: flex;
		flex: 1 1;
		.GdInput input, .GdInput .MuiSelect-select, .GdInput textarea {
			background-color: transparent;
		}
		.Card {
			display: flex;
			flex: 1 1;
			flex-direction: column;
			align-items: end;
			.modal-footer {
				position: relative;
				display: flex;
				background: white;
				width: 100%;
				max-width: 600px;
				min-width: 600px;
				border-radius: 0 0 5px 5px;
				flex-direction: row-reverse;
				padding: 20px 30px !important;
				&.drawer {
					border-radius: 0;
				}
				&.with-top-border {
					padding: 10px 20px;
					border-top: 1px solid lightgrey;
				}
			}

			&.almostFullScreen {
				.CardHeader, .CardBody, .modal-footer {
					max-width: 90vw !important;
					min-width: 90vw !important;
				}

				.Tabs {
					max-width: 90vw !important;
					min-width: 90vw !important;
				}
			}

			&.wide {
				.CardHeader, .CardBody, .modal-footer {
					max-width: 800px !important;
					min-width: 800px !important;
				}

				.Tabs {
					max-width: 800px !important;
					min-width: 800px !important;
				}
			}

			.CardHeader {
				justify-content: space-between;
				display: flex;
				width: 100%;
				max-width: 600px;
				min-width: 600px;
				border-radius: 5px 5px 0 0;
				background-color: white;
				padding: 20px 30px !important;
				&.drawer {
					border-radius: 0 0 0 0 !important;
				}
				.Icon {
					float: right;
				}
			}

			.Tabs {
				width: 100%;
				max-width: 600px !important;
				min-width: 600px !important;
				background-color: white;
				.TabRow {
					//border-top: 1px solid #e8e8e8;
				}
				.TabRow {
					padding: 0 10px;
				}
			}

			.editableListsWrapper.open {
				width: 100%;
				max-width: 940px;
				background-color: white;
				min-width: 0;
			}
			.CardBody {
				display: flex;
				max-width: 600px;
				min-width: 600px;
				background-color: white;
				overflow: auto;
				padding: 20px 30px !important;
				flex-direction: column;
				&.no-padding {
					padding: 0 !important;
				}
			}
		}
	}
}
.no-padding {
	.CardBody {
		padding: 0 !important;
	}
}
