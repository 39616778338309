.light {
  .theme-palette.dark {
    display: none;
  }
}

.dark {
  .theme-palette.light {
    display: none;
  }
}

.theme-picker {
  position: relative;
  overflow: hidden;
  min-width: 300px;
  max-width: 300px;
  border-left: 1px solid;
  display: flex;
  flex-direction: column;

  .upper-section {
    display: flex;
    flex-direction: column;
  }

  .theme-previews {
    display: flex;
    padding-top: 15px;
    padding-bottom: 5px;
    border-bottom: 1px solid;
  }

  .theme-title {
    font-weight: 600;
    padding: 6px;
    margin-bottom: 10px;
    font-size: 12px;
  }

  .theme-palette {
    padding: 10px;

    &.light {
      background-color: #ffffff;

      .theme-title, p {
        color: #575757 !important;
      }

      .colour-preview {
        border: 1px solid #e8e8e8;
      }
    }

    &.dark {
      background-color: #1c232a;
      color: #e1e1e1 !important;

      .theme-title, p {
        color: #e1e1e1 !important;
      }

      .colour-preview {
        border: 1px solid #424242;
      }
    }
  }

  .palette-colours-wrapper {
    position: relative;
    //display: flex;
    //flex: 1 1;
    overflow: auto;
  }

  .palette-colours {
    //display: flex;
    //flex-direction: column;
    //flex: 1 1;
    overflow: hidden;
    position: relative;
  }

  .palette-colour {
    padding-bottom: 7px;

    p {
      font-size: 12px;
      padding: 5px;
      margin: 0;
    }

    .colour-preview {
      width: 100%;
      height: 22px;
      border-radius: 5px;
      margin-right: 10px;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &.on-mobile {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    height: 400px;
    min-height: 400px;
    max-height: 400px;

    .header-bar-theme {
      border-top: 1px solid;
    }
  }

  .swiper-button-next {
    width: 34px !important;

    &::after {
      top: 22px !important;
      //left: -10px !important;
    }
  }

  .swiper-button-prev {
    width: 34px !important;

    &::after {
      top: 22px !important;
      left: 10px !important;
    }
  }

  .header-bar-theme {
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 17px;
    min-height: 21px;
    max-height: 21px;
    border-bottom: 1px solid;
    //flex: 1 1;
    p {
      margin: 0;
      font-size: 15px;
      font-weight: 500;
    }
  }

  .theme-preview {
    width: 170px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    position: relative;

    &:hover {
      opacity: 0.7;
    }

    .preview-window {
      overflow: hidden;
      width: 150px;
      height: 100px;
      border: 1px solid;
      display: flex;
    }

    .preview-header {
      border-bottom: 1px solid;
      width: 100%;
      height: 15px;
    }

    .preview-left-nav {
      border-right: 1px solid;
      min-width: 30px;
      height: 100%;
    }

    .preview-main {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .preview-label {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 10px;
      width: 170px;
      position: relative;
      overflow: hidden;
      box-sizing: border-box;

      p {
        margin: 0;
        font-size: 12px;
      }
    }

    .preview-widget-wrapper {
      display: flex;
      width: 118px;
      height: 100%;
      position: relative;
      flex-wrap: wrap;
      overflow: hidden;
    }

    .preview-widget {
      border: 1px solid;
      width: 47px;
      margin-left: 7px;
      margin-top: 7px;
      position: relative;
      height: 39px;
      border-radius: 5px;
    }

    .preview-dot {
      background-color: rgb(73, 185, 196);
      width: 20px;
      height: 7px;
      border-radius: 10px;
      margin: 3px;
    }
  }

  .body-theme {
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    //padding: 15px 10px;
    //display: flex;
    flex-direction: column;
    padding-top: 15px;
    //flex: 1 1;

  }
}