@import "../../styles/theme";

.no-padding {
  padding: 0
}

.no-notifications {
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Asap", sans-serif;
  font-size: 14px;
  color: #919090;
}
.filter-menu {
  font-size: 13px;
  font-family: "Asap", "sans-serif" !important;
}
.filter-menu-container {
  .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input {
    height: 20px;
    //margin-bottom: 5px;
    line-height: 20px;
    font-size: 11px;
  }

  .MuiFormControl-root.GdInput.Dropdown label {
    display: none;
  }
}

#notifications-popover {
  .filterIcon {
    margin-right: 20px;
    font-size: 12px;
    display: flex;
    font-weight: 500;
    align-items: center;
    .Icon {
      margin-right: 5px;
      margin-top: -2px;
    }
  }
  .MuiList-padding {
    padding-bottom: 0 !important;
  }

  .list-items-wrapper {
    .list-row:nth-last-child(1) {
      border-bottom: none !important;
    }
  }


  .MuiPaper-root {
    min-width: 500px;
    max-width: 500px;
    width: 500px;
    transform: translate(80px, 30px) !important;
  }

  .MuiMenu-list {
    border: 1px solid lightgray;
    border-radius: 5px !important;
  }

  .list-container-popover {
    min-width: 500px;
    max-height: 300px;
  }

  .footer {
    bottom: 0;
    //justify-content: center;
    align-items: center;
    display: flex;
    //padding-top: 13px;
    //padding-bottom: 7px;
    padding: 10px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top: 1px solid lightgray;
    .MuiIconButton-root {
      padding: 0;
      margin-right: 5px;
    }
    .MuiFormControlLabel-root {
      margin: 0 !important;
    }

    span.history-button {
      font-family: "Asap", sans-serif;
      cursor: pointer;
      font-weight: 500;
      text-align: center;
      color: #49b9c4;
      padding-right: 5px;
    }

    .icon {
      padding-left: 2px;
      padding-bottom: 2px;
    }
  }

}

.notifications-title {
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid $gd-border-grey;
  font-weight: bold;
  color: $gd-dark-grey;
  font-family: "Asap", sans-serif;
  display: flex;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 15px;
}

.notifications-name {
  overflow: hidden;
  text-overflow: ellipsis;
  color: $gd-dark-grey;
  font-size: 12px;
  margin: 0;
  font-weight: bold;
}

.notifications-subtitle {
  overflow: hidden;
  text-overflow: ellipsis;
  color: $gd-grey;
  font-size: 12px;
  font-weight: normal;
}

.notifications-description {
  overflow: hidden;
  text-overflow: ellipsis;
  p {
    color: $gd-grey;
    font-size: 12px;
    text-transform: none;
    margin: 0;
  }
}

.notifications-drawer {

  .details {
    overflow: hidden;
    width: 100%;

    .details-inner {
      overflow: hidden;

      &.not-scrolling {
        overflow: hidden;
        display: flex;
        flex: 1 1;
        width: 100%;

      }
    }
  }

  .notifications-description {
    display: inline;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    color: $gd-dark-grey;

    p {
      font-size: 12px;
      margin: 0;
    }
  }

}