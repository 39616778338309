.light .gaia .modal-body-wrapper .modal-body .Card .CardHeader {
  background-color: aliceblue !important;
}

.no-chats-yet {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  .row {
    align-items: center;
    flex: 0;
  }
}

.dark .gaia .messages {
  &::after {
    background: linear-gradient(to bottom, rgba(28, 35, 42, 0) 0%, #1c232a 100%);
  }
}

.dark .gaia .message {
  &.assistant {
    background-color: #48525b;
    color: white;
    &::before {
      border-right-color: #48525b;
    }
  }
  &.user {
    background-color: #49b9c4;
    color: white;
    &::before {
      border-left-color: #49b9c4;
    }
  }
}

/* ThinkingAnimation.css */
.thinking-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 30px;
  margin: 0 auto;
  padding: 10px 30px;
}

.thinking-dot {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #49b9c4;
  animation: thinking 1s infinite ease-in-out;
}

.thinking-dot:nth-child(1) {
  animation-delay: 0s;
}

.thinking-dot:nth-child(2) {
  animation-delay: 0.2s;
}

.thinking-dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes thinking {
  0%, 100% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.5;
  }
}


.gaia {

  .ellipsis-wrapper {
    position: relative;
    overflow: hidden;
  }

  .active-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
  }
  .conversations {
    display: flex;
    flex: 1 1;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 50px;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
      pointer-events: none;
    }
  }

  .gaia-title {
    margin-top: 5px;
    font-size: 15px;
  }

  .gaia-description {
    font-size: 13px;
    line-height: 18px;
    font-weight: 300;
  }

  img.gaia-logo {
    height: 50px;
    width: auto;
  }

  .message-cont {
    display: flex;
    flex-direction: column;
    margin: 5px;
    max-width: 70%;
    &.user {
      align-items: end;
    }
  }

  .message-timestamp {
    font-size: 10px;
    color: grey;
    margin-top: 3px;
  }

  .send-button {
    position: absolute;
    right: 22px;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    top: 19px;
    &.no-pointer {
      cursor: default;
      opacity: 0.5 !important;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  .back-button {
    font-family: "Asap", "sans-serif";
    font-size: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }

    .Icon {
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      margin-right: 5px;
    }
  }

  .GdInput input, .GdInput .MuiSelect-select, .GdInput textarea {
    border-radius: 50px !important;
    padding-right: 40px;
  }

  .message-box {
    position: relative;
    padding: 20px;
  }

  .messages {
    display: flex;
    overflow: auto;
    padding: 10px;
    flex-direction: column-reverse;
    flex: 1 1;

    &::after {
      content: "";
      position: absolute;
      bottom: 80px;
      left: 0;
      right: 0;
      height: 50px;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
      pointer-events: none;
    }
  }


  .CardBody {
    display: flex;
    flex: 1 1;
    overflow: hidden;
  }

  .message-box {
    label {
      display: none;
    }

    .GdInput {
      margin: 0;
      padding: 0;
    }

    flex: 0;
    align-items: center;
  }

  .convo-title {
    margin: 0;
    font-weight: 600;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    width: 100%;
    padding-right: 30px;
  }

  .last-updated {
    margin: 0;
    color: grey;
    font-size: 12px;
  }

  .conversation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e8e8e8;
    height: 60px;
    padding: 0 20px;
    cursor: pointer;
    min-height: 60px;
    position: relative;
    overflow: hidden;
    .column {
      position: relative;
      overflow: hidden;
    }
    &:hover {
      background: #f6f6f6;
    }
  }

  .message {
    padding: 8px 14px;
    //white-space: pre-wrap;
    font-size: 13px;
    position: relative;
    overflow: hidden;
    img {
      max-width: 100%;
      border-radius: 10px;
    }
    h1, h2, h3, h4, h5, h6 {
      margin: 10px 0;
      font-size: 14px;
      line-height: 15px;
    }
    p, ol {
      font-size: 13px;
      //margin: 0 !important;
    }

    &.user {
      background-color: #d8f5ff;
      border-radius: 14px 14px 0 14px;

      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        right: -7px;
        width: 0;
        height: 0;
        border: 10px solid transparent;
        border-left-color: #d8f5ff;
        border-right: 0;
        border-bottom: 0;
        margin-bottom: 0;
      }
    }

    &.assistant {
      background-color: #f0f0f0;
      border-radius: 14px 14px 14px 0;

      &::before {
        content: "";
        position: absolute;
        bottom: 0; /* Align with the bottom */
        left: -7px; /* Position to the left to match the tail */
        width: 0;
        height: 0;
        border: 10px solid transparent;
        border-right-color: #f0f0f0;
        border-left: 0;
        border-bottom: 0;
        margin-bottom: 0;
      }
    }
  }

  //}
  //}
}