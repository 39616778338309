@import url("https://fonts.googleapis.com/css?family=Asap:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i");
@import url("https://fonts.googleapis.com/css?family=Source+Code+Pro:400,700,900");
@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed:400");
.MenuWrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0; }
  .MenuWrapper.disabled {
    pointer-events: none; }

#MenuShowContainer {
  position: relative; }
  #MenuShowContainer.disabled {
    pointer-events: none !important; }
    #MenuShowContainer.disabled .Icon, #MenuShowContainer.disabled .state-select-view, #MenuShowContainer.disabled .menu-span, #MenuShowContainer.disabled div {
      pointer-events: none !important; }
    #MenuShowContainer.disabled .state-select-view {
      background-color: transparent !important; }

.Menu {
  transition: 0.2s opacity ease-out;
  z-index: 1;
  position: fixed;
  background-color: white;
  border-radius: 5px;
  font-family: "Asap", sans-serif;
  min-width: 200px;
  overflow: hidden;
  box-shadow: none;
  border: 1px solid transparent;
  opacity: 0;
  max-height: 0; }
  .Menu.showing {
    opacity: 1;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.05);
    border: 1px solid #e8e8e8;
    max-height: 600px; }
  .Menu.disabled {
    pointer-events: none; }
  .Menu .MenuHeader {
    padding: 15px 30px 15px 20px;
    background-color: #f1f1f1; }
    .Menu .MenuHeader p {
      margin: 0; }
    .Menu .MenuHeader .title {
      color: #595959;
      font-weight: 700;
      margin-bottom: 5px !important;
      font-size: 12px; }
    .Menu .MenuHeader .subtitle {
      font-size: 11px;
      color: grey; }
  .Menu .MenuItem {
    display: flex;
    align-items: center;
    padding: 15px 16px 15px 20px;
    cursor: pointer; }
    .Menu .MenuItem.selected {
      background-color: #f1f1f1; }
    .Menu .MenuItem:hover {
      background-color: #f1f1f1; }
    .Menu .MenuItem p {
      font-size: 10px;
      margin: 0 17px;
      color: #595959; }
