@import "../../styles/theme";

.no-padding {
  padding: 0 !important;
}

.link.in-drawer-header {
  font-size: 14px;
  text-overflow: ellipsis;
  cursor: default;
  color: grey !important;
  border: none !important;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  .site-filter-title {
    display: flex;
  }
}

#disconnected-popover {

  .MuiPaper-root {
    transform: translate(50px, 30px) !important;
    //max-width: 492px;
    display: flex;
  }

  .MuiMenu-list {
    border: 1px solid lightgray;
    border-radius: 5px !important;
    display: flex;
    overflow: hidden;
  }

  .list-container-popover {
    min-width: 500px;
    max-height: 300px;
  }

  .footer {
    bottom: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    padding-top: 13px;
    padding-bottom: 7px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top: 1px solid lightgray;

    span {
      font-family: "Asap", sans-serif;
      cursor: pointer;
      font-weight: 500;
      text-align: center;
      color: #49b9c4;
    }

    .icon {
      padding-left: 2px;
      padding-bottom: 2px;
    }
  }

}

.disconnected-title {
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid $gd-border-grey;
  font-weight: bold;
  color: $gd-dark-grey;
  font-family: "Asap", sans-serif;
  display: flex;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 15px;
}

.disconnected-name {
  color: $gd-dark-grey;
  font-size: 12px;
  margin: 0 !important;
  font-weight: bold;
}

.disconnected-subtitle {
  color: $gd-grey;
  font-size: 11px;
  font-weight: normal;
}

.disconnected-description {
  color: $gd-grey;
  font-size: 12px;
}

.disconnected-date {
  font-size: 11px;
  color: grey;
  margin-top: 2px;
}

.disconnected-drawer {
  height: 100%;
  .disconnected-description {
    display: inline;
    white-space: pre-wrap;
    overflow-wrap: break-word;

    p {
      color: $gd-grey;
      font-size: 12px;
      margin: 0;
    }

  }

}