.splash-screen-sso {
  background-color: white;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  img {
    z-index: 9999;
    width: 130px;
    height: 130px;
  }
}

.splash-screen-sso .splash-logo {
  width: 150px;
  z-index: 1;
}

.ouro {
  position: absolute;
  display: inline-block;
  height: 180px;
  width: 180px;
  border-radius: 50%;
  background: none repeat scroll 0 0 #ffffff;
  overflow: hidden;
  animation-name: spin;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, .1) inset, 0 0 25px rgba(0, 0, 255, 0.075); */
}

.ouro:after {
  content: "";
  position: relative;
  top: 6px;
  left: 6px;
  display: block;
  height: 168px;
  width: 168px;
  background: none repeat scroll 0 0 #ffffff;
  border-radius: 50%;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, .1); */
}

.ouro > span {
  position: absolute;
  height: 100%;
  width: 50%;
  overflow: hidden;
}

.splash-screen-sso .left {
  left: 0;
}

.splash-screen-sso .right {
  left: 50%;
}

.anim {
  position: absolute;
  left: 100%;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 999px;
  background: none repeat scroll 0 0 #40BEBF;
  opacity: 0.8;
  -webkit-animation: ui-spinner-rotate-left 3s infinite;
  animation: ui-spinner-rotate-left 3s infinite;
  -webkit-transform-origin: 0 50% 0;
  transform-origin: 0 50% 0;
}

.left .anim {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.right .anim {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  left: -100%;
  -webkit-transform-origin: 100% 50% 0;
  transform-origin: 100% 50% 0;
}

.ouro3 .anim {
  -webkit-animation-delay: 0s;
  -webkit-animation-duration: 2s;
  -webkit-animation-timing-function: linear;
  animation-delay: 0s;
  animation-duration: 2s;
  animation-timing-function: linear;
}

.ouro3 .right .anim {
  -webkit-animation-name: ui-spinner-rotate-right;
  -webkit-animation-delay: 0;
  -webkit-animation-delay: 1s;
  animation-name: ui-spinner-rotate-right;
  animation-delay: 0;
  animation-delay: 1s;
}

.splash-screen-sso p {
  position: absolute;
  margin-top: 250px;
  font-family: 'Poppins', sans-serif;
  color: grey;
  font-weight: 300;
}

@keyframes ui-spinner-rotate-right {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(180deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes whole-loader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes ui-spinner-rotate-left {
  0% {
    transform: rotate(0deg)
  }

  25% {
    transform: rotate(0deg)
  }

  50% {
    transform: rotate(180deg)
  }

  75% {
    transform: rotate(180deg)
  }

  100% {
    transform: rotate(360deg)
  }
}

@-webkit-keyframes ui-spinner-rotate-right {
  0% {
    -webkit-transform: rotate(0deg)
  }

  25% {
    -webkit-transform: rotate(180deg)
  }

  50% {
    -webkit-transform: rotate(180deg)
  }

  75% {
    -webkit-transform: rotate(360deg)
  }

  100% {
    -webkit-transform: rotate(360deg)
  }
}

@-webkit-keyframes ui-spinner-rotate-left {
  0% {
    -webkit-transform: rotate(0deg)
  }

  25% {
    -webkit-transform: rotate(0deg)
  }

  50% {
    -webkit-transform: rotate(180deg)
  }

  75% {
    -webkit-transform: rotate(180deg)
  }

  100% {
    -webkit-transform: rotate(360deg)
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}