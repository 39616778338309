@import "../styles/theme";

.loginPage {
  font-family: $primary-font;
  display: flex;
  height: 100%;
  min-width: 800px;
  overflow: hidden;
  textarea {
    min-height: 100px;
  }
  .animated-logo {
    position: absolute;
    width: 18%;
    min-width: 400px;
    max-width: 600px;
    opacity: 1;
    height: auto;
    z-index: 1;
  }

  .Pattern {
    position: relative;
    width: 100%;
    height: 100%;
  }
  &.organisation {
    .GdButton {
      min-width: 100px;
      width: auto;
    }
  }
  .GdButton {
    width: 100%;
  }

  .header {
    font-size: 16px;
    color: #a0a0a0;
    text-align: center
  }

  .subHeader {
    color: #2a363f;
    font-size: 40px;
    text-align: center;
    margin: 0;
    margin-bottom: 50px;
    font-weight: 700
  }

  form {
    width: 400px;
  }
}

