@import "../../styles/theme";

.alerts-page {
	.alerts-list {
		overflow: auto;
		.incident-site {
			margin: 20px;
			display: flex;
			flex: 1;
			flex-direction: column;
			border: 1px solid $gd-border-grey;
			border-collapse: collapse;

			.title {
				color: $gd-off-black;
				display: flex;
				font-weight: 500;
				font-size: 14px;
				flex-direction: row;
				padding: 10px;
				background-color: $gd-bg-grey;
			}

			.incident {
				cursor: pointer;

				&:hover {
					background-color: $gd-blue-hover-mild;
				}

				font-size: 13px;
				display: flex;
				flex-direction: row;
				border-bottom: 1px solid $gd-border-grey;

				&:last-child {
					border-bottom: none;
				}

				padding: 20px;
				color: $gd-off-black;
				border-collapse: collapse;

				.item {
					flex: 1;
				}

				.disconnected {
					color: $gd-red;
				}
			}
		}
	}
}
