.device-category {
  .widget-board-header .place-in-header {
    margin-bottom: -40px;
    margin-top: 5px;
  }
  .GdButton.fill {
    height: 27px;
    //width: 40px;
    padding: 0 20px;
    line-height: 27px;
    font-size: 11px;
    letter-spacing: 0;
    margin-right: 17px;
    z-index: 10;
  }
}