@import "../styles/theme";

#mobile_bottom_nav {
	height: 55px;
	min-height: 55px;
	max-height: 55px;
	position: relative;
	display: flex;
	flex-direction: row;
	border-top: 1px solid $gd-border-grey;
	background-color: white;
	align-items: center;
	padding: 0 20px;
	padding-right: 10px;
	.button {
		flex: 1;
		height: 55px;
		.Icon {
			left: 50%;
			margin-left: -7.5px;
			margin-top: 10px;
		}
		.label {
			font-size: 12px;
			color: $gd-grey;
			text-align: center;
		}
	}
}
