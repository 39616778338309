.mega-prod-wrapper {
  display: flex;
  flex: 1 1;
  flex-direction: column;

  &.disabled {
    opacity: 0.7;
    pointer-events: none;
  }

  p.notice {
    padding: 5px 20px;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 5px;

    a.link {
      border-bottom: none;
    }

    span {
      font-weight: 700;
    }
  }
}

.page.prdks {
  background-color: white;
  overflow: auto;
  display: flex;
  align-items: center;
  padding: 30px;
  .expires {
    margin: 0;
    color: #a5a5a5;
    font-size: 12px !important;
  }

  .default-icon {
    margin: 0;
    margin-right: 50px;
    background-color: #f1f1f1;
    padding: 5px 10px;
    color: #868686;
    font-size: 11px !important;
    border-radius: 5px;
    margin-top: 3px;
    &.no-margin {
      margin-right: 0 !important;
    }
    &.make-default {
      background-color: white;
      cursor: pointer;
      padding-right: 0;
      &:hover {
        opacity: 0.7;
      }
    }
  }
  &.checkout {
    background-color: whitesmoke;
  }
  .products-inner-page {
    &.checkouts {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 60px;
    }
  }

  .divider {
    margin: 10px 0;
  }
}

.sub-title-cat {
  margin-top: -10px;
  padding-left: 35px;
  margin-bottom: 20px;
}

.category-title {
  font-size: 20px;
  font-weight: 700;
  padding-left: 10px;
  font-family: Poppins, sans-serif;
  margin-bottom: 15px;
  cursor: pointer;
  display: inline-block;

  span.chevron {
    margin-right: 10px;
    top: -3px;
    position: relative;
  }

  &:hover {
    opacity: 0.7;
  }
}

@media (max-width: 1000px) {
  .products .product {
    width: calc(50% - 82px) !important;
  }
}

@media (min-width: 1000px) and (max-width: 1300px) {
  .products .product {
    width: calc(33.333% - 82px) !important;
  }
}

.checkout-bar {
  display: flex;
  padding: 10px;
  flex-direction: row-reverse;
  background-color: white;
  border-top: 1px solid #ececec;
  justify-content: space-between;
  &.errors {
    flex-direction: column;
    background-color: #ea6262;
    padding: 15px 20px;
    .row {
      align-items: center;
      margin: 5px;
    }
    p {
      font-size: 12px;
      color: white;
      font-weight: 700;
      margin: 5px 10px;
    }
  }
  &.totals {
    padding: 15px 20px;
    flex-direction: row;
    justify-content: start;
    //align-items: center;
    background-color: whitesmoke;
    p {
      font-weight: 700;
      margin: 0 20px;
      span {
        font-weight: 300;
        font-size: 11px;
        font-style: italic;
      }
    }
  }
  .GdButton {
    width: 100%;
  }
}

.products-wrapper {
  //transition: 0.3s max-height ease-in-out;
  max-height: 2000px;
  overflow: hidden;
  margin-bottom: 30px;
  &.closed {
    max-height: 52px;
  }
}

.add-remove-items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .Icon {
    padding: 0 10px;
    color: darkgrey;
  }
  .GdInput {
    margin: 0;
  }
  input {
    height: 30px;
    max-width: 50px;
    text-align: center;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type=number] {
    -moz-appearance:textfield; /* Firefox */
  }
}

.image {
  height: 100px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin-bottom: 20px;
}

.products {
  display: flex;
  //margin-bottom: 30px;
  flex-wrap: wrap;

  .product {
    width: calc(33.333% - 82px) !important;
    display: flex;
    flex-direction: column;
    padding: 30px;
    border: 1px solid #ececec;
    margin: 10px;

    .name {
      //text-align: center;
      font-weight: 600;
    }

    .price {
      font-size: 13px;

      span {
        font-style: italic;
        font-size: 10px;
      }
    }

    .description {
      font-size: 13px;
      line-height: 17px;
    }
  }
}