.row.tabs {
  .GdAutocomplete .MuiInputBase-root {
    padding: 5px !important;
    padding-right: 56px !important;
  }

}
.date-error {
  .rs-picker-toggle {
    border-color: #ff2d2d !important;
  }
}
.list-error {
  .editable-list {
    border: 1px solid #ff2d2d !important;
  }
}
.create-invoice {

  .logo-example {
    height: 60px;
    width: auto;
    margin: 15px;
    border: 1px solid lightgrey;
    padding: 9px;
    border-radius: 5px;
  }

  textarea {
    padding: 10px 15px !important;
  }

  .time-till {
    margin: -8px 10px;
    margin-bottom: 0;
    font-size: 11px;
    color: #737373;
  }
}