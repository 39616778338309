#decorated_page {
  &.partner-override {
    border: 5px solid orange;
    border-top: none !important;
    border-radius: 0 0 10px 10px;
  }
  .Crumb.clickable {
    color: #606060 !important;
  }
  .Tabs {
    width: 100%;
    color: #424242;
    .Tab {
      white-space: nowrap;
      //display: inline;
      box-sizing: border-box;
      padding-left: 15px;
      padding-right: 15px;
      //padding-top: 3px;
      //padding-bottom: 3px;
    }
  }
}

.page .list-wrapper:nth-child(1) {
  //padding: 0 10px;
}

.list-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
}

.tabbed-page, .page, .gd-list:first-child {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
}

.gd-list {
  .Icon.clickable {
    line-height: 40px;
    width: 30px;
    text-align: center;
    padding-top: 3px;
  }
  &:nth-child(1) {
    //padding: 0 10px;
  }
}

.list-items-wrapper {
  &.no-scroll {
    overflow: hidden;
  }
  overflow: auto;
  min-height: 50px;
}
