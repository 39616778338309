.appliances {
  .appliance {
    .icon-wrapper {
      min-width: 27px;
      display: flex;
      justify-content: end;
      align-items: center;
    }
    .number-wrapper {
      max-width: 100px;
      input {
        text-align: center;
      }
    }
    .GdInput {
      margin-top: 0;
      margin-right: 10px;
    }
  }
}