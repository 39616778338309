@import "theme";

.MuiMenuItem-root {
  .icon-container {
    margin-right: 10px;
  }

  svg {
    font-size: 11px !important;
  }
}

.modal-side-content {
  width: 500px;
  //margin: 0 10px;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  border-left: 1px solid lightgrey;
  background-color: white;
  overflow: hidden;
  position: relative;

  .CardHeader {
    border-radius: 0 !important;
  }

  .back-button-wrapper {
    display: flex;

    .Icon {
      position: relative;
      pointer-events: auto;
      width: 28px;
      align-items: center;
      justify-content: start;
      display: flex;
    }
  }

  .list-heading {
    height: 0 !important;
  }

  .no-items-text {
    margin-bottom: 0 !important;
  }

  .CardBody {
    padding: 0;
  }

}

.modal-body-wrapper .modal-body .Card {
  .modal-side-content {
    .CardHeader, .CardBody, .modal-footer, .Tabs {
      max-width: none !important;
      min-width: 0 !important;
      width: 100%;
      position: relative;
      box-sizing: border-box;
    }
  }
}

.rs-picker-menu {
  z-index: 999999 !important;
}

.or-divider {
  padding-left: 10px;
  margin: 14px 0;
  font-weight: 600;
}

.info-card {
  background-color: whitesmoke;
  border-radius: 10px;
  overflow: hidden;
  //transition: 0.2s opacity ease-in, 0.05s padding ease-in, 0.05s margin ease-in, 0.2s border ease-in;
  user-select: none;
  //opacity: 0;
  max-height: 0;
  padding: 0;
  margin: 0;
  border: none;
  pointer-events: none;
  .info-card-header {
    white-space: nowrap;
    display: flex;
    align-items: center;
    .info-card-subheading {
      white-space: nowrap;
      overflow: hidden;
      width: 320px;
      text-overflow: ellipsis;
      margin-left: 5px;
    }
  }
  &.has-below {
    border-radius: 10px 10px 0 0;
  }
  &.has-above {
    border-radius: 0 0 10px 10px;
    margin-top: -11px;
    max-height: 200px;
    overflow: auto;
  }
  .location-form {
    width: 100%;
  }
  &.showing {
    max-height: none;
    pointer-events: all;
    opacity: 1;
    padding: 20px;
    border: 1px solid #e8e8e8;
    margin-bottom: 10px;
  }


  .complete-icon-wrapper {
    transition: 0.2s width ease-in;
    width: 0;
    margin-right: 10px;
    overflow: hidden;
  }

  .complete-icon {
    transition: 0.2s background-color, opacity ease-in;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    font-size: 12px !important;
    color: white;
    border-radius: 100%;
    line-height: 24px;
    //opacity: 0;
  }

  &.completed, &.incomplete, &.error {
    .complete-icon-wrapper {
      width: 25px;
    }

    //.complete-icon {
    //  opacity: 1;
    //}
  }

  &.completed {
    .complete-icon {
      background-color: #49c570;
    }
  }

  &.incomplete {
    .complete-icon {
      background-color: orange;
    }
  }

  &.error.showing {
    border: 1px solid #e43536 !important;

    .complete-icon {
      background-color: #e43536;
    }
  }

  .info-body {
    position: relative;
    transition: 0.2s opacity ease-in;
  }

  .thin-button {
    .GdButton {
      height: 24px;
      line-height: 25px;
      font-size: 12px;
    }
  }

  .info-card-heading {
    align-items: start;
    font-weight: 600;
    font-family: Poppins, sans-serif;
    font-size: 18px;
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 10px;
    padding-bottom: 5px;
    .GdButton {
      height: 24px;
      line-height: 25px;
      font-size: 12px;
    }
  }

  .info-card-subheading {
    opacity: 0;
    transition: 0.2s opacity ease-in;
    color: grey;
    font-weight: 400;
    font-size: 14px;
  }

  &.closed.showing {
    max-height: 25px;
    padding: 20px;
    border: 1px solid #e8e8e8;
    margin-bottom: 10px;
    pointer-events: all;
    .info-card-subheading {
      opacity: 1;
    }
    .info-body {
      pointer-events: none;
      opacity: 0;
      display: none;
    }

    .info-card-heading {
      border-color: transparent !important;
    }
  }
}

.info-text {
  color: grey;
  font-style: italic;
  margin-top: 10px;
  padding: 0 10px;
  font-size: 12px;
}

.info-text {
  color: grey;
  font-style: italic;
  margin-top: 10px;
  padding: 0 10px;
}

.rs-calendar-header-error {
  color: #8e8e93 !important;
  background: transparent !important;
}

.rs-calendar-show-month-dropdown .rs-calendar-header-title-date.rs-calendar-header-error:focus, .rs-calendar-show-month-dropdown .rs-calendar-header-title-date.rs-calendar-header-error:hover:active {
  color: #2db8c1 !important;
}

.MuiAutocomplete-paper {
  width: 100% !important;
}

.no-items-container-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  justify-content: center;
  align-items: center;

  .no-items-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
      margin-bottom: 60px;
      font-size: 25px;
    }
  }
}

.row {
  display: flex;
  flex-direction: row;
  flex: 1;

  &.center {
    justify-content: center;
    align-items: center;
  }

  &.reverse {
    flex-direction: row-reverse;
  }

  &.space-between {
    justify-content: space-between;
  }
}

.GdButton {
  &.sm {
    width: 100px;
  }

  &.md {
    width: 200px;
  }

  &.lg {
    width: 300px
  }
}

.left {
  float: left;
}

.right {
  float: right;
}

.list-hover {
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
}

.page {
  overflow: auto;
  display: flex;

  .notice {
    padding: 20px;
    margin: 0;
    margin-bottom: 20px;
    color: grey;
    line-height: 20px;
    font-size: 12px;
    background-color: whitesmoke;

    &.mobile {
      flex-direction: column;
    }
  }

  &.grey {
    background-color: whitesmoke;
  }

  &.white {
    background-color: white;
  }

  &.not-flex {
    //padding: 30px 0;
    display: block;
    overflow: auto;
  }

  .divider {
    border-bottom: 1px dashed lightgrey;
    width: 100%;
    margin-top: 25px;
    margin-bottom: 5px;
    position: relative;
  }

  &.narrow {
    .row {
      width: 100%;
      //justify-content: center;
    }

    .detail-content {
      background-color: white;
      border-radius: 5px;
      margin-bottom: 10px;
      justify-content: center;
      align-content: center;
      display: flex;
      width: 100%;
      max-width: 600px;

      .Card {
        background-color: white;
        border-radius: 5px;
        padding: 10px;
        padding-top: 0;
      }

      &.two-thirds {
        max-width: 900px;
        background-color: whitesmoke;
      }

      p {
        font-size: 13px;
      }

      .Card {
        width: 100%;
      }
    }

    .column {
      align-items: center;

      &.top {
        margin-top: 30px;
      }
    }
  }

  &.medium {
    .detail-content {
      justify-content: center;
      align-content: center;
      display: flex;
      width: 100%;
      max-width: 900px;

      .Card {
        width: 100%;
      }
    }

    .column {
      align-items: center;
    }
  }
}

.MuiAutocomplete-paper {
  font-size: 13px !important;
  font-family: $primary-font !important;
}

.column {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.hor-center {
  justify-content: center;
}

.header-bar {
  display: flex;
  flex: 1;
  flex-direction: row-reverse;
  padding: 10px 20px;
}

.organisation-page {
  .header-bar {
    flex: none;
  }
}

.ver-center {
  align-items: center;
}
@import "theme";

.MuiMenuItem-root {
  .icon-container {
    margin-right: 10px;
  }

  svg {
    font-size: 11px !important;
  }
}

.modal-side-content {
  width: 500px;
  //margin: 0 10px;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  border-left: 1px solid lightgrey;
  background-color: white;
  overflow: hidden;
  position: relative;

  .CardHeader {
    border-radius: 0 !important;
  }

  .back-button-wrapper {
    display: flex;

    .Icon {
      position: relative;
      pointer-events: auto;
      width: 28px;
      align-items: center;
      justify-content: start;
      display: flex;
    }
  }

  .list-heading {
    height: 0 !important;
  }

  .no-items-text {
    margin-bottom: 0 !important;
  }

  .CardBody {
    padding: 0;
  }

}

.modal-body-wrapper .modal-body .Card {
  .modal-side-content {
    .CardHeader, .CardBody, .modal-footer, .Tabs {
      max-width: none !important;
      min-width: 0 !important;
      width: 100%;
      position: relative;
      box-sizing: border-box;
    }
  }
}

.rs-picker-menu {
  z-index: 999999 !important;
}

.or-divider {
  padding-left: 10px;
  margin: 14px 0;
  font-weight: 600;
}

.info-card {
  background-color: whitesmoke;
  border-radius: 10px;
  overflow: hidden;
  //transition: 0.2s opacity ease-in, 0.05s padding ease-in, 0.05s margin ease-in, 0.2s border ease-in;
  user-select: none;
  //opacity: 0;
  max-height: 0;
  padding: 0;
  margin: 0;
  border: none;
  pointer-events: none;
  .info-card-header {
    white-space: nowrap;
    display: flex;
    align-items: center;
    .info-card-subheading {
      white-space: nowrap;
      overflow: hidden;
      width: 320px;
      text-overflow: ellipsis;
      margin-left: 5px;
    }
  }
  &.has-below {
    border-radius: 10px 10px 0 0;
  }
  &.has-above {
    border-radius: 0 0 10px 10px;
    margin-top: -11px;
    max-height: 200px;
    overflow: auto;
  }
  .location-form {
    width: 100%;
  }
  &.showing {
    max-height: none;
    pointer-events: all;
    opacity: 1;
    padding: 20px;
    border: 1px solid #e8e8e8;
    margin-bottom: 10px;
  }


  .complete-icon-wrapper {
    transition: 0.2s width ease-in;
    width: 0;
    margin-right: 10px;
    overflow: hidden;
  }

  .complete-icon {
    transition: 0.2s background-color, opacity ease-in;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    font-size: 12px !important;
    color: white;
    border-radius: 100%;
    line-height: 24px;
    //opacity: 0;
  }

  &.completed, &.incomplete, &.error {
    .complete-icon-wrapper {
      width: 25px;
    }

    //.complete-icon {
    //  opacity: 1;
    //}
  }

  &.completed {
    .complete-icon {
      background-color: #49c570;
    }
  }

  &.incomplete {
    .complete-icon {
      background-color: orange;
    }
  }

  &.error.showing {
    border: 1px solid #e43536 !important;

    .complete-icon {
      background-color: #e43536;
    }
  }

  .info-body {
    position: relative;
    transition: 0.2s opacity ease-in;
  }

  .thin-button {
    .GdButton {
      height: 24px;
      line-height: 25px;
      font-size: 12px;
    }
  }

  .info-card-heading {
    align-items: start;
    font-weight: 600;
    font-family: Poppins, sans-serif;
    font-size: 18px;
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 10px;
    padding-bottom: 5px;
    .GdButton {
      height: 24px;
      line-height: 25px;
      font-size: 12px;
    }
  }

  .info-card-subheading {
    opacity: 0;
    transition: 0.2s opacity ease-in;
    color: grey;
    font-weight: 400;
    font-size: 14px;
  }

  &.closed.showing {
    max-height: 25px;
    padding: 20px;
    border: 1px solid #e8e8e8;
    margin-bottom: 10px;
    pointer-events: all;
    .info-card-subheading {
      opacity: 1;
    }
    .info-body {
      pointer-events: none;
      opacity: 0;
      display: none;
    }

    .info-card-heading {
      border-color: transparent !important;
    }
  }
}

.info-text {
  color: grey;
  font-style: italic;
  margin-top: 10px;
  padding: 0 10px;
  font-size: 12px;
}

.rs-calendar-header-error {
  color: #8e8e93 !important;
  background: transparent !important;
}

.rs-calendar-show-month-dropdown .rs-calendar-header-title-date.rs-calendar-header-error:focus, .rs-calendar-show-month-dropdown .rs-calendar-header-title-date.rs-calendar-header-error:hover:active {
  color: #2db8c1 !important;
}

.MuiAutocomplete-paper {
  width: 100% !important;
}

.no-items-container-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  justify-content: center;
  align-items: center;

  .no-items-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
      margin-bottom: 60px;
      font-size: 25px;
    }
  }
}

.row {
  display: flex;
  flex-direction: row;
  flex: 1;

  &.reverse {
    flex-direction: row-reverse;
  }

  &.space-between {
    justify-content: space-between;
  }
}

.GdButton {
  &.sm {
    width: 100px;
  }

  &.md {
    width: 200px;
  }

  &.lg {
    width: 300px
  }
}

.left {
  float: left;
}

.right {
  float: right;
}

.list-hover {
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
}

.page {
  overflow: auto;
  display: flex;

  .notice {
    padding: 20px;
    margin: 0;
    margin-bottom: 20px;
    color: grey;
    line-height: 20px;
    font-size: 12px;
    background-color: whitesmoke;
  }

  &.grey {
    background-color: whitesmoke;
  }

  &.white {
    background-color: white;
  }

  &.not-flex {
    //padding: 30px 0;
    display: block;
    overflow: auto;
  }

  .divider {
    border-bottom: 1px dashed lightgrey;
    width: 100%;
    margin-top: 25px;
    margin-bottom: 5px;
    position: relative;
  }

  &.narrow {
    .row {
      width: 100%;
      //justify-content: center;
    }

    .detail-content {
      background-color: white;
      border-radius: 5px;
      margin-bottom: 10px;
      justify-content: center;
      align-content: center;
      display: flex;
      width: 100%;
      max-width: 600px;

      .Card {
        background-color: white;
        border-radius: 5px;
        padding: 10px;
        padding-top: 0;
      }

      &.two-thirds {
        max-width: 900px;
        background-color: whitesmoke;
      }

      p {
        font-size: 13px;
      }

      .Card {
        width: 100%;
      }
    }

    .column {
      align-items: center;

      &.top {
        margin-top: 30px;
      }
    }
  }

  &.medium {
    .detail-content {
      justify-content: center;
      align-content: center;
      display: flex;
      width: 100%;
      max-width: 900px;

      .Card {
        width: 100%;
      }
    }

    .column {
      align-items: center;
    }
  }
}

.MuiAutocomplete-paper {
  font-size: 13px !important;
  font-family: $primary-font !important;
}

.column {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.hor-center {
  justify-content: center;
}

.header-bar {
  display: flex;
  flex: 1;
  flex-direction: row-reverse;
  padding: 10px 20px;
}

.organisation-page {
  .header-bar {
    flex: none;
  }
}

.ver-center {
  align-items: center;
}
