@import "../../styles/theme";

.template-card {
	cursor: pointer;
	margin-bottom: 15px;
	display: flex;
	align-items: center;
	border: 1px solid lightgrey;
	border-radius: 10px;
	padding: 30px;
		.left-section {
			width: 50px;
			height: 50px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 100%;
			border: 1px solid #e8e8e8;
			margin-right: 20px;
		}
		.right-section {
			h4 {
				margin-bottom: 0 !important;
				font-size: 18px;
				font-family: 'Poppins', sans-serif;
			}
			p {
				margin-top: 0;
				font-size: 13px;
				color: $gd-dark-grey;
			}

		}
}
