@import "../styles/theme";

.select-template {
  padding: 5px;
  overflow: auto;
}

.mini-wrapper {
  max-width: 90%;

  .rule-title {
    font-weight: bold;
    padding-bottom: 10px;
    padding-top: 10px;
  }
  .link-wrapper {
    display: inline-block;
    float: left;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.delete-button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 20px;
}

.rule-page-timeswitch {
  background-color: white;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .top-bar {
    width: 50%;
    border-bottom: 10px solid $gd-border-grey;
    display: flex;
    flex-direction: column;

    .GdButton {
      width: 150px;
      margin: 10px 20px 10px 20px;
    }
  }


  .timeswitch-view {
    margin-top: 20px;
    width: 50%;
    //min-width: 850px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .detail-card {
      //min-width: 850px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-bottom: 20px;
      border-bottom: 1px solid $gd-border-grey;

      .Icon {
        margin-right: 20px;
      }

      .right-content {
        display: flex;
        flex-direction: column;

        h1 {
          margin-bottom: 5px;
          font-weight: 600;
        }

        h2 {
          margin-top: 5px;
          font-size: 15px;
          font-weight: 500;
        }
      }
    }

    .timeswitch-preview {
      width: 100%;
      padding-bottom: 20px;

      .schedule-timeline {
        margin-top: 40px;
      }

      .schedule-table {
        margin-top: 40px;
      }

      border-bottom: 1px solid $gd-border-grey;
    }

    .timeswitch-actions {
      width: 100%;
      display: flex;
      flex-direction: column;

      p {
        color: $gd-grey;
      }

      .action {
        display: flex;
        flex-direction: row;

        h2 {
          font-size: 17px;
          font-weight: 600;
          margin-right: 40px;
        }

        p {
          color: $gd-off-black;
        }
      }
    }
  }

}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 9999;
}

.ReactModal__Content.ReactModal__Content--after-open {
  width: 95%;
  height: 95%;
  top: 2.5% !important;
  left: 2.5% !important;
  padding: 0 !important;
  border: none !important;
  display: flex !important;
  flex: 1 !important;
  border-radius: 10px !important;
}

.on-off-state {
  font-size: 20px;
  font-weight: 700;

  &.on {
    color: #48c46f;
  }

  &.off {
    color: #ff5b5b;
  }
}

.condition-row {
  display: flex;
  flex: 1;
  align-items: center;
  padding: 4px;

  &.assets {
    margin: 0 4px;
  }

  p {
    margin: 0 8px;
    white-space: nowrap;
  }

  &.assets .GdButton.gd-grey {
    background-color: transparent;
    color: #595959;
    position: relative;
    height: auto;
    border-radius: 10px;
    text-align: left;
    line-height: 25px;
    padding: 10px 20px;
    width: 100%;
    white-space: normal;
    max-height: 100px;
    overflow: auto;
    //border: none !important;
  }

  .GdInput {
    margin: 0 4px !important;
    //display: inline-block !important;
  }

  .Autocomplete .DummyInput {
    margin: 0;
    margin-right: 10px;
  }
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.editableListsWrapper.open {
  display: flex;
  flex: 1;
  overflow: hidden;
  position: relative;
}

.rule {
  //background-color: white;
  //display: flex;
  //flex: 1;
  //flex-direction: column;
  //position: relative;
  //overflow: hidden;
  //.CardBody {
  //	padding: 30px 0;
  //}

  .bottom-bar-inner {
    //max-width: 650px !important;
  }

  .timeswitch-preview {
    margin-bottom: 30px;
  }

  .schedule-timeline {
    margin: 30px 0;
    margin-bottom: 20px;
  }

  .delete-bar {
    top: 60px;
    display: flex;
    flex: 1 1;
    height: 80px;
    align-items: center;
    flex-direction: row-reverse;
    position: absolute;
    width: 100%;
    justify-content: space-between;
    z-index: 2;

    .GdButton {
      width: 120px;
      margin: 0px 20px;
    }
  }

  .top-bar {
    top: 0;
    flex: 1 1;
    display: flex;
    height: 80px;
    align-items: center;
    flex-direction: row-reverse;
    position: absolute;
    width: 100%;
    justify-content: space-between;
    z-index: 2;
    //background-color: red;
    background-color: rgba(255, 255, 255, 0.8);


    .Icon {
      margin-right: 30px;
    }

    .GdButton {
      width: 120px;
      margin: 20px;
    }
  }

  .detail-card {
    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: row;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid $gd-border-grey;


    .Icon {
      margin-right: 20px;
    }

    .right-content {
      display: flex;
      flex-direction: column;

      h1 {
        margin-bottom: 5px;
        font-weight: 600;
      }

      h2 {
        margin-top: 5px;
        font-size: 15px;
        font-weight: 500;
      }
    }
  }

  p.trig-by {
    line-height: 24px;
  }

  p.label {
    color: $gd-grey;
    margin-top: 20px;
  }

  .alert-preview {
    width: 100%;
    padding-bottom: 20px;
    border-bottom: 1px solid $gd-border-grey;
  }

  .alert-log {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-height: 246px;
    overflow: auto;
    border-bottom: 1px dashed #ececec;
  }

  .alert-actions {
    border-bottom: 1px solid $gd-border-grey;
    width: 100%;
    display: flex;
    flex-direction: column;

    .action {
      display: flex;
      flex-direction: row;

      h2 {
        font-size: 17px;
        font-weight: 600;
        margin-right: 40px;
      }

      p {
        color: $gd-off-black;

        span {
          display: block;
          margin-bottom: 10px;
        }
      }
    }
  }

}

.advanced-wrapper {
  display: flex;
  flex: 1 1;
  width: 100%;
  background-color: white;
  height: 100%;

  .new-wrapper {
    display: flex;
    flex: 1 1;
    width: 100%;

    .GdButton {
      margin: 10px;
      width: 100%;
    }
  }

  .top-bar {
    height: 60px;
    border-bottom: 1px solid #ececec;
  }

  .condition-row {
    margin: 2px 0;

    &.assets {
      margin: 2px 6px;

      &.recip {
        margin: 2px 3px;
      }
    }
  }

  .toggle-wrapper {
    margin-bottom: 20px;
  }

  h1 {
    font-family: 'Poppins', sans-serif;
    font-size: 25px;
  }

  .top-bar {
    background-color: white;
  }

  position: relative;

  .right-panel {
    display: flex;
    flex-direction: column;
    width: 300px;
    //padding-top: 60px;
    //border-left: 1px solid $gd-border-grey;
    border-right: 1px solid #ececec;
    //border-top: 1px solid $gd-border-grey;
    overflow: auto;

    .actions-list {
      display: flex;
      flex-direction: column;

      .action {
        padding: 10px;
        height: 40px;
        border-bottom: 1px solid $gd-border-grey;
        cursor: pointer;
        display: flex;
        padding-left: 15px;
        align-items: center;
        flex-direction: row;

        &:hover {
          background-color: #f6f6f6;
        }

        &.main-rule {
          padding-left: 20px;

          .label {
            font-size: 14px;
          }

          //border-top: 1px solid #ececec;
        }

        &.active {
          background-color: $gd-blue-background;
          //background-color: #f7f7f7;
        }

        .trash {
          width: 30px;
        }

        .color {
          width: 10px;
          height: 10px;
          border-radius: 10px;
          margin-right: 15px;
        }

        .label {
          margin: 0;
          color: #4b4b4b;
          font-size: 12px;
          font-weight: 600;
        }

        .GdButton {
          margin-left: 20px;
        }
      }
    }
  }
}

.inner-rule {
  display: flex;
  overflow: visible;

  .editableListsWrapper {
    border: 1px solid #e5e5ea;
    border-radius: 5px
  }

  &.no-scroll {
    overflow: hidden;
  }

  &.padded {
    padding: 10px 20px;
  }

  &.ad {
    flex-direction: column;
    flex: 1 1;
  }

  .details {
    overflow: hidden;
    width: 100%;

    .details-inner {
      overflow: hidden;

      .inner-header {
        padding: 10px;
      }

      .inner-body {
        //overflow: auto;
        //padding: 0 5px;

        &.not-scrolling {
          overflow: hidden;
          display: flex;
          flex: 1 1;
          width: 100%;
        }
      }
    }
  }

  h1 {
    color: #464646;
    margin: 0;
    margin-bottom: 5px;
  }


  .bottom-bar {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 30px 0;
    z-index: 5;

    .bottom-bar-inner {
      display: flex;
      flex: 1;
      //max-width: 850px;
      width: 100%;
      justify-content: space-between;

      .GdButton {
        margin: 0 20px;
      }
    }

  }
}

p {
  color: $gd-dark-grey;
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 10px;

  .Autocomplete {
    display: inline-block;
    margin-left: 10px;
  }

  &.sub {
    color: $gd-grey;
    margin-top: 0px;
    font-size: 11px;
  }
}

.advanced-details {
  display: flex;
  flex-direction: column;
  overflow: auto;
  align-items: center;
  padding: 0 30px;
  max-width: 836px;
  .main-panel {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    //padding: 20px;
    //min-width: 500px;
    width: 100%;
    //max-width: 800px;
  }

  .details-inner {
    display: flex;
    flex: 1;
    flex-direction: column;
    //max-width: 850px;
    width: 100%;
  }
}

.details {
  h1 {
    font-size: 28px;
  }

  display: flex;
  flex-direction: column;
  //overflow: auto;
  //align-items: center;
  //padding: 80px;
  //padding-bottom: 100px;

  .details-inner {
    display: flex;
    flex: 1;
    flex-direction: column;
    //max-width: 850px;
    width: 100%;
  }

  .title-row {
    font-family: 'Poppins', sans-serif;
    display: flex;
    flex-direction: row;
    margin: 0;
    font-weight: 600;
    font-size: 20px;

    .Icon {
      margin-right: 20px;
    }
  }

  //
  //.name {
  //	margin-top: 20px;
  //}
  //
  //.description {
  //	margin-top: 20px;
  //}

  .save {
    margin-top: 20px;

    .GdButton {
      margin-right: 20px;
    }
  }
}

.rule-page-advanced {
  //padding-left: 20px;
  //padding-top: 150px;
  .action-type {
    font-size: 12px;
    font-weight: 600;
    color: #676767;
    margin: 3px 0;
    align-items: center;
    display: flex;
    justify-content: flex-start;

    span {
      text-transform: capitalize;
      margin-left: 5px;
      font-size: 14px;
      color: #424242;
      font-weight: 300;
    }
  }

  .title {
    font-weight: 700;
    font-size: 30px;
  }

  .description {
    margin: 0;
  }

  .schedule-timeline {
    //max-width: 800px;
  }

  .action-name {
    font-family: 'Poppins', sans-serif;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 50px;
    color: #595959;
    margin-top: 10px;
  }

  .indicator {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    margin-right: 10px;
  }

  .Icon {
    margin-left: 10px;
  }
}
