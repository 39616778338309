@import "../../styles/theme";

@media(max-width: 800px) {
  .right-side {
    display: none !important;
  }
  .left-side {
    padding: 0 !important;
  }
  .savings-calculator {
    .panel-card {
      max-width: none !important;
    }
  }
}

.savings-calculator {
  font-family: $primary-font;
  display: flex;
  height: 100%;
  //min-width: 1000px;
  overflow: hidden;
  align-items: start;

  .breakdown {
    font-family: 'Poppins', sans-serif;

    .stat {
      color: $gd-brand;
      font-weight: 700;
      font-size: 20px;
    }

    .name {
      font-weight: 700;
      margin-bottom: 20px;
    }
  }

  .results {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    padding: 40px 0;

    p.desc-txt {
      font-weight: 700;
      font-size: 18px;
    }

    .stat {
      font-weight: 700;
      font-size: 40px;
      color: $gd-brand;
      margin-bottom: 10px;
      line-height: 50px;
    }
  }

  .bold {
    font-weight: 700;
  }

  img {
    width: 100%;
    height: auto;
  }

  .panel-wrapper {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex: 50;
    justify-content: center;
    align-items: center;
    padding: 15px;
  }

  .large-centered {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .appliances-wrapper {
    margin-bottom: 30px;
  }

  .panel-card {
    border: 1px solid lightgrey;
    width: 100%;
    max-width: 650px;
    border-radius: 10px;
    overflow: auto;

    &.right {
      max-width: 650px;
    }

    .panel-header {
      padding: 20px 30px;
      background-color: $gd-brand;

      p {
        color: white;
        font-size: 25px;
        font-family: 'Poppins', sans-serif;
        margin: 0;
        font-weight: 700;

        &.sub-heading {
          font-size: 15px;
        }
      }
    }

    .panel-content {
      padding: 20px 30px;
    }
  }
}