#line-graph {
	width: 1200px;
	height: 400px;
}

.asset-settings {
	display: flex;
	flex-direction: row;
	.asset-general-settings {
		max-width: 500px;
		flex: 1;
	}
}

.settings-row {
	padding-top: 20px;
}
