@import "./../../styles/theme";

.modal-divider {
  width: 100%;
  height: 1px;
  background-color: $gd-bg-grey;
  margin: 20px;
}

.email-error {
  height: 40px;
  width: 100%;
  //display: inline-block;
  //flex: 1;
  justify-content: center;
  align-items: center;
}
.error-text {
  color: $gd-red;
}

.text-block {
  padding: 10px;
  .bold {
    font-weight: 700;
  }
}

.modal-block {
  padding: 10px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-question-container {
  padding-bottom: 10px;
  font-family: 'Poppins', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  width: 100%;
  box-sizing: border-box;
  .question {
    font-size: 15px;
    font-weight: 700;
  }
}

.cards-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
