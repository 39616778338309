.DataDownload {

  .filter-notice {
    color: grey;
    background-color: whitesmoke;
    padding: 10px 20px;
    font-size: 11px;
  }

  .details .title-row {
    padding: 10px;
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 10px;
  }

  .MuiSelect-select.MuiSelect-select {
    padding-right: 38px;
  }

  .editableListsWrapper {
    border: 1px solid #ececec;
    border-radius: 5px;
    height: 400px;
  }

  .Icon.clickable {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }

  .MuiFormGroup-root {
    margin-bottom: 10px;
  }

  .GdAutocomplete {
    display: inline-block;
    width: auto;
    min-width: 184px;
    float: left;
  }

  .no-sel {
    padding: 10px 20px;
    background: #49b9c4;
    color: white;
    display: inline-block;
    margin-right: 20px;
    margin-top: 20px;
    font-size: 13px;
    font-weight: 700;
    border-radius: 30px;
  }

  .MenuShowContainer {
    width: auto;
    float: left;
  }

  .filter {
    display: flex;
    margin-right: 10px;
    float: left;
    position: relative;
    margin-bottom: 10px;

    .MuiFormControl-root.GdInput {
      display: inline-block;
      width: auto;
      float: left;
    }

    .GdInput input, .GdInput .MuiSelect-select, .GdInput textarea {
      font-size: 11px;

      &.right-input {
        border-left: 1px solid #d3d3d3 !important;
        border-radius: 0 10px 10px 0 !important;
      }
    }

    .and-or {
      margin-right: 5px;

      .GdInput input, .GdInput .MuiSelect-select, .GdInput textarea {
        line-height: 38px;
        height: 37px;
      }
    }
  }

  .cust-gran {
    margin: 0;
    margin-top: 15px;
    margin-left: 10px;
    font-style: italic;
  }

  .push-right {
    .MuiFormControl-root {
      margin-right: 10px;
    }
  }

  .detail-content.lower {
    .MuiFormControl-root, .GdInput {
      max-width: 210px;
    }

    MuiFormControl-root, .GdInput, .DropDown {
      max-width: 230px;
    }
  }

  .filtered-selection {
    position: relative;
    display: inline-block;
    overflow: hidden;
    width: 100%;
    margin-top: 15px;

    .GdButton {
      float: left;
    }
  }

  .CardBody {
    overflow: hidden;
  }
}
