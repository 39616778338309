.sharewithorg.row {
  align-items: center;

  .MuiFormControl-root, .GdInput {
    max-width: 250px;
    margin-right: 10px;
  }
}

.sharewithorg {

  &.mobile {
    margin-top: 10px;
    flex-direction: row;
  }

  .GdButton {
    width: 150px;
    margin-right: 10px;

    &.stateChanger.sm {
      width: 150px;
    }
  }

  .normal-width {
    .GdButton {
      width: auto;
    }
  }


  .GdAutocomplete {
    width: 250px;
  }
}

.left-input {
  display: flex;
  flex: 1;

  .GdInput {
    input {
      border-radius: 10px 0 0 10px !important;
    }
  }
}

.right-input {
  margin-top: 14px;
  width: 130px;

  .GdInput input, .GdInput .MuiSelect-select, .GdInput textarea {
    border-left: 1px solid lightgrey;
    border-radius: 0 10px 10px 0 !important;
  }

}
