.integration-management {
  flex-direction: row;

  .integration-options {
    position: relative;
    overflow: auto;
    padding: 10px;
    width: 100%;

    .integration-option {
      display: flex;
      position: relative;
      overflow: hidden;
      box-sizing: border-box;
      border-radius: 10px;
      border: 1px solid #e8e8e8;
      padding: 10px 20px;

      &.connected {
        border-color: rgb(73, 197, 112) !important;
        border-width: 2px;
      }

      .inner-bordered-container {
        transition: 0.2s box-shadow ease-in;
        position: relative;
        overflow: hidden;
        display: flex;
        justify-content: start;
        align-items: start;
        //cursor: pointer;
        flex-direction: column;

        .title {
          font-weight: 600;
          font-size: 18px;
          font-family: Poppins, sans-serif;
          margin-bottom: 0;
        }

        .link {
          font-size: 13px;
        }

        .description {
          font-size: 13px;
          white-space: pre-line;
        }

        img {
          width: auto;
          height: auto;
          max-width: 100px;
          max-height: 100px;
        }
      }
    }
  }
}

.status-list-item {
  font-size: 14px;
  font-weight: 600;
  span {
    font-weight: 600;
    color: grey;
    font-size: 12px;
  }
  &.mini {
    font-size: 11px;
    line-height: 20px;
    margin: 0;
    color: grey;
    span {
      font-size: 11px;
    }
  }
}

.status-tab {
  position: absolute;
  right: 10px;
  top: 10px;
  border-radius: 60px;
  padding: 5px 15px;
  font-weight: 500;
  font-size: 11px;
  color: white;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: Poppins, sans-serif;
  line-height: 17px;
  background-color: grey;
  &.connected {
    background-color: rgb(73, 197, 112);
  }
  &.awaiting-connection {
    padding-left: 10px;
  }

  &.inline {
    position: relative;
    top: auto;
    right: auto;
  }

  .Icon {
    margin-right: 5px;
  }
}

.integration-steps {
  span {
    font-weight: 600;
    color: grey;
    font-size: 12px;
  }

  li {
    line-height: 25px;
  }
}

.link {
  font-weight: 600 !important;
}

.status-tab  {
  .Loader {
    margin-right: 5px;
  }
  svg {
    width: 13px;
    color: white;
    height: 13px;
  }
}