.no-card {
  padding: 20px;
  text-align: center;
  margin: 0;
  margin-top: 30px;
  font-weight: 500;
}

.card-list {
  .card {
    display: flex;
    padding: 20px;
    border-bottom: 1px solid #ececec;
    justify-content: space-between;
    align-items: center;
    .column {
      align-items: start;
    }
  }
}
  .product-list {
    padding: 20px 0;
    //max-height: 400px;
    //display: flex;
    flex: 1 1;
    flex-direction: column;
    overflow: auto;
    .image-wrapper {
      height: 35px;
      width: 50px;
      padding: 10px;
      padding-right: 20px;
      .image {
        width: 100%;
        height: 100%;
        background-size: contain;
        margin: 5px;
      }
    }
    .row  {
      overflow: hidden;
      margin-bottom: 15px;
    }
    .column {
      align-items: start;
      justify-content: center;
    }
  }
  .items-wrapper {
    .CardBody {
      padding: 0;
    }
  }