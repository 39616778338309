@import "../../styles/theme";

.scanning-instructions {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: .010em;
  line-height: 20px;
  background-color: #eaeaea;
  display: block;
  padding: 16px;
  color: rgba(0, 0, 0, 0.54);
}

.Loader .loading-bar::after {
  color: #49b9c4 !important;
}

.plus-icon-wrapper {
  height: 40px;
  width: 30px;
  display: flex;
  margin-left: 10px;
  justify-content: center;
  align-items: center;

  :hover {
    opacity: 0.7;
  }
}

.plus-text-wrapper {
  margin-left: 10px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.icon-wrapper-back {
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;

  :hover {
    opacity: 0.7;
  }
}

.title-wrapper {
  height: 40px;
  //width: 90%;
  width: 100%;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .text-wrapper {
    display: flex;
    width: 100%;
    height: 40px;
    justify-content: start;
    align-items: center;
    font-family: 'Poppins', sans-serif;

    p {
      font-size: 20px;
      margin: 0;
    }
  }
}

.add-device.CardBody {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

.impKwh {
  margin-left: 10px;
}

.appliance-dropdown {
  margin-left: 10px;
  padding-top: 0px !important;
  min-width: 200px;
}

.scanning-results {
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.no-padding {
  padding: 0 !important;
}

.body-container {
  overflow: hidden;
  display: flex;
  flex: 1 1;
}

.form-container {
  padding: 20px;
}

.editable-container {
  border-top: 1px solid #e8e8e8;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  flex: 1 1;
}

.no-devices {
  font-size: 14px;
  padding: 10px;
  text-align: center;
}

.scanning-result {
  &.mobile {
    padding: 16px 0px;
    flex-wrap: wrap;

    .impKwh {
      margin-left: 0px;
      margin-top: 10px;
    }
  }

  position: relative;
  padding: 0 16px;
  flex: 1 1 auto;
  display: flex;
  align-items: center;

  .GdInput {
    margin: 0 !important;
  }

  .Icon {
    font-size: 17px !important;
    line-height: 22px !important;
    width: 30px;
    justify-content: center;
    align-items: center;
    display: flex !important;
    height: 40px;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }

  .image {
    height: 50px !important;
    width: 50px !important;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-right: 16px;
    border-radius: 50%;
    box-sizing: content-box;
  }


  .description {
    margin-bottom: 0;
    margin-top: 0;
    font-size: 13px !important;
    color: grey;
  }
}

.button-row {
  margin-top: 30px;
  margin-bottom: 20px;
}

.channel-button-text {
  font-size: 20px;
  font-weight: bold;
}

.add-device-divider {
  width: 100%;
  height: 1px;
  background-color: $gd-border-grey;
  opacity: 1;
  margin-top: 20px;
  margin-bottom: 20px;
}

.button-container {
  display: flex;
  padding-right: 10px;
}

p.scanning-notice {
  text-align: center;
  font-weight: 700;
  margin-bottom: 0;
  line-height: 35px;
}

.header {
  font-family: "Asap", sans-serif;
  position: relative;
  font-size: 12px;
  font-weight: 700;
  color: grey;
  padding-left: 20px;
  margin-bottom: 8px;
}
