@import "../styles/theme";

.search-input-wrapper {
  padding: 5px 10px;

  .GdInput {
    margin-top: 0;

    input {
      background: white;
      border-radius: 55px !important;
      border: 1px solid #e8e8e8;
      font-size: 12px;
      padding: 0 35px;
      line-height: 35px;
      height: 35px;
    }
  }
}

.drag-handle {
  position: relative;
  height: 100%;
  width: 5px;
  min-width: 5px;
  margin-left: -2.5px;
  margin-right: -2.5px;
  opacity: 0;
  background-color: grey;
  z-index: 999;
  right: 0;
  cursor: col-resize;

  &:hover {
    background-color: grey;
    opacity: 0.2;
  }
}

.search-input-wrapper.boxy {
  padding: 0;

  .input-icon {
    display: flex;
    height: 30px;
    width: 30px;
    position: absolute;
    justify-content: center;
    align-items: center;
    top: 3px;
    left: 5px;
    pointer-events: none;

    .Icon {
      pointer-events: none;
    }

    &.no-pointer {
      pointer-events: none !important;
    }

    &.end {
      pointer-events: all !important;

      .Icon {
        pointer-events: all !important;
      }

      top: 4px;
      cursor: pointer;
      left: auto;
      right: 10px;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .GdInput {
    margin-top: 0;

    input {
      background: white;
      border-radius: 0 !important;
      border: none;
      border-bottom: 1px solid #e4e4e4;
      font-size: 11px;
      padding: 0 35px;
      line-height: 35px;
      height: 35px;
    }
  }
}

#left_nav {
  position: relative;
  width: 250px;
  min-width: 250px;
  max-width: 430px;
  flex-grow: 0;
  flex-shrink: 0;
  overflow: hidden;
  background-color: white;
  border-right: 1px solid #e8e8e8;
  z-index: 2;
  //padding-right: 5px;
  //-webkit-transition: all 0.2s ease-out;
  //transition: all 0.2s ease-out;
  #MenuShowContainer {
    position: relative;
    width: 100%;
    align-items: center;

    .menu-show-inner {
      position: relative;
      width: 100%;
      align-items: center;

      .left-nav-dd {
        border-top: 1px solid #e8e8e8;
        border-bottom: 1px solid #e8e8e8;
        display: flex;
        padding: 7px 20px;
        font-size: 11px;
        height: auto !important;
        width: auto !important;
        color: #818692;
        align-items: center;

        .filter-chevron {
          margin-left: 10px;
        }

        .filter-icon {
          justify-content: center;
          align-items: center;
          display: flex;
          margin-right: 5px;
        }

        .fake-dropdown {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex: 1 1;
        }
      }
    }
  }

  .Tab.selected {
    border-bottom: 1px solid #818692;
  }

  .search-input-wrapper {
    padding: 0;

    .GdInput {
      margin-top: 0;

      input {
        background: white;
        border-radius: 0 !important;
        border: none;
        border-bottom: 1px solid #e4e4e4;
        font-size: 11px;
        padding: 0 35px;
        line-height: 35px;
        height: 35px;
      }
    }
  }

  .create-new-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 20px;

    p {
      font-size: 12px;
      color: #818692;
    }
  }

  .Tabs {
    //padding-left: 15px;
    border-bottom: 1px solid #e8e8e8;

    .TabRow {
      line-height: 25px;
      border-bottom: none !important;
      color: #818692;

      .Indicator {
        background-color: #818692;
      }

      .Tab {
        padding-left: 10px;
        //border-bottom: none !important;;
        padding-right: 10px;
        color: #818692;

        &.selected {
          color: #818692;
        }
      }
    }
  }

  .MuiList-padding {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .input-icon {
    display: flex;
    height: 30px;
    width: 30px;
    position: absolute;
    justify-content: center;
    align-items: center;
    top: 3px;
    left: 5px;
    pointer-events: none;

    .Icon {
      pointer-events: none;
    }

    &.no-pointer {
      pointer-events: none !important;
    }

    &.end {
      pointer-events: all !important;

      top: 4px;
      cursor: pointer;
      left: auto;
      right: 10px;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .powered-by {
    position: absolute;
    height: 60px;
    display: flex;
    flex-direction: column;
    align-items: end;
    right: 15px;
    bottom: -5px;
    overflow: hidden;

    &.closed {
      align-items: start;
      left: 11px;
      width: 34px;
      bottom: 10px;
    }

    p {
      color: grey;
      font-size: 11px;
      margin: 0;
    }

    .pb-logo {
      width: 100px;

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .order-hardware-button {
    height: 48px;
    display: flex;
    align-items: center;
    padding-bottom: 43px;
  }

  .GdButton {
    height: 28px;
    //width: 40px;
    padding: 0 20px;
    line-height: 28px;
    font-size: 11px;
    letter-spacing: 0;
  }

  .divider {
    border-top: 1px dashed #3c3c3c;
  }

  .upper-menu-items {
    position: relative;
    overflow: hidden;

    .inner-items {
      -webkit-transition: 0.2s all ease-in;
      overflow: hidden;
      max-height: 280px;
    }

    &.closed {
      .inner-items {
        max-height: 30px;
      }
    }

    .see-more-less {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 0 20px;
      height: 35px;
      color: #8b92a1;

      &:hover {
        opacity: 0.7;
      }

      p {
        font-size: 11px;
        color: #8b92a1;
        margin: 0;
        padding-right: 8px;
      }
    }

    .menu-item {
      height: 30px;
      line-height: 30px;
      border-bottom: none;
      align-items: center;

      .left-nav-icon {
        line-height: 27px;

        &.playlist {
          padding-left: 2px;
        }
      }

      p {
        line-height: 30px;
        text-transform: none;
      }
    }
  }

  &.powered-by {
    .menu-items {
      padding-bottom: 75px;
    }
  }

  .menu-items {
    display: flex;
    flex: 1 1;
    overflow: hidden;
    position: relative;
    padding-bottom: 45px;

    .menu-items-scroller {
      position: relative;
      overflow: auto;
      overflow-x: hidden;
      width: 100%;
      height: 100%;
    }
  }

  .menu-item {
    align-items: center;
    height: 48px;
    position: relative;
    width: 100%;
    cursor: pointer;
    border-bottom: 1px solid #f3f3f3;
    display: flex;

    &.portfolio {
      height: 38px;
      line-height: 38px;
      border-bottom: none;
      background: white;
      box-sizing: border-box;

      p {
        font-size: 12px;
        line-height: 38px;
        text-transform: none;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        //max-width: 173px;
        //width: calc(100% - 113px) !important;
        max-width: none !important;
      }

      .left-nav-icon {
        line-height: 37px;
      }
    }

    &.top {
      border-top: 1px solid #f3f3f3;
    }

    &.selected {
      border-left: 3px solid #47b7c2;
      background-color: #eeffff;

      &:hover {
        background-color: #eeffff;
      }

      p, .left-nav-icon {
        color: #676d76;
        margin-left: -3px;
        font-weight: 600;
      }
    }

    &:hover {
      background-color: #f6f6f6;
    }

    p {
      -webkit-transition: 0.2s opacity ease-in;
      display: inline-block;
      //padding-left: 48px;
      font-size: 11px;
      line-height: 48px;
      margin: 0;
      text-transform: uppercase;
      font-weight: 500;
      color: #818692;
    }

    .left-nav-icon {
      //position: absolute;
      left: 0;
      top: 0;
      overflow: hidden;
      min-width: 52px;
      display: inline-block;
      height: 100%;
      text-align: center;
      line-height: 45px;
      color: #99a0b0;

      &.right {
        width: 30px;
        //right: 10px;
        //left: auto;
        &.second {
          //right: 40px;
          z-index: 0;
        }
      }

      &.number {
        line-height: 28px;
      }

      .Loader {
        display: inline-block;
        margin-top: 2px;

        .MuiCircularProgress-colorPrimary {
          color: #47b7c2;
        }
      }

      .alert-number {
        width: auto;
        background: #e43535;
        height: 20px;
        line-height: 20px;
        border-radius: 30px;
        font-size: 8px;
        font-weight: 700;
        padding: 3px 8px;
        color: white;

        &.none {
          background: #47b7c2;
        }
      }
    }
  }

  &.closed {
    width: 52px !important;
    min-width: 52px !important;

    .see-more-less {
      padding-left: 21px;
    }

    .menu-item p {
      padding-left: 400px;
    }

    .menu-inner-content {
      width: 250px;
    }
  }

  .menu-inner-content {
    position: relative;
    top: 0;
    left: 0;

    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .menu-header {
    position: relative;
    overflow: hidden;
    padding: 10px 8px;

    svg {
      width: 140px;
    }

    .logo {
      width: 130px;
      min-width: 130px;
      height: auto;
      float: left;
    }


  }

  .lock-icon {
    cursor: pointer;
    position: absolute;
    width: 50px;
    height: 44px;
    line-height: 40px;
    text-align: center;
    bottom: 0;
    left: 0;
    z-index: 5;

    &:hover {
      opacity: 0.7;
    }
  }
}