@import "./../../styles/theme";

.reset-page {
  .controls {
    user-select: none;
  }
  .resets {
    display: flex;
    width: 80%;
    flex-direction: column;
  }
  background: white !important;
  .date-pick {
    width: 200px;
    margin-right: 20px;
  }
  span {
    cursor: pointer;
    font-weight: bold;
  }
  .operations {
    display: flex;
    padding: 10px;
    .GdButton {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
  .data-summary {
    display:flex;
    min-height: 600px;
    flex-direction:row;
    .left-table {
      flex: 1;
    }
    .right-graph {
      flex: 3;
      border: 1px solid $gd-border-grey;
    }
  }
  table.result-table {
    border: 1px solid $gd-border-grey;
    padding: 15px;
    height: fit-content;
    border-collapse: collapse;
    th {
      padding: 5px;
      border: 1px solid $gd-border-grey;
    }
    td {
      padding: 5px;
      border: 1px solid $gd-border-grey;
    }
    .results-header {
      font-weight: bold;
    }
    .record-row {

    }
    .reset-row {
      background-color: $gd-border-grey;
    }
  }
}