.device-hub-issue-overview-icons {
  p.issue-type-name {
    font-size: 11px;
    color: grey;
    margin: 0;
    margin-top: 3px;
    margin-bottom: 3px;
    font-weight: 600;
  }
  .icon-column {
    flex: 0;
    margin-right: 10px;
    .link {
      border-bottom: none;
    }
  }
  .issue-icon-row {
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    align-items: center;
    padding: 2px 5px;
    padding-left: 0;
    flex: 0;
    display: flex;
    .Icon {
      margin-left: 5px;
    }
  }
}