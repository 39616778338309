.widget {
  -webkit-transition: 0.2s all ease-in;
  transition: 0.2s all ease-in;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  border: 1px solid #e9e9e9;
  margin: 5px;
  margin-top: 0;
  margin-bottom: 10px;
  background-color: white;
  border-radius: 15px;
  overflow: visible;
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
  min-height: 180px;
  &.loading {
    .widget-graph {
      opacity: 0;
    }

    pointer-events: none;
    opacity: 0.7;
  }

  .widget-graph {
    -webkit-transition: 0.5s opacity ease-in;
    transition: 0.5s opacity ease-in;
    opacity: 1;
    height: 140px;
  }

  .button-wrapper {
    -webkit-transition: 0.2s opacity ease-in;
    transition: 0.2s opacity ease-in;
    opacity: 0;
  }

  &:hover {
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
    &.show-legend {
      box-shadow: -1px 6px 10px 0px #00000014;
      border-radius: 15px 15px 0 0;
    }

    .button-wrapper {
      opacity: 1;
    }
  }

  .not-enough {
    position: absolute;
    top: 50%;
    width: 100%;
    text-align: center;
  }

  .widget-header {
    padding: 15px 20px;
    padding-bottom: 0;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .icon-wrapper {
      display: flex;
      width: 20px;
      height: 20px;
      border-radius: 100%;
      overflow: hidden;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
    }
  }

  .widget-value {
    -webkit-transition: 0.3s opacity ease-in;
    transition: 0.3s opacity ease-in;
    opacity: 0;
    background: white;
    padding: 10px 20px;
    font-weight: 500;
    font-size: 13px;
    display: flex;
    justify-content: space-between;
    align-items: start;
    flex-direction: column;
    position: absolute;
    top: 100%;
    width: calc(100% - 40px);
    border: 1px solid #e9e9e9;
    left: -1px;
    //border-top: 0;
    z-index: 2;
    box-shadow: -1px 6px 10px 0px #00000014;
    border-radius: 0 0 15px 15px;
    pointer-events: none;

    &.showing {
      opacity: 1;
    }

    &.historic {
      //min-height: 35px;
    }

    span {
      color: grey;
      font-weight: 200;
      font-size: 13px;
    }

    span.date-label {
      font-size: 12px;
      font-weight: 500;

      &.compare {
        font-size: 12px;
        font-weight: 200;
      }
    }
  }

  .loader-box {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 10px;
    display: flex;
  }
}