.get-in-touch {
  max-width: 500px;
  line-height: 25px;
  text-align: center;
  background-color: whitesmoke;
  padding: 30px;
}

.getting-started-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  padding: 30px;
  overflow: auto;
  background-color: white;
  align-items: center;

  .gs-header {
    font-family: Poppins, sans-serif;
    font-size: 50px;
    font-weight: 700;
    color: #49b9c4;
    margin-bottom: 0;
    margin-top: 40px;
  }

  .gs-subheader {
    font-family: Poppins, sans-serif;
    font-size: 14px;
    margin-bottom: 60px;
  }

  .gs-steps-wrapper {
    .gs-step {
      margin-bottom: 20px;

      .gs-step-title-wrapper {
        display: flex;
        align-items: center;
        cursor: pointer;
        &:hover {
          opacity: 0.7;
        }

        .gs-step-number {
          font-size: 17px;
          background: #49b9c4;
          width: 40px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          color: white;
          border-radius: 100%;
          font-family: 'Poppins', sans-serif;
          font-weight: 700;
        }

        .gs-step-icon {
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .gs-step-header {
          font-size: 25px;
          line-height: 40px;
          text-align: center;
          color: #49b9c4;
          font-family: 'Poppins', sans-serif;
          font-weight: 700;
          margin-left: 20px;
        }

      }

      .gs-body-wrapper {
        padding-left: 50px;
        display: flex;

        &.closed {
          height: 0;
          overflow: hidden;
        }

        .gs-image {
          img {
            width: 200px;
          }

          &.create-site img {
            width: 200px;
            margin-bottom: -50px;
          }

          &.add-hub img {
            width: 300px;
            margin-top: 15px;
            margin-right: -50px;
            margin-bottom: -40px;
            margin-left: -20px;
          }
        }

        .gs-body-text {
          padding: 20px;
          max-width: 300px;

          span.lighter-italic {
            color: grey;
            font-style: italic;
          }

          span.plus-icon {
            width: 20px;
            height: 20px;
            background: #49b9c4;
            border-radius: 100%;
            display: inline-block;
            text-align: center;
            line-height: 17px;
            color: white;
            font-weight: 700;
          }
        }
      }
    }
  }
}