@import "../../styles/theme";

.preview-container {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.audit-modal-overview {
  padding: 10px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.stat-container {
  padding: 15px 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  .bold {
    font-weight: 700;
  }
}

.audit-header-modal {
  padding: 15px 15px;
  //width: 200px;
  position: relative;
  background-color: $gd-orange;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.doesNotExist-header-modal {
  padding: 15px 15px;
  //width: 200px;
  position: relative;
  background-color: $gd-red;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.file-preview {
  height: 120px;
  width: 120px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}


