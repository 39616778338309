#filter-menu {
  .MuiSvgIcon-root {
    width: 0.8em;
    height: 0.8em;
  }
}

.initialising {
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  display: flex;
  flex: 1 1;
  justify-content: center;
  align-items: center;
  color: grey;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  pointer-events: none;
  flex-direction: column;

  .initialising-title {
    font-size: 17px;
    font-family: Poppins, sans-serif;
    font-weight: 500;
    margin-bottom: 0;

  }
}

.hidden-scroller {
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
  padding: 20px;
  //padding-top: 0;
  &::-webkit-scrollbar {
    display: none;
  }
}

.minimal-dropdown {
  .MuiInputBase-root {
    min-width: auto !important;
  }

  .MuiSelect-select {
    font-size: 12px !important;
    height: 26px !important;
    line-height: 26px !important;
    background: transparent !important;
  }

  &.ultra-minimal {
    .MuiSelect-select {
      font-size: 11px !important;
      border: none !important;
      padding: 0;
      padding-right: 5px;
      height: 15px !important;
      line-height: 15px !important;
      box-shadow: none !important;
      color: #595959;

      &:hover {
        box-shadow: none !important;
        opacity: 0.7;
      }
    }

    .Dropdown .MuiSelect-icon.MuiSvgIcon-root {
      display: none !important;
    }
  }

  .Dropdown .MuiSelect-icon.MuiSvgIcon-root {
    height: 1em !important;
  }

}

.filter-menu-container {
  .loader-wrapper .loader-inner {
    padding: 13px 50px;
    width: auto;
  }
}

.filter-menu-container label span {
  font-size: 12px !important;
}

.summary-page {
  overflow: hidden !important;
  display: flex !important;
  flex: 1 1 !important;
  background: white !important;
  width: 100%;
  margin: 0;

  &.fullscreen {
    position: fixed;
    z-index: 999;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
  }

  .editing-header {
    background: white;
    padding: 5px;
    display: flex;
    justify-content: space-between;
  }

  .button-wrapper {
    //background: #f35a5a;
    background-color: whitesmoke;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;

    &.grey {
      background-color: whitesmoke;
    }

    &.larger {
      width: 35px;
      height: 35px;
      border-radius: 100%;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  .GdButton {
    height: 25px !important;
    font-size: 11px !important;
    line-height: 25px;
  }

  .widget-board-header {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    border-bottom: 1px solid #e8e8e8;
    min-height: 40px;

    .GdButton {
      margin: 0;
      margin-left: 10px;
    }
  }

  .widget-wrapper {
    position: relative;
    overflow: visible;
    //min-height: 200px;
    .drag-window {
      background-color: rgba(255, 255, 255, 0.9);
      z-index: 2;
      position: relative;
      width: calc(100% - 10px);
      margin: 5px;
      margin-top: 0;
      margin-bottom: 10px;
      user-select: none !important;
      height: 182px;

      &[class*="header"] {
        height: 140px;
      }

      &[class*="device_breakdown"], &[class*="group_breakdown"], &[class*="category_breakdown"] {
        height: 366px;
      }

      .inner-window {
        display: flex;
        height: 100%;
        border: 1px dashed lightgrey;
        border-radius: 10px;
        flex-direction: column;

        .inner-window-header {
          background-color: white;
          padding: 10px;
          padding-left: 20px;
          border-radius: 10px 10px 0 0;
          border-bottom: 1px dashed lightgrey;
          font-family: 'Poppins', sans-serif;
          display: flex;
          align-items: center;
          justify-content: space-between;

          &.electricity {
            background: #fffcf5;
          }

          &.gas {
            background: #faf5ff;
          }

          &.water {
            background: #f5fbff;
          }

          p {
            font-size: 14px;
            font-weight: 500;
            margin: 0;
          }
        }

        .drag-handle {
          display: flex;
          flex: 1 1;
          align-items: center;
          justify-content: center;

          p {
            margin: 0;
            font-weight: 700;
            padding-left: 10px;
          }
        }
      }
    }
  }

  .draggable-column {
    transition: 0.2s background-color ease-in;
    display: table-cell;
    vertical-align: top;
    padding-bottom: 30px;
    border: 1px dashed transparent;
    min-width: 240px;

    &.is-editing {
      border-top: 1px dashed lightgrey;
      border-right: 1px dashed lightgrey;

      &:nth-child(1) {
        border-left: 1px dashed lightgrey;
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }

    &.dragging-over {
      background-color: #f6f6f6;
    }

  }

  //div[data-rbd-droppable-id="sensor"] {
  //  max-width: 200px;
  //  min-width: 150px;
  //}

  .widget {

    &.header {
      min-height: 200px;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

}
