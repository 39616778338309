.data-archive {
  //.list-heading .list-field:nth-child(1) {
  //  min-width: 0;
  //  max-width: 0;
  //  padding-left: 15px;
  //  padding-right: 40px;
  //}
  .data-request-row {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .list-row {
    cursor: default;
    &:hover {
      .list-field {
        background-color: white;
      }
    }
  }
  //.list-row .list-field.checkbox {
  //  padding-right: 0;
  //}
}