.Account {
  //padding: 30px 0;
  //overflow: auto;
  padding-bottom: 60px;
  .expires {
    margin: 0;
    color: #a5a5a5;
    font-size: 12px !important;
  }

  .default-icon {
    margin: 0;
    margin-right: 50px;
    background-color: #f1f1f1;
    padding: 5px 10px;
    color: #868686;
    font-size: 11px !important;
    border-radius: 5px;
    margin-top: 3px;
    &.no-margin {
      margin-right: 0 !important;
    }
    &.make-default {
      background-color: white;
      cursor: pointer;
      padding-right: 0;
      &:hover {
        opacity: 0.7;
      }
    }
  }

  table.invoices {
    width: 100%;
    padding: 20px;
    padding-top: 5px;
    p {
      vertical-align: top;
      //line-height: 20px;
      span {
        font-size: 12px;
        color: #a8a8a8;
      }
    }
    tr.invoice {
      line-height: 25px;
      font-size: 13px;
      min-height: 35px;
      color: #535353;
      vertical-align: top;
    }
    td {
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 10px;
    }
    th {
      padding-left: 10px;
      padding-right: 10px;
      text-align: left;
      font-size: 13px;
      line-height: 40px;
      //border-bottom: 1px solid #ececec;
      color: #535353;
    }
  }

  .card-list {
    .card {
      display: flex;
      padding: 20px;
      border-bottom: 1px solid #ececec;
      justify-content: space-between;
      align-items: center;
      .column {
        align-items: start;
      }
    }
  }

  .profile-picture {
    width: 80px;
    height: 80px;
    margin-left: 10px;
    border-radius: 100%;
    background-size: cover;
    background-position: center;
    border: 3px solid #e8e8e8;
    margin-right: 30px;
    margin-bottom: 20px;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    color: lightgrey;
    background-color: whitesmoke;
    &.org {
      background-size: 90%;
      border-radius: 10px;
      border: 1px solid lightgrey;
      width: 130px;
      height: 130px;
      &.long {
        height: 70px;
        width: 250px;
        background-size: 90%;
        background-position: 10%;
      }
    }
  }

  .mfa-butt {
    width: 100px;
  }




  .GdInputOuter {
    margin-bottom: 15px;
    width: 100%;
  }
}