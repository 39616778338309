.example-login {
  display: flex;
  flex: 1 1;
  justify-content: center;
  align-items: center;
  height: 100%;
  .example-login-inner {
    p {
      font-size: 14px;
      margin-bottom: 0;
      margin-top: 30px;
      color: #929292;
    }
  }
}