.loader-wrapper {
  display: flex;
  flex: 1 1;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  .loader-inner {
    padding: 50px;
    width: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .loader-text {
    display: flex;
    text-align: center;
    margin-top: 20px;
    color: grey;
  }
}
