.header-bar {
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  background: white;
  width: 95%;
  z-index: 1;
  border-top: 1px solid;

  &.mobile {
    bottom: 55px;
  }

  .GdButton {
    width: 100px;
  }
  .header-bar-inner {
    width: 100%;
    display: flex;
    max-width: 540px;
    flex-direction: row-reverse;
  }
}
