@import "../../styles/theme";

.site-settings {
	display: flex;
	flex-direction: row;
	.site-general-settings {
		max-width: 500px;
		flex: 1;
		.verify-text {
			font-size: 12px;
			color: $gd-grey;
		}
	}
}

.custom-header {
	height: 40px;
	.custom-header-text {
		font-weight: bold;
		font-size: 12px;
	}
}

.gateway-warnings {
	width: auto;
	background: #e43535;
	height: 20px;
	line-height: 20px;
	border-radius: 30px;
	font-size: 9px;
	font-weight: 700;
	padding: 3px 8px;
	color: white;
	margin-left: 5px;
	&.grey {
		background-color: grey;
	}
}
.gateway-verifying-modal-text {
	font-size: 13px;
	color: $gd-grey;
}
.mac-address-email-text {
	background-color: $gd-border-grey;
	padding: 30px 50px;
	color: $gd-off-black;
	font-style: italic;
	font-size: 14px;
	line-height: 24px;
}

.site-page .list-field #MenuShowContainer .state-select-view.list-field.status .menu-span p {
	color: #595959 !important;
	font-weight: 400 !important;
}
