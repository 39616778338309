@import "../../styles/theme";

.image-preview {
  width: auto;
  height: 450px;
}

.video-preview {
  outline: none;
  width: 100%;
  height: 450px;
}

.file-manager {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;

  &.no-pointer {
    opacity: 0.7;
    pointer-events: none;
  }

  .MuiFormControl-root.with-icon {
    input {
      padding-left: 32px;
    }
  }

  .GdInput {
    margin-top: 0;

    .Icon {
      z-index: 4;
      left: 12px !important;
      top: 12px !important;
    }

  }

  .fm-top-bar {
    display: flex;
    padding: 10px;
    border-bottom: 1px solid $gd-border-grey;
  }

  .file-dropper {
    display: flex;
    flex-direction: row;
    flex: 1;
    overflow: hidden;

    .dragging-overlay {
      display: flex;
      flex-direction: column;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: white;
      transition: background-color 0.5s;

      .fm-container {
        display: flex;
        flex-direction: row;
        flex: 1;
        overflow: hidden;
      }

      &.active {
        background-color: $gd-blue-background;
      }
    }

    .prompt {
      position: absolute;
      width: calc(100% - 300px);
      height: calc(100% - 143px);
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1 1;

      .prompt-items {
        width: 250px;
        height: 250px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      .Icon {
        color: #d8d8d8 !important;
      }

      .image-text {
        text-align: center;
        color: #d8d8d8;
        font-size: 15px;
        margin-top: 8px;
      }
    }
  }

  .file-manager-filter-bar {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    .GdInput {
      max-width: 200px;
      margin-right: 10px;
    }

    .DummyInput {
      width: 120px;
    }

    .Autocomplete {
      margin-left: 15px;

      .DummyInput {
        margin-top: 0;
      }
    }

    .file-manager-action-bar {
      display: flex;
      margin-left: auto;
      margin-right: 30px;
      align-items: center;

      .Icon {
        padding: 8px;
      }
    }

    .file-manager-count {
      display: flex;
      font-size: 15px;
      color: $gd-dark-grey;
      margin-left: 15px;
    }
  }

  .file-list {
    width: calc(100% - 300px);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    place-content: baseline;
    padding: 5px;
    overflow: auto;

    .select-box {
      border: 1px solid $gd-blue;
      background-color: $gd-blue-border;
      opacity: 0.5;
    }

    .file-card {
      user-select: none;
      padding: 5px;
      height: 121px;
      display: flex;
      flex: 1 1;
      max-width: 150px;
      min-width: 110px;


      &.selected {
        .Card {
          border-color: $gd-blue-border;

          .CardHeader {
            color: $gd-blue;
            background-color: $gd-blue-background;
          }
        }
      }

      .Card {
        margin: 0;
        width: 100%;
        height: 100%;
        border: 1px solid lightgrey;
        border-radius: 5px;
        display: flex;
        flex-direction: column;

        p {
          margin: 0;
          font-size: 12px;
        }

        h1 {
          width: 100%;
          text-align: center;
          font-size: 22px;
          line-height: 50px;
          color: grey;
        }

        .CardHeader {
          height: 10px;
          font-size: 12px;
          font-weight: 300;
          padding: 10px;
          line-height: 12px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        .Icon {
          width: 100%;
          height: 90px;
          text-align: center;
          line-height: 90px !important;
        }

        .image-background {
          width: 100%;
          height: 100%;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }
  }

  .file-summary {
    display: flex;
    flex-direction: column;
    border-left: 1px solid #e8e8e8;
    width: 300px;
    max-width: 300px;
    overflow: hidden;
    p {
      font-size: 13px;
    }

    .file-info {
      position: relative;
      display: flex;
      flex: 1;
      flex-direction: column;
    }

    form {
      position: relative;
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    .button-wrapper {
      display: flex;
      flex-direction: row-reverse;
    }

    .heading {
      color: $gd-off-black;
      background-color: $gd-bg-grey;
      //padding: 10px;
      border-bottom: 1px solid $gd-border-grey;
      position: relative;
      display: flex;
      align-items: center;
      font-size: 14px;
      //padding-left: 15px;
      .file-heading-text {
        margin: 0;
        padding: 9.5px;
        font-size: 13px;
      }

      div {
        width: 100%;
      }

      .GdInput {
        input {
          height: 32px;
          padding-left: 10px;
          border-radius: 0 !important;
        }
      }
    }

    .preview {
      min-height: 200px;
      max-height: 200px;
      width: 100%;
      border-bottom: 1px solid $gd-border-grey;
      position: relative;
      display: flex;
      flex: 1;
      align-items: center;

      .Icon {
        margin: auto;
      }

      .image-preview {
        position: relative;
        display: flex;
        flex: 1;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }
    }

    .size {
      align-items: center;
      max-height: 13px;
      position: relative;
      display: flex;
      flex: 1;
      font-size: 13px;
      padding: 10px;
      color: $gd-off-black;
      border-bottom: 1px solid $gd-border-grey;
      background-color: $gd-bg-grey;

      p {
        padding: 0;
        margin: 0;

        .size-heading {
          font-size: 10px;
          margin-right: 5px;
        }

        .value {
          font-size: 13px;
        }
      }
    }

    .file-details {
      align-items: center;
      max-height: 13px;
      position: relative;
      display: flex;
      flex: 1;
      padding: 10px;
      color: $gd-off-black;
      border-bottom: 1px solid $gd-border-grey;
      background-color: $gd-bg-grey;

      p {
        padding: 0;
        margin: 0;

        .file-heading {
          font-size: 10px;
          margin-right: 5px;
        }

        .value {
          font-size: 13px;
        }
      }
    }

    .file-description {
      position: relative;
      display: flex;
      flex: 1;
      align-items: center;
      padding: 20px 10px;

      textarea {
        height: 100%;
        width: calc(100% - 20px);
        border: none;
        margin: 10px;
        resize: none;
        background-color: white;

        &:disabled {
          background-color: white;
        }

        &:focus {
          outline: 0;
        }
      }
    }

    .GdButton {
      margin: 10px;
    }

    .file-name-list {
      display: flex;
      flex-direction: column;
      flex: 1;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 5px;

      .file-name {
        margin: 10px;
        font-size: 12px;
        cursor: pointer;
        text-overflow: ellipsis;
        overflow: hidden;
        min-height: 15px;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}


body.react-confirm-alert-body-element {
  overflow: hidden;
}


.react-confirm-alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

  .dialog {
    background-color: white;
    color: $gd-off-black;
    border-radius: 5px;

    .top-content {
      padding-top: 15px;
      margin: 15px;

      h2 {
        margin: 20px;
        font-size: 16px;
        font-weight: 600;
      }

      p {
        margin: 20px;
        font-size: 14px;
      }
    }

    .bottom-menu {
      display: flex;
      //border-top: 1px solid $gd-blue-border;
      justify-content: flex-end;
      padding: 15px;

      .GdButton {
        margin: 10px;
      }
    }
  }
}


