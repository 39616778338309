@import "../styles/theme";

.kpi {
  display: flex;
  //min-height: 92px;
  flex-direction: row;

  &.disabled {
    opacity: 0.5;
  }

  .kpi-datum {
    //padding: 20px;
    color: $gd-dark-grey;
    z-index: 2;
    position: relative;
    cursor: default;
    margin-right: 10px;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 10px;
    height: 50px;
    padding: 0 5px;
    overflow: hidden;

    &.custom {
      border: 1px dashed #eeeeee;
      padding: 0 15px;
      &.narrow {
        max-width: 130px;
      }

      .cancel-button {
        transition: opacity 0.3s ease-in;
        position: absolute;
        top: 3px;
        right: 5px;
        opacity: 0;
        &.always-show {
          opacity: 1;
        }
      }

      &:hover {
        .cancel-button {
          opacity: 1;
        }
      }
    }

    .kpi-title {
      &.min-dd {
        max-height: 15px;
        overflow: hidden;

      }
      font-size: 11px;
      //margin-bottom: 5px;
    }

    .kpi-value {
      font-size: 20px;
      font-weight: 500;
      height: 25px;
      white-space: nowrap;
      .Loader {
        align-items: start;
        padding-top: 6px;
      }
    }
  }
}

.group-kpi-loader {
  .loader-wrapper {
    position: relative !important;
  }
}

.custom-kpi {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px dashed lightgrey;
  border-radius: 10px;
  padding: 0 15px;
  height: 50px;

  &.create {
    cursor: pointer;

    p {
      margin: 0;
      font-size: 11px;
      margin-top: 3px;
      color: grey;
    }

    &:hover {
      background-color: #f8f8f8;
    }
  }
}

.swiper-button-prev {
  top: 0;
  cursor: pointer;
  width: 60px;
  height: 100%;
  position: absolute;
  z-index: 2;
  background-image: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  &:hover {
    &::after {
      opacity: 0.7;
    }
  }

  &::after {
    color: #b9b9b9;
    content: '';
    font-family: "Font Awesome 6 Free";
    position: absolute;
    left: 0;
    font-weight: 700;
    font-size: 20px;
    width: 100%;
    text-align: left;
    height: 100%;
    display: flex;
    vertical-align: middle;
    align-items: center;
  }

  &.swiper-button-disabled {
    display: none !important;
  }
}

.swiper-button-next {
  top: 0;
  cursor: pointer;
  width: 60px;
  height: 100%;
  position: absolute;
  z-index: 2;
  right: 0;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  &:hover {
    &::after {
      opacity: 0.7;
    }
  }
  &::after {
    color: #b9b9b9;
    content: '';
    font-family: "Font Awesome 6 Free";
    position: absolute;
    left: 0;
    font-weight: 700;
    font-size: 20px;
    width: 100%;
    flex-direction: row-reverse;
    height: 100%;
    display: flex;
    vertical-align: middle;
    align-items: center;
  }
  &.swiper-button-disabled {
    display: none !important;
  }
}

.swiper {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.icon-row {
  height: 30px;
  align-items: center;
  cursor: pointer;
  padding: 0 10px;
  &.selected {
    border: 1px solid #49b9c4;
    background-color: #eefeff;
    border-radius: 5px;
    pointer-events: none;
  }

  &:hover {
    background-color: #eeffff;
  }

  p {
    margin: 0;
    margin-left: 10px;
    font-size: 12px;
    font-weight: 600;
  }
}
