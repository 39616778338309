.partner-card {
  cursor: pointer;
  margin: 10px 0;
  display: flex;
  border-radius: 10px;
  border: 1px lightgrey solid;
  padding: 20px 0;
  transition: 0.3s all ease-out;
  width: 100%;
  h4 {
    font-size: 16px;
    line-height: 16px;
    font-family: Poppins, sans-serif;
  }
  &.selected {
    border: 1px #49b9c4 solid;
    background-color: #e5fdff;
  }
  &:hover {
    box-shadow: 0 0 0 4px rgba(45, 184, 193, 0.1);
  }
  .partner-card-contents {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding-right: 20px;
    .left-section {
      min-width: 100px;
      width: 100px;
      justify-content: center;
      align-items: center;
      display: flex;
    }
    .right-section {
      justify-content: flex-start;
      align-items: flex-start;
      //border: 1px red solid;
      h4 {
        margin: 10px;
      }
      p {
        margin: 10px;
        font-size: 13px;
        color: grey;
      }

    }

  }


}


