@import "../styles/theme";

.globalSearchMobile {
  transition: 0.2s ease-out;
  display: flex;
  background-color: whitesmoke;
  border-radius: 100%;
  right: 65px;
  width: 30px;
  height: 30px;
  position: fixed;
  z-index: 999;
  overflow: hidden;

  .icon-wrapper {
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    display: flex;
    position: absolute;
  }

  .search-body {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex: 1 1;

    .search-header {
      border-bottom: 1px solid #e8e8e8;
      padding-right: 66px;
      padding-left: 40px;
      display: flex;
      position: relative;
      flex: 1;
      max-height: 56px;
      //background: green;
      .GdInput {
        margin: 0 !important;

        .MuiInput-input {
          border-radius: 0;
          height: 56px;
          box-shadow: none !important;
          border: none !important;
          background-color: white !important;
          font-size: 14px;
          padding-left: 8px;
        }
      }

      .icon {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;

        &.cancel {
          top: 0;
          right: 0;
          width: 66px;
          height: 56px;
        }

        &.search {
          top: 0;
          left: 19px;
          width: 20px;
          height: 56px;
        }
      }
    }

    .search-body-inner {
      //background: red;
      display: flex;
      position: relative;
      flex: 1 1;
      overflow: auto;
      flex-direction: column;

      .mobile-search-list-item {
        display: flex;
        min-height: 56px;
        align-items: center;
        padding: 0 15px;
        border-bottom: 1px solid whitesmoke;
        p {
          margin-left: 15px;
          font-size: 13px;
          margin-bottom: 3px;
        }
      }
    }
  }

  &.open {
    background-color: white;
    width: 100%;
    right: 0;
    border-radius: 0;
    height: 100%;
    top: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex: 1 1;
  }
}

#top_nav {
  height: 55px;
  min-height: 55px;
  max-height: 55px;
  position: relative;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid $gd-border-grey;
  background-color: white;
  align-items: center;
  padding: 0 20px;
  padding-right: 10px;

  .top-nav-icon {
    position: relative;
    overflow: visible;
    line-height: 0;
    cursor: pointer;
    &.on-mobile {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 57px !important;
      border: none;
    }
    &:hover {
      opacity: 0.7;
    }
  }

  .Loader {
    //margin-top: 2px;
    display: inline-block;

    .MuiCircularProgress-colorPrimary {
      color: white;
    }
  }

  .new-button {
    display: inline-block;
    position: relative;
    overflow: hidden;
    width: 85px;
    margin-right: 11px;
    //margin-top: 3px;
    .GdButton.fill {
      height: 27px;
      width: 40px;
      padding: 0 20px;
      line-height: 27px;
      font-size: 11px;
      letter-spacing: 0;
      margin-right: 17px;
    }
  }

  .Breadcrumbs {
    display: flex;
    flex: auto;
  }

  .profile-image {
    width: 28px;
    height: 28px;
    overflow: hidden;
    border: 2px solid #e8e8e8;
    background-size: cover;
    background-position: center;
    cursor: pointer;
    background-color: #2a363f;
    -webkit-user-select: none;
    user-select: none;
    margin-right: 10px;
    border-radius: 100%;

    &:hover {
      opacity: 0.7;
    }
  }

}
