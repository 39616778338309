@import "../styles/theme";

.mobileLoginPage {
  font-family: $primary-font;
  display: flex;
  height: 100%;


  .GdButton {
    width: 100%;
  }

  .header {
    font-size: 16px;
    color: #a0a0a0;
    text-align: center
  }

  .subHeader {
    color: #2a363f;
    font-size: 40px;
    text-align: center;
    margin: 0;
    margin-bottom: 50px;
    font-weight: 700
  }

}

