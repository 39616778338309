.site-occupancy-schedule-modal {

}
.elr-in-modal {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  flex: 1 1;
  background-color: white;
}
.timeline-header {
  background-color: whitesmoke;
  padding: 10px;
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  flex-direction: column;
  min-height: 40px;
  max-height: 40px;
  overflow: hidden;
  position: relative;
}