@import "../../styles/theme";

.top-bar {
  top: 0;
  flex: 1 1;
  display: flex;
  height: 80px;
  align-items: center;
  flex-direction: row-reverse;
  position: absolute;
  width: 100%;
  justify-content: space-between;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.8);
}

.frequency-day {
  align-items: center;
  display: flex;
  font-size: 13px;
  color: #525252;
  //padding-left: 10px;
  .GdInput {
    margin-left: 15px;
    .MuiSelect-select.MuiSelect-select {
      padding-right: 38px;
    }
  }
}

.report {
  width: 100%;
  background-color: white;
  height: 100%;
  position:relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .select-template {
    .Card {
      overflow: visible;
    }
    .CardBody {
      transition: 0.3s all ease-out;
      border: 1px solid lightgrey;
      border-radius: 10px;
      align-items: center;
      padding: 50px 10px;
      &:hover {
        box-shadow: 0 0 0 4px rgba(45, 184, 193, 0.1);
      }
    }
    .left-section {
      justify-content: center;
      display: flex;
    }
    .right-section {
      display: flex;
      flex-direction: column;
      h4 {
        margin: 0 !important;
      }
      p {
        margin-bottom: 0 !important;
      }
    }
    .template-guide {
      margin: 30px 0;
      h4 {
        margin-bottom: 10px;
      }
      p {
        color: $gd-dark-grey;
        font-size: 13px;
        margin-bottom: 5px;
        &.sub {
          margin-top: 5px;
          font-size: 11px;
        }
      }
    }
  }
}
