.LoggedInAsBar {
  background-color: orange;
  color: white;
  padding: 10px 20px;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  .GdButton.outline.white-outline {
    color: white;
    background-color: rgba(255,255,255,0.1);
  }
}