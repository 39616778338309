.developer {
  .app-info {
    width: 100%;
    position: relative;
    display: block;
    span {
      float: left;
      padding: 5px 10px;
      margin: 5px;
      background-color: whitesmoke;
      border-radius: 30px;
      font-size: 11px;
    }
  }
  .app-info-title {
    font-weight: 700;
  }
}