.opening-hours {
  .opening-hour {
    align-items: center;
    height: 62px;
    //margin-bottom: 15px;
    p.day {
      font-weight: 700;
      width: 100px;
    }
    p.dash {
      margin: 10px;
    }
    .toggle-wrap {
      margin-right: 30px;
    }
  }
}