@import "../styles/theme";

html, body, #root, .page {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
}



#decorated_page {
  min-width: 1000px;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  font-family: $primary-font;
}

#mobile_decorated_page {
	overflow: hidden;
	height: 100%;
	display: flex;
	flex-direction: row;
	box-sizing: border-box;
	font-family: $primary-font;
}

#right_content_wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  flex: auto;
}

#content {
  background-color: $gd-bg-grey;
  display: flex;
  flex: 1;
  overflow: hidden;
}
