.title-container {
  display: flex;
  align-items: center;
  flex-direction: row;

  .title-text {
    margin: 5px;
  }
}

.modal-breadcrumb {
  &:hover {
    cursor: pointer;
  }
}

.no-applications-text {
  font-weight: 300;
  font-size: 14px;
  margin-bottom: 0 !important
}

.Add-Edit-Custom-Field {
  &.error {
    .chosen-icon-wrapper {
      border: 1px dashed red;
    }
  }

  .chosen-icon-wrapper {
    width: 90px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px dashed lightgrey;
    margin-right: 10px;
    border-radius: 5px;
    cursor: pointer;
    overflow: hidden;
    position: relative;

    p {
      margin: 0;
      margin-top: 5px;
      font-size: 11px;
    }

    &:hover {
      border: 1px dashed #eaeaea;

      .Icon, p {
        opacity: 0.7;
      }

      .remove-icon {
        opacity: 1;
      }
    }

    .remove-icon {
      position: absolute;
      top: 3px;
      right: 6px;
      opacity: 0;
    }
  }

  .chosen-icon-wrapper-view {
    width: 90px;
    height: 90px;
    display: flex;
    opacity: 0.7;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px dashed lightgrey;
    margin-right: 10px;
    border-radius: 5px;
    overflow: hidden;
    position: relative;

    p {
      margin: 0;
      margin-top: 5px;
      font-size: 11px;
    }

    .remove-icon {
      position: absolute;
      top: 3px;
      right: 6px;
      opacity: 0;
    }
  }
}

