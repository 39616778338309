.toggle-wrapper {
  font-size: 13px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.toggle-text {
  margin: 0;
  font-size: 12px;
  color: rgb(89, 89, 89);
  font-weight: 700;
}
.Toggle {
  width: 45px;
  height: 28px;
  border-radius: 30px;
  overflow: hidden;
  background: transparent !important;
  border: 1px solid lightgrey;
  transition: 0.2s ease-in;
  margin-right: 15px;
  &.active {
    background: transparent !important;
    .dot {
      margin-left: 20px;
      background-color: #6ec455;
    }
  }

  .dot {
    transition: 0.2s ease-in;
    width: 22px;
    height: 22px;
    border-radius: 100%;
    margin: 3px;
    background-color: #e45457;
  }
}