.rule-card-wrapper {
	padding: 5px;
	width: 33.333333%;
	float: left;
	box-sizing: border-box;
}
@media (max-width: 1370px) {
	.rule-card-wrapper {
		width: 50%;
	}
}
.rule-card {
	margin: 0;
	cursor: pointer;
	transition: 0.2s box-shadow ease-in;
	&:hover {
		opacity: 0.7;
		box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.05);
	}
	.CardBody {
		flex-direction: row;
		display: flex;
		align-items: center;
		padding: 15px 5px;
		.card-icon {
			height: 90px;
			width: 85px;
			position: relative;
			overflow: hidden;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-right: 20px;
			margin-left: 15px;
		}

		.card-content {
			height: 100%;
			display: flex;
			flex-direction: column;
			width: 100%;

			h4 {
				margin-top: 0;
				font-size: 15px;
				color: #353535;
				margin-bottom: 10px;
			}

			p {
				font-size: 12px;
				margin: 0;
				margin-bottom: 5px;
				color: #545454;
			}
		}
	}
}
