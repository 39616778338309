@import '../styles/theme';

.start-end-heading {
  font-weight: 500;
  border-bottom: 1px solid lightgrey;
  padding-bottom: 5px;
  display: flex;
  margin-bottom: 12px;
}

.rate-title {
  font-size: 18px;
  font-family: "Asap", "sans-serif";
  font-weight: bold;
  color: $primary-font;
}

.not-disabled {
  label {
    color: grey !important;
  }

  .MuiInputBase-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.87) !important;
  }
}

.plus-minus-button {
  min-width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #49b9c4;
  border-radius: 100%;
  margin: 7px 2px;
  cursor: pointer;
  color: white;
  z-index: 2;

  &.minus {
    margin-left: -67px;
  }

  &.disabled {
    opacity: 0;
    pointer-events: none !important;

    .Icon {
      pointer-events: none !important;
    }
  }

  &:hover {
    opacity: 0.7;
  }

}

.divider {
  width: 100%;
  height: 1px;
  background-color: $gd-bg-grey;
  //margin: 20px;
}

.error-text {
  color: $gd-red;
  font-size: 14px;
  font-family: "Asap", "sans-serif";
  font-weight: bold
}

.dropdown-button {
  color: #49b9c4;
  font-weight: 700;
  //border-bottom: 1px solid whitesmoke;
  width: 100%;
  padding: 10px 0;
}

.new-location-box {
  padding: 15px;
  background: transparent;
  margin-top: -15px;
  border: 1px solid whitesmoke;
  border-radius: 0 0 10px 10px;
  border-top: none;

  &.editing-locked {
    background-color: transparent;
    padding: 0;
    border: none;
  }
}

.MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input {
  .dd-icons-cont {
    display: none;
  }

  .dropdown-button {
    padding: 0;
  }
}

.row.drop-down-row {
  justify-content: space-between;

  .Icon {
    padding-left: 10px;
  }
}

.fake-input {
  line-height: 40px;
  position: relative;
  outline: none;
  font-size: 13px;
  border-radius: 10px !important;
  height: 38px;
  background: #f1f1f1;
  border: 1px solid #f1f1f1;
  padding: 0 15px;
  display: flex;
  font-weight: 700;
  justify-content: space-between;
}

.dd-icons-cont {
  display: flex;
  position: relative;
}