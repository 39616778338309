
#featureTooltip .MuiTooltip-tooltip {
  padding: 20px !important;
  max-width: min-content !important;
  word-wrap: break-word;
  font-family: "Asap", "sans-serif";
  font-weight: 500;
  line-height: 1.4em;
  border-radius: 5px;
  background-color: white !important;
  border: 1px #e8e8e8;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}

.MuiTooltip-popper, .MuiTooltip-tooltip {
  z-index: 999999999 !important;
  transition: none !important;
}

.dark .subTiers .tiers .tier {
  &.required {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  }
}
.feature-tooltip {
  background: #fff;
  border-radius: 4px;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  position: relative;
  //padding: 10px 20px;
  .f-name {
    font-weight: 600;
    font-size: 15px;
    font-style: normal;
    margin: 10px;
    margin-top: 0;
    margin-bottom: 0;
    font-family: Poppins, sans-serif;
  }
  .img-loader {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 0;
  }
  .img-container {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    width: 480px;
    height: 230px;
    //border: 1px solid #e8e8e8;
    //border-radius: 5px;
    margin-bottom: 10px;
    z-index: 1;
    display: flex;
  }
  p {
    font-weight: 500;
    line-height: 18px;
    font-size: 13px;
    margin: 10px;
    margin-bottom: 20px;
  }
}
.SubTierModal {
  padding: 30px 50px;
  min-width: 1100px;

  div[role=presentation] {
    background-color: rgba(0, 0, 0, 0.5);
    .MuiPopover-paper {
      border-radius: 10px;
    }
  }
  .sub-tier-modal-heading {
    font-family: Poppins, sans-serif;
    font-size: 30px;
    font-weight: 600;
  }

  .sub-tier-modal-sub-heading {
    font-family: Poppins, sans-serif;
    font-size: 20px;
  }
  .confirmation-popover {
    border: 1px solid #e8e8e8;
    padding: 20px 30px;
    border-radius: 10px;
    overflow: hidden;
    .GdButton {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .confirm-heading {
      font-size: 16px;
      font-weight: 600;
    }
    .confirm-description {
      margin-bottom: 20px;
      span {
        color: #49b9c4;
        font-weight: 600;
      }
    }
  }
  .brand-col {
    color: #49b9c4;
    font-weight: 600;
  }
  .support-feature {
    margin: 15px 0;
  }
  .tier-feature {
    font-size: 12px;
    opacity: 0.4;
    padding: 5px 0;
    cursor: default;
    &.has-tooltip {
      span.feature-name {
        border-bottom: 1px dashed #b2b2b2;
        padding: 3px 0;
      }
    }
    .Icon {
      opacity: 0.3;
      margin-right: 10px;
    }
    &.bold {
      color: #49b9c4;
      font-weight: 600;
    }
    &.enabled {
      opacity: 1;
      .Icon {
        opacity: 1;
        color: #49b9c4;
      }
    }
  }
  .popular {
    font-family: Poppins, sans-serif;
    height: 40px;
    font-weight: 600;
    &.on {
      background-color: #49b9c4;
      text-align: center;
      margin-left: 15px;
      color: white;
      border-radius: 15px 15px 0 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: calc(100% - 15px);
    }
  }

  .plan-button {
    cursor: pointer;
    padding: 10px 20px;
    border: 1px solid #49b9c4;
    color: #49b9c4;
    font-family: Poppins, sans-serif;
    text-transform: uppercase;
    text-align: center;
    border-radius: 10px;
    font-weight: 600;
    &:not(.most-popular) {
      background-color: transparent !important;
    }
    &:hover {
      opacity: 0.7;
    }

    &.most-popular {
      background-color: #49b9c4;
      color: #fff !important;
    }

    &.current {
      background-color: transparent !important;
      border: 1px solid grey !important;
      color: grey !important;
    }
  }

  .frequency-toggle {
    display: inline-flex;
    overflow: hidden;
    font-family: Poppins, sans-serif;
    justify-content: center;
    align-items: center;
    margin: 20px 0 40px 0;
    border: 2px solid #e8e8e8;
    border-radius: 10px;
    background-color: #e8e8e8;

    .frequency-option {
      cursor: pointer;
      padding: 10px 20px;
      transition: 0.2s all;

      .discount {
        background-color: #49b9c4;
        color: white;
        padding: 1px 8px;
        border-radius: 10px;
        font-size: 11px;
        line-height: 20px;
        font-weight: 500;
        margin-left: 5px;
      }

      &:hover {
        opacity: 0.7;
      }

      &.selected {
        cursor: default;

        &:hover {
          opacity: 1;
        }

        background-color: white;
      }
    }
  }

  .tiers {
    .tier {
      padding: 20px 30px;
      margin: 15px;
      margin-top: 0;
      border: 1px solid #e8e8e8;
      border-radius: 15px;
      &.required {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      }
      &.pop {
        border-radius: 0 0 15px 15px;
      }
      &:nth-child(1) {
        margin-left: 0;
      }
      &:nth-last-child(1) {
        margin-right: 0;
      }

      .tier-name {
        font-family: Poppins, sans-serif;
        font-size: 20px;
        font-weight: 600;
      }

      .tier-description {
        height: 80px;
        font-size: 13px;
        overflow: auto;
      }

      .tier-cost {
        font-family: Poppins, sans-serif;
        font-size: 15px;
        font-weight: 600;
        margin-bottom: 20px;

        span.strikeout {
          text-decoration: line-through;
          opacity: 0.5;
        }
      }
    }
  }
}