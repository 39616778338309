#contextBar {
  z-index: 2;
  display: flex;
  align-items: center;
}
.context-bar {
  //background-color: #defcff;
  background-color: #fff4ec;
  align-items: center;
  font-size: 11px;
  height: 35px;
  display: flex;
  //padding: 8px 20px;
  //padding-bottom: 10px;
  border-bottom: 1px solid #e8e8e8;
  //background-color: white;
  //margin-top: -6px;
  position: relative;
  overflow: hidden;
  z-index: 2;
  .swiper-button-next {
    background-image: linear-gradient(to right, rgba(255, 244, 236, 0), rgb(255, 244, 236));
  }
  .swiper-button-prev {
    background-image: linear-gradient(to left, rgba(255, 244, 236, 0), rgb(255, 244, 236));
  }
  .swiper-button-next::after {
    //left: -7px;
    font-size: 16px;
    line-height: 35px;
  }
  .swiper-button-prev::after {
    //left: -7px;
    font-size: 16px;
    line-height: 35px;
  }
  .swiper-slide:nth-last-child(1) {
    .context-item {
      margin-right: 15px;
    }
  }
  .context-item {
    margin-left: 15px;
  }
  p {
    white-space: nowrap;
    font-size: 11px;
    margin: 0;
  }
  span {
    //margin-right: 3px;
    font-weight: 600;
  }
  .context-icon {
    margin-right: 5px;
    margin-top: -3px;
  }
}