.subTierModalWrapper {
  .close-icon {
    position: fixed;
    top: 8%;
    right: 7%;
  }
}

.loader-wrapper.absolute {
  position: absolute;
  height: 100%;
}