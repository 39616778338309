@import "../../styles/theme";

.rule {
	//width: 100%;
	//background-color: white;
	//height: 100%;
	//position:relative;
	//display: flex;
	//justify-content: center;
	//align-items: center;
	.select-template {
		.Card {
			overflow: visible;
		}
		.CardBody {
			transition: 0.3s all ease-out;
			border: 1px solid lightgrey;
			border-radius: 10px;
			align-items: center;
			padding: 50px 10px;
			&:hover {
				box-shadow: 0 0 0 4px rgba(45, 184, 193, 0.1);
			}
		}
		.left-section {
			justify-content: center;
			display: flex;
		}
		.right-section {
			display: flex;
			flex-direction: column;
			h4 {
				margin: 0 !important;
			}
			p {
				margin-bottom: 0 !important;
			}
		}
		.template-guide {
			margin: 30px 0;
			h4 {
				margin-bottom: 10px;
			}
			p {
				color: $gd-dark-grey;
				font-size: 13px;
				margin-bottom: 5px;
				&.sub {
					margin-top: 5px;
					font-size: 11px;
				}
			}
		}
	}
}
