@import "../../styles/theme";


.identify-button-container {
  display: inline-block;
  margin-right: 40px;
  float: right
}


.overview-asset {
  flex-direction: column;

  .asset-data {
    font-weight: 700;
    line-height: 15px;
    span.warning {
      color: red;
    }
    span.heading {
      color: grey;
      font-weight: 300 !important;
      font-size: 11px;
      padding-right: 10px;
    }
  }

  .state-select-view span {
    align-items: center;
  }

  .phase-button {
    margin-bottom: 15px;
    width: 200px;
  }

  .details {
    h1 {
      font-size: 18px;
      margin: 0;
    }

    p {
      font-size: 13px;
      margin: 0;
    }

    padding: 0;
  }

  .detail-card {
    //min-width: 850px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 0;
    //padding-bottom: 20px;
    //border-bottom: 1px solid $gd-border-grey;

    .Icon {
      margin-right: 20px;
    }

    .right-content {
      display: flex;
      flex-direction: column;

      h1 {
        margin-bottom: 5px;
        font-weight: 600;
      }

      h2 {
        margin-top: 5px;
        font-size: 15px;
        font-weight: 500;
      }
    }
  }

  .top-summary {
    flex-direction: row;
    display: flex;
    flex: 1 1;
    padding: 15px;
    padding-left: 30px;

    .left-image {
      display: flex;
      width: 80px;
      border-radius: 5px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;

      .init-spinner {
        padding-left: 20px
      }

    }

    .right-summary {
      flex-direction: column;
      display: flex;
      padding: 10px;
      padding-left: 20px;

      p {
        margin: 5px 0;
      }

      .heading {
        font-weight: 600;
      }
    }
  }

  .card-row {
    margin: 15px;
    display: flex;
    flex-direction: row;

    .Card {
      flex: 1 1;

      p.asset-data {
        margin-top: 20px;

        .heading {
          font-weight: 600;
        }
      }

      &.real-time {
        .CardHeader {
          display: flex;
          justify-content: space-between;
        }
      }

      &.family {
        .CardHeader {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
}
