.duckcharge-kpi {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1;
  height: 200px;
  color: #00C3EE;
  font-family: 'Poppins', sans-serif;
  border-bottom: 1px solid lightgrey;
  background-color: white;
  text-align: center;
}

.duckcharge-kpi-number {
  font-size: 50px;
  font-weight: 700;
}

.duckcharge-kpi-label {
  color: grey;
}