.Icon-Search {
  padding: 20px;
  width: 250px;
  .GdButton {
    height: 25px;
    line-height: 25px;
    font-size: 11px;
  }
  .icon-search-input {
    margin-bottom: 15px;
  }
  .suggested-title {
    font-size: 11px;
  }
  .icon-grid {
    display: flex;
    flex-wrap: wrap;
    max-height: 150px;
    overflow: auto;
    .icon-wrapper {
      margin: 5px;
      width: 20px;
      height: 20px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      &.color {
        &:hover {
          cursor: pointer;
          opacity: 0.7;
        }
      }
    }
  }
}